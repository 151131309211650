import React, { useState } from 'react';
import styled from 'styled-components';
import './Investors.css';

const Container = styled.div`
  // height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: -50px;
  //align-items: center;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  //height: 92vh;
  width: 90vw;
  padding-bottom: 75px;
  margin-left: 150px;
  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 2.5fr;
    margin-left: 50px;
  }
  @media screen and (max-width: 925px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 500px) {
    width: 90vw;
    margin-left: 0px;
  }
`;
const One = styled.div`
  height: 100%;
  width: 100%;
  color: #fff;
  @media screen and (max-width: 925px) {
    display: flex;
  }
`;
const Two = styled.div`
  width: 90%;
  height: 100%;
  padding: 30px;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;

  background: #081542;
  color: rgb(237, 237, 237);
  font-weight: 300;
  /* background: #fff;
  color: #333; */
  @media screen and (max-width: 500px) {
    width: 100%;
    padding: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 40px;
  }
`;
const Div = styled.div`
  margin-top: 10px;
`;
const How = styled.div`
  font-size: 4.5rem;
  font-family: 'Roboto Slab', serif;
  padding: 10px;
  padding-left: 20px;
  padding-right: 200px;
  transition: 200ms ease;
  //background: #081542;
  /* background: #fff;
  color: #000; */
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1300px) {
    font-size: 3.5rem;
  }
  @media screen and (max-width: 1150px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 925px) {
    padding-right: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 2.2rem;
  }
`;
const Inv = styled.div`
  font-size: 4.5rem;
  font-family: 'Roboto Slab', serif;
  padding: 10px;
  padding-left: 20px;
  padding-right: 200px;
  transition: 200ms ease;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1300px) {
    font-size: 3.5rem;
  }
  @media screen and (max-width: 1150px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 925px) {
    padding-right: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 2.2rem;
  }
`;

const Investors = () => {
  const [showHow, setShowHow] = useState(true);
  const showHowItem = () => {
    setShowHow(true);
  };

  // Function to show all items
  const showInv = () => {
    setShowHow(false);
  };
  return (
    <Container>
      <Wrapper>
        <Content>
          <One>
            <How
              onClick={showHowItem}
              className={`${showHow ? 'how' : 'not-how'}`}
            >
              How
            </How>
            <Inv className={`${showHow ? 'not-how' : 'how'}`} onClick={showInv}>
              Investors
            </Inv>
          </One>
          <Two>
            {showHow ? (
              <>
                <Div>
                  Any project we take on with a focus on energy efficiency
                  always begins with planning, where we carefully assess the
                  site and consider the home's orientation to maximize natural
                  light and minimize heat loss.
                </Div>{' '}
                <Div>
                  We prioritize insulation, using high-quality materials to
                  create a tight building envelope that reduces drafts and
                  ensures thermal comfort year-round. An ideal home will feature
                  energy-efficient windows, doors, and HVAC systems, along with
                  the latest in smart home technology to monitor and regulate
                  energy consumption. We also integrate renewable energy sources
                  such as solar panels to reduce reliance on the grid.
                </Div>
                <Div>
                  By blending thoughtful design with eco-friendly practices, we
                  aim to deliver not just houses, but energy-efficient homes
                  that provide long-term savings and a sustainable living
                  environment for our clients.
                </Div>
              </>
            ) : (
              <>
                {' '}
                <Div>
                  For savvy investors eyeing the real estate market, embracing
                  the potential of energy efficiency is your ticket to
                  sustainable success.{' '}
                </Div>
                <Div>
                  In a era moving towards a greener planet, energy-efficient
                  homes are only going to continue rising in demand, and it's
                  not hard to see why. These homes offer reduced utility costs,
                  enhanced comfort, and a competitive edge in the market.
                </Div>{' '}
                <Div>
                  Imagine a real estate portfolio where every property is a
                  beacon of energy efficiency, consistently increasing in value
                  over time. Can you afford to overlook this movement?
                </Div>
                <Div>
                  {' '}
                  Now is the perfect time to explore the world of
                  energy-efficient homes and watch your real estate portfolio
                  thrive.
                </Div>
                <Div className="bold">
                  Energy-efficient homes are undeniably the future of real
                  estate.
                </Div>
              </>
            )}
          </Two>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Investors;
