import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CTA from '../../CTA/CTA';
import ImgR from '../../../images/foundation.jpg';
import Footer2 from '../../Footer/Footer2';
import ImgLead from '../../../images/embracing.png';
const Container = styled.div`
  width: 100vw;
  height: 100%;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  background-color: rgb(202, 210, 255);
  background-color: rgb(230, 230, 230);
  position: relative;
  z-index: 50;
`;
const Wrapper = styled.div`
  margin-left: 200px;
  width: 800px;
  //height: 150vh;
  @media screen and (max-width: 1400px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 900px) {
    width: 80vw;
    margin-left: 50px;
  }
  @media screen and (max-width: 400px) {
    margin-left: 25px;
  }
`;
const Header = styled.div`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 25px;
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 2rem;
  }
`;
const Para = styled.div`
  color: #333;
  font-weight: 300;
  margin-top: 25px;
  margin-bottom: 25px;
`;
const SubHeader = styled.div`
  font-weight: 500;
  font-size: 2rem;
`;

const Img = styled.img`
  height: 250px;
  width: 500px;
  object-fit: cover;
`;
const Sticky = styled.div`
  position: fixed;
  z-index: 0;
  margin-right: 75px;
  right: 0;
  top: 0;
  height: 100vh;
  width: 350px;
  background: rgb(237, 237, 237);
  display: flex;
  align-items: center;
  justify-content: center;
  //opacity: 0;
  @media screen and (max-width: 1500px) {
    margin-right: 25px;
  }
  @media screen and (max-width: 1300px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 1275px) {
    display: none;
  }
`;
//
const LeadGen = styled.div`
  background: #fff;
  //background: #071023;
  //height: 20vh;
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  margin-top: 75px;
  @media screen and (max-height: 650px) {
    margin-top: 125px;
  }
`;
const LeadImg = styled.img`
  height: 250px;
  @media screen and (max-height: 700px) {
    height: 200px;
  }
  @media screen and (max-height: 650px) {
    height: 225px;
  }
  @media screen and (max-height: 550px) {
    height: 200px;
  }
`;
const LeadImgWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
  align-items: center;
`;
const LeadDiv = styled.div`
  //color: #333;
  padding: 25px;
  font-weight: 500;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  background: #071023;
  @media screen and (max-height: 700px) {
    font-size: 1rem;
    padding: 5px;
  }
  @media screen and (max-height: 600px) {
    display: none;
  }
`;
const CTADiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const Div = styled.div`
  @media screen and (max-height: 650px) {
    font-size: 0.9rem;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
`;
const BlogContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  //align-items: center;
  //justify-content: center;
  background: #fff;
  margin-left: 350px;
  width: 825px;
  padding-top: 50px;
  height: 250vh;
`;
const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-left: 25px;
  grid-row-gap: 0px;
`;
const Wrap = styled.div`
  position: relative;
  z-index: 49;
  height: 500vh;
  width: 100vw;
`;
const Btn = styled.div`
  padding: 10px 20px;
  font-size: 16px;
  //min-width: 50px;
  max-width: 250px;
  transition: 300ms ease-in-out;
  background: #081542;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid #071023;
    cursor: pointer;
  }
`;
// const CTADiv = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   text-align: center;
// `;
// const Div = styled.div``;

const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Foundation = () => {
  return (
    <>
      <Container>
        <Sticky>
          <LeadGen>
            <LeadDiv>Embracing Energy Efficiency</LeadDiv>
            <LeadImgWrap>
              <LeadImg src={ImgLead} alt="free pdf" />
            </LeadImgWrap>
            <CTADiv>
              <Div>TAKE CONTROL OF YOUR ENERGY BILLS:</Div>
              <Div>DOWNLOAD OUR FREE GUIDE NOW</Div>
              <a href="https://mailchi.mp/4cb5ae9e1389/embracing-energy-efficiecny">
                <BtnWrap>
                  <Btn>DOWNLOAD THE GUIDE</Btn>
                </BtnWrap>
              </a>
            </CTADiv>
          </LeadGen>
        </Sticky>
        <Wrapper>
          <Header>
            Understanding Foundation Types: Which is Right for Your Building?
          </Header>
          <Img src={ImgR} alt="" />
          <Para>
            When embarking on a construction project, one of the most critical
            decisions you'll make is choosing the right foundation type for your
            building. The foundation is the literal and metaphorical bedrock of
            any structure, providing stability and support. Understanding the
            various foundation types and their suitability for different
            situations is essential for a successful construction project. In
            this guide, we'll delve into the most common foundation types and
            help you determine which one is right for your building.
          </Para>
          <SubHeader>1. Slab Foundation</SubHeader>
          <Para>
            Overview: A slab foundation is a single, continuous layer of
            concrete that is poured directly onto the ground. It's commonly used
            for homes with crawl spaces or as a base for structures like sheds
            and garages.
          </Para>
          <Para>
            When to Choose: Slab foundations are cost-effective and ideal for
            areas with stable soil conditions and minimal risk of frost heave.
            They work well for single-story homes and buildings without a
            basement.
          </Para>
          <SubHeader>2. Crawl Space Foundation</SubHeader>
          <Para>
            Overview: Crawl space foundations consist of a raised platform with
            limited space underneath. They provide access for maintenance and
            repairs while elevating the building above the ground.
          </Para>
          <Para>
            When to Choose: Crawl space foundations are suitable for areas with
            moderate moisture levels. They offer protection against dampness and
            pests and are commonly used in regions with uneven terrain.
          </Para>
          <SubHeader>3. Basement Foundation</SubHeader>
          <Para>
            Overview: Basements are underground spaces that provide additional
            living or storage space. They involve excavating a significant
            portion of the ground below the building.
          </Para>
          <Para>
            When to Choose: Basements are a great choice when you need extra
            living space or plan to include utilities like a furnace or water
            heater. They are common in colder climates, as the foundation depth
            helps protect against frost.
          </Para>

          <SubHeader>4. Tilt-Up Foundation</SubHeader>
          <Para>
            Overview: Tilt-up foundations involve casting concrete walls
            horizontally, then tilting them into place to form the structure's
            perimeter.
          </Para>
          <Para>
            When to Choose: Tilt-up foundations are often used for commercial
            and industrial buildings due to their efficiency and speed of
            construction.
          </Para>
          <SubHeader>Choosing the Right Foundation</SubHeader>
          <Para>
            The choice of foundation should consider various factors, including
            the local climate, soil conditions, building design, and budget
            constraints. Consulting with a professional engineer or architect is
            highly recommended to assess these factors and make an informed
            decision.
          </Para>

          <Para>
            Additionally, local building codes and regulations may dictate
            specific requirements for foundation types, so it's essential to
            comply with these standards.
          </Para>
          <Para>
            Remember that the foundation is a critical component of your
            building's structural integrity. Investing time and resources in
            selecting the right foundation type for your project will pay off in
            the long run, ensuring the safety and durability of your structure.
          </Para>
          <Para>
            In conclusion, understanding foundation types and their suitability
            for different situations is crucial when planning a construction
            project. Whether you opt for a slab, crawl space, basement,
            pier-and-beam, or tilt-up foundation, the choice should align with
            your specific needs and local conditions. To make the right
            decision, consult with experts and adhere to local building codes to
            ensure a strong and stable foundation for your building.
          </Para>
        </Wrapper>
      </Container>
    </>
  );
};

export default Foundation;
