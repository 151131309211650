import React from 'react';
import './Pdf.css';

function Pdf() {
  return (
    <div>
      <iframe className="myPDF" frameBorder="0" src="/Modern.pdf"></iframe>{' '}
    </div>
  );
}

export default Pdf;
