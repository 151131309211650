import React from 'react';

import styled from 'styled-components';
import './Stakes.css';
import Img1 from '../../images/photo-1.jpg';
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useState } from 'react';
import { Button } from '../Button';
import { useInView } from 'react-intersection-observer';
import { Triangle } from '../valuesection/ValueSection';

export const StakesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  //margin-top: -250px;
  //background-color: #fff;
  z-index: 0;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    //grid-gap: -100px;
  }
`;
export const VideoWrap = styled.img`
  width: 100%;
  height: 400px;
  margin-top: 125px;
  margin-left: 150px;
  object-fit: cover;
  max-width: 50%;

  @media screen and (max-width: 768px) {
    height: 250px;
    width: 250px;
    margin-top: -0%;
    margin-left: 30%;
  }
`;
export const StakesContent = styled.div``;
const Heights = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 32px;
  margin-top: 100px;
  background-color: #fff;
  color: #000;
  font-size: 1.6rem;
  text-align: center;

  @media screen and (max-width: 768px) {
    margin-bottom: 500px;
    font-size: 1.2rem;
    margin-top: 50px;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    margin-top: 0px;
    margin-bottom: 0;
  }
`;
const Img = styled.img`
  height: 200px;
  width: 100%;
  @media screen and (max-width: 500px) {
    height: 400px;
    min-width: 200px;
  }
`;
const HeightsP = styled.div`
  height: 200px;
  margin-top: 10%;
  @media screen and (max-width: 500px) {
    margin-top: -100px;
    height: 0;
    text-align: center;
  }
`;
const Blur = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 99;
  backdrop-filter: blur(200px);
`;
const Offer = styled.div`
  margin-top: 175px;

  @media screen and (max-width: 400px) {
    margin-top: 215px;
  }
`;
const DontOffer = styled.div`
  margin-left: 10px;
`;
const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

const OfferH = styled.div`
  font-size: 2rem;

  padding-top: 35px;
  padding-bottom: 20px;
  font-family: 'Poppins';
`;
const OfferList = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  padding-bottom: 50px;
  font-size: 1.1rem;
  //padding-top: 50px;
  padding-left: 25px;
`;
const I = styled.i`
  color: rgb(220, 0, 0);
`;
export const Block = styled.div`
  padding: 150px;
  padding-right: 50px;
  padding-left: 125px;
  padding-bottom: 100px;
  max-width: 700px;
  position: absolute;
  z-index: 4;
  bottom: 10%;
  margin-top: 100px;
  right: 0%;
  background: #081542;
  border-radius: 25px 0px 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    bottom: 36.5%;
    overflow-y: hidden;
    //padding: 10px;
    padding-left: 75px;
    margin-top: 0;
    padding-top: 100px;
    padding-bottom: 100px;
    //display: none;
  }

  @media screen and (max-width: 500px) {
    bottom: 40%;
  }
  @media screen and (max-width: 400px) {
    bottom: 37%;
  }
  @media screen and (max-width: 380px) {
    bottom: 38%;
    padding-top: 75px;
    padding-bottom: 75px;
  }
  @media screen and (max-width: 370px) {
    bottom: 40%;
  }
`;
const Uu = styled.div`
  height: 80vh;
  width: 100vw;
  background-color: #fff;
  overflow: hidden;
  // z-index: 0;
  // position: inherit;
  @media screen and (max-width: 768px) {
    overflow-y: hidden;
  }
  @media screen and (max-width: 500px) {
    height: 80vh;
  }
  @media screen and (max-width: 400px) {
    height: 100vh;
  }
  @media screen and (max-width: 380px) {
    height: 110vh;
  }
`;
export const Black = styled.div`
  padding: 50px;
  padding-right: 150px;
  padding-left: 135px;
  background-color: #081542;
  position: absolute;
  bottom: 38%;
  left: 8%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const BlockDiv = styled.div`
  color: #fff;
  font-size: 1.7rem;
  font-family: 'Poppins', sans-serif;
  overflow-y: hidden;
  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
`;
export const Span = styled.span`
  color: turquoise;
`;
export const BtnWrap = styled.div`
  margin-top: 25px;
  //width: 500px;
  min-width: 900px;
`;
export const BtnDiv = styled.div`
  /* display: grid;
  grid-template-columns: 20fr 1fr;*/
  max-width: 500px;
`;
const Side1Header = styled.h1`
  //font-family: 'Roboto', sans-serif;
  //color: #fff;
  //margin-top: 100px;
  margin-bottom: 25px;
  height: 175px;
  overflow: hidden;
  //overflow-x: auto;
  // overflow-y: hidden;
  overflow: auto;
`;
function Stakes1() {
  const { ref: myRef, inView: myElementIsVisible } = useInView();
  const { ref: wastedRef, inView: wastedIsVisible } = useInView();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const [mousePosition, setmousePosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const mouseMove = (e) => {
      setmousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener('mousemove', mouseMove);

    return () => {
      window.removeEventListener('mousemove', mouseMove);
    };
  }, []);

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
    },
  };

  return (
    <Uu>
      <StakesWrapper>
        <StakesContent>
          <div className="stakes-container">
            <Offer>
              <OfferList data-aos="fade-down">
                <I className="fa-solid fa-circle-xmark" />
                <div className="offer-list">
                  Energy inefficient homes consume more energy and inaction will
                  cost you significantly more in the long run.
                </div>
              </OfferList>
              <OfferList data-aos="fade-down">
                <I className="fa-solid fa-circle-xmark" />
                <div className="offer-list">
                  Another cold winter? Poorly insulated and ventilated homes can
                  lead to discomfort.
                </div>
              </OfferList>
              <OfferList data-aos="fade-down">
                <I className="fa-solid fa-circle-xmark" />
                <div className="offer-list">
                  Energy efficiency is a major government target so don't miss
                  out on the significant energy efficient tax cuts.
                </div>
              </OfferList>

              <OfferList data-aos="fade-down">
                <I className="fa-solid fa-circle-xmark" />
                <div className="offer-list">Ready to upgrade your future?</div>
              </OfferList>
            </Offer>
          </div>
        </StakesContent>
      </StakesWrapper>
    </Uu>
  );
}

export default Stakes1;
