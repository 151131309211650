import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './Clean.css';

const ImgWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  display: inline-block;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover::before {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }
  &:hover {
    transform: scale(1.1);
  }
  transition-duration: 500ms;
`;
const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-left: -2px;
  transition-duration: 500ms;
  display: block;
  max-width: 100%;

  &:hover {
    transform: scale(1.2);
  }
`;

const ImgText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.3);
  color: white;

  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;
const ImgWrapWrap = styled.div`
  width: 375px;
  height: 450px;
  overflow: hidden;
`;
const Place = styled.div`
  font-weight: 300;
  font-size: 1.2rem;
`;
const Br = styled.div`
  margin-top: 10px;
  font-size: 1rem;
`;

const CardClean2 = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [inViewRef, inView] = useInView({
    triggerOnce: true, // Trigger the effect only once when it comes into view
  });

  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };
  return (
    <Link to={props.link}>
      <ImgWrapWrap>
        <ImgWrap
          className="image-container-clean"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            ref={inViewRef}
            className={`blurry-image ${inView && loaded ? 'loaded' : ''}`}
          >
            {inView && (
              <Img
                src={props.src}
                alt=""
                loading="eager"
                onLoad={handleImageLoad}
                className={loaded ? 'normal' : ''}
              />
            )}
          </div>
          <ImgText className="image-text">
            <Place
              className={`${
                isHovered
                  ? 'image-text img-txt-hov-clean'
                  : 'image-text img-txt-hov-no-clean'
              }`}
            >
              {props.header}

              <br />
              <Br>- click here -</Br>
            </Place>
          </ImgText>
        </ImgWrap>
      </ImgWrapWrap>
    </Link>
  );
};

export default CardClean2;
