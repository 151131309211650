import React, { useEffect } from 'react';
import styled from 'styled-components';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Card from './Card';
import Img4 from '../../images/projects/higgy-house.jpg';
import Img3 from '../../images/house.jpg';
import Img2 from '../../images/thumbnail.jpg';
import Img5 from '../../images/lighting.jpg';
import Img1 from '../../images/projects/woodborough ext b4.jpg';
import './Benefits.css';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const Cardcontainer = styled.div`
  color: rgb(237, 237, 237);
  //height: 100vh;
  width: 400px;
  //width: 100%;
  height: 100%;
  //height: 800px;
  overflow: hidden;
  //width: 100%;
  //transform: translateY(20px);
  @media screen and (max-width: 768px) {
    //width: 100vw;
    width: 350px;
    overflow: auto;
  }
`;
const Container = styled.div`
  //height: 110vh;
  height: 100%;
  padding-bottom: 75px;
  overflow: hidden;
  background: #071023;
  display: flex;
  justify-content: center;

  //align-items: center;

  @media screen and (max-width: 500px) {
    //height: 270vh;
    height: 100%;
    padding-bottom: 25px;
  }
`;
const Wrapper = styled.div``;
const Header = styled.h1`
  color: #fff;
  font-weight: 300;
  margin-top: 50px;
  font-size: 2.5em;
  @media screen and (max-width: 768px) {
    font-size: 2em;
  }
`;
const SubHeader = styled.h4`
  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: 200;
  margin-top: 10px;
`;
const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-right: 50px;
  margin-left: 50px;
  grid-gap: 25px;
  margin-top: 50px;
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    display: none;
  }
`;
const CardsWrapper2 = styled.div`
  display: none;
  grid-template-columns: 1fr 1fr 1fr;
  margin-right: 0px;
  margin-left: 0px;
  grid-gap: 25px;
  margin-top: 50px;
  @media screen and (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 0px;
    margin-right: 0px;
  }
  @media screen and (max-width: 500px) {
    margin-left: 25px;
  }
`;
const Wrapper2 = styled.div`
  //height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 500px) {
    display: inherit;
  }
`;
const Benefits = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView();
  const { ref: oceanRef, inView: oceanVisible } = useInView();
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);
  return (
    <Container>
      <Wrapper>
        <Header data-aos="fade">WHY CHOOSE US?</Header>
        <SubHeader data-aos="fade">EXPLORE YOUR FUTURE</SubHeader>
        <CardsWrapper>
          <div ref={oceanRef}>
            <Cardcontainer data-aos="fade">
              <div className={`${''} ${oceanVisible ? 'glitch-fix' : ''}`}>
                <Card
                  data-aos="fade"
                  src={Img2}
                  h1="Full service construction"
                  p="Simplify your project experience! Our services cover every step, from intial plans to flawless completion. Our success stems from constant communication with our customers, ensuring you remain in full control of your project."
                  cta="Click here to find out more about us."
                  btn="About Us"
                  header="ABOUT US"
                  link="/about-us"
                />
              </div>
            </Cardcontainer>
          </div>
          <div ref={oceanRef}>
            <Cardcontainer data-aos="fade">
              <div className={`${''} ${oceanVisible ? 'first' : 'null'}`}>
                <Card
                  src={Img4}
                  link="/recent-projects"
                  h1="Quality you can trust"
                  p="You'll walk into your finished project with a genuine sense of pride. We are committed to turning your vision into reality whether it's your cozy home or professional real estate."
                  cta="Click here to explore our recent projects."
                  btn="Recent Projects"
                  header="RECENT PROJECTS"
                />
              </div>
            </Cardcontainer>
          </div>
          <div ref={oceanRef}>
            <Cardcontainer data-aos="fade">
              <div className={`${''} ${oceanVisible ? 'second' : 'null'}`}>
                <Card
                  className="second"
                  src={Img1}
                  link="/energy-efficiency"
                  h1="Embrace Energy Efficiency"
                  p="Say goodbye to chilly winters! Our energy efficient designs are your key to saving money whilst tackling the challenge of rising energy costs. Experience the future of comfort living."
                  cta="Click here to find out more about our energy-efficient efforts."
                  btn="Design Process"
                  header="ENERGY EFFICIENCY"
                />
              </div>
            </Cardcontainer>
          </div>
        </CardsWrapper>
        <Wrapper2>
          <CardsWrapper2>
            <div>
              <Cardcontainer data-aos="fade">
                <div>
                  <Card
                    data-aos="fade"
                    link="/about-us"
                    src={Img2}
                    cta="Click here to find out more about us."
                    h1="Full service construction"
                    p="Simplify your project experience! Our services cover every step, from intial plans to flawless completion. Our success stems from constant communication with our customers, ensuring you remain in full control of your project."
                    btn="About Us"
                  />
                </div>
              </Cardcontainer>
            </div>
            <div>
              <Cardcontainer data-aos="fade">
                <div>
                  <Card
                    src={Img4}
                    link="/recent-projects"
                    h1="Quality you can trust"
                    p="You'll walk into your finished project with a genuine sense of pride. We are committed to turning your vision into reality whether it's your cozy home or professional real estate. Explore our recent projects:"
                    btn="Recent Projects"
                    cta="Click here to explore our recent projects."
                  />
                </div>
              </Cardcontainer>
            </div>
            <div>
              <Cardcontainer data-aos="fade">
                <div>
                  <Card
                    className="second"
                    link="/energy-efficiency"
                    src={Img1}
                    h1="Embrace Energy Efficiency"
                    p="Say goodbye to chilly winters! Our energy efficient designs are your key to saving money whilst tackling the challenge of rising energy costs. Experience the future of comfort living. Find out more about our energy efficient design process:"
                    btn="Design Process"
                    cta="Click here to find out more about our energy-efficient efforts."
                  />
                </div>
              </Cardcontainer>
            </div>
          </CardsWrapper2>
        </Wrapper2>
      </Wrapper>
    </Container>
  );
};

export default Benefits;
