import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../Button';
import './Hero3.css';

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  overflow: hidden;
  height: 100vh;
  /* 
  color: #fff;
  @media screen and (max-width: 1300px) {
    background-size: 120%;
  }
  @media screen and (max-width: 1100px) {
    background-size: 140%;
  }
  @media screen and (max-width: 900px) {
    background-size: 160%;
  }
  @media screen and (max-width: 768px) {
    background-size: 180%;
  }
  @media screen and (max-width: 700px) {
    background-size: 200%;
  }
  @media screen and (max-width: 650px) {
    background: url('/images/hero-small.png');
    background-size: 100%;
  }
  @media screen and (max-width: 500px) {
    background-size: 160%;
  } */
`;
const Img = styled.div`
  height: 100vh;
  width: 100vw;
  background: url('/images/mansion.jpg') center;
  background-size: 100%;

  @media screen and (max-width: 1400px) {
    background-size: 120%;
  }
  @media screen and (max-width: 1200px) {
    background-size: 140%;
  }
  @media screen and (max-width: 1000px) {
    background-size: 160%;
  }
  @media screen and (max-width: 900px) {
    background-size: 180%;
    transform: scale(1);
  }
  @media screen and (max-width: 800px) {
    background-size: 190%;
  }
  @media screen and (max-width: 768px) {
    background-size: 200%;
  }
  @media screen and (max-width: 650px) {
    background: url('/images/garage.jpg');
    background-size: 100%;
  }
  @media screen and (max-width: 500px) {
    background-size: 150%;
    background-position-y: 90%;
    background-position-x: 50%;
  }
`;
const Container2 = styled.div`
  height: 100vh;
  width: 100vw;
  display: none;
  justify-content: center;
  align-items: center;
  background: url('/images/prac-hero2.png') center;
  background-size: 100%;
  color: #fff;
  @media screen and (max-width: 900px) {
    display: flex;
  }
`;
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
`;
const Content = styled.div`
  // border: 1px solid #000;
  padding: 20px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  //margin-top: 150px;
  @media screen and (max-width: 380px) {
    height: 100%;
  }
`;
const Header = styled.h1`
  text-align: center;
  font-size: 4rem;
  font-family: 'Roboto Slab', serif;
  //font-family: 'Playfair Display', serif;
  font-weight: 400;
  margin-top: 50px;
  @media screen and (max-width: 1300px) {
    font-size: 3rem;
    margin-top: 75px;
  }
  @media screen and (max-width: 768px) {
    font-size: 3.5rem;
    margin-top: 75px;
  }
  @media screen and (max-width: 500px) {
    font-size: 2.8rem;
  }
  @media screen and (max-width: 390px) {
    font-size: 2.6rem;
    overflow: hidden;
  }
`;
const SubHeader = styled.div`
  text-align: center;
  font-size: 1rem;
  margin-top: 25px;
  margin-bottom: 50px;
  @media screen and (max-width: 500px) {
    margin-bottom: 0px;
    margin-top: 20px;
  }
`;
const Chev = styled.div`
  overflow-y: hidden;
`;
const I = styled.i`
  //overflow-y: hidden;
`;
const BtnWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  //margin-bottom: 125px;
  @media screen and (max-width: 650px) {
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }
  @media screen and (max-width: 500px) {
    grid-gap: 5px;
    grid-template-columns: 1fr;
    margin-top: 50px;
  }
`;

const Hero3 = () => {
  return (
    <Container>
      <Img className="container-hero">
        <Wrapper>
          <Content>
            <Header>Bespoke Excellence in Construction</Header>
            <SubHeader>
              TURNING DREAMS INTO REALITY: YOUR VISION, OUR EXPERTISE.
            </SubHeader>

            <BtnWrap>
              <a href="https://www.youtube.com/watch?v=j3PBTPTmsqY">
                <Button buttonStyle="btn--outline" buttonSize="btn--large">
                  Watch Video
                </Button>
              </a>
              <Link to="/recent-projects">
                <Button buttonSize="btn--large">Our Work</Button>
              </Link>
            </BtnWrap>
          </Content>
          <Chev>
            <i className="fa-solid fa-chevron-down oy"></i>
          </Chev>
        </Wrapper>
      </Img>
    </Container>
  );
};

export default Hero3;
