import React from 'react';
import '../../App.css';

import '../Stakes/Stakes.css';
import Explanatory from '../Explanatory';
import Footer from '../Footer';
import Guide from '../Guide';
import HeroSection from '../HeroSection';
import ImageSlider from '../ImageSlider';
import InfoSection from '../InfoSection';
import Plan from '../Plan';
import { homeObjectOne } from '../InfoSection/Data';
import PlanHeader from '../PlanHeader';
import { SliderData } from '../SliderData';
import Stakes from '../Stakes';
import StakesBottom from '../StakesBottom';
import ValueProp from '../ValueProp';
import ValuePropHeader from '../ValuePropHeader';
import Maps from '../Maps';
import Hero from '../Hero';
import Value from '../Value';
import Hero2 from '../Hero2/Hero2';
import Stakes1, {
  Block,
  BlockDiv,
  BtnDiv,
  BtnWrap,
  Span,
} from '../Stakes/Stakes1';
import styled from 'styled-components';
import ValueSection, { Triangle } from '../valuesection/ValueSection';
import { Button } from '../Button';
import CTA from '../CTA/CTA';
import CaseStudy from '../Case Study/CaseStudy';
import Footer2 from '../Footer/Footer2';
import OnHundred from '../100/OnHundred';
import ValueSection2 from '../valuesection/ValueSection2';
import CTA2 from '../CTA/CTA2';

const En = styled.div`
  background-color: #081542;
  @media screen and (max-width: 768px) {
    //height: 30vh;
    background: #fff;
  }
`;
const Cont = styled.div`
  position: relative;
  z-index: 99;
`;
const Cont2 = styled.div`
  position: relative;
  z-index: 99;
  display: none;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Cont3 = styled.div`
  //display: none;
  position: relative;
  z-index: 99;

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

function HomePage() {
  return (
    <En>
      <Cont>
        <Hero2 />
        <OnHundred />

        <Triangle />
      </Cont>
      <ValueSection />
      <Cont>
        <ValueSection2 />
        <Cont2>
          <CaseStudy />
          <CTA />
          <Footer2 />
        </Cont2>
        <Cont3>
          <CTA2 />

          <Footer2 />
        </Cont3>
      </Cont>
    </En>
  );
}

export default HomePage;
