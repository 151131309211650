import React, { useEffect } from 'react';
import styled from 'styled-components';
import './About.css';
import Vision from './Vision';
import Img1 from '../../images/thumbnail.jpg';
import Ico1 from '../../images/ico1.png';
import Ico2 from '../../images/ico2.png';
import Ico3 from '../../images/ico3.png';
import Ico4 from '../../images/ico4.png';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Container = styled.div`
  /* height: 100vh;
  width: 100vw; */
  background: #fff;
`;
const Wrapper = styled.div`
  /* width: 100%;
  height: 100%; */
  display: flex;
  justify-content: center;
  align-items: left;
  margin: 50px;

  flex-direction: column;
`;
const Header = styled.h2`
  //color: #081452;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 15px;
  margin-left: 250px;
  margin-right: 250px;
  @media screen and (max-width: 1500px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
const Para = styled.div`
  font-weight: 300;
  color: #333;
`;
const ImgWrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Img = styled.img`
  object-fit: cover;
  height: 600px;
  width: 400px;
`;
const H1 = styled.h2`
  color: #081542;
  text-align: left;
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 25px;
  margin-left: 250px;
  margin-right: 250px;
  @media screen and (max-width: 1500px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 75px;
  margin-left: 250px;
  margin-right: 250px;
  @media screen and (max-width: 1500px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
  }
`;
const Content1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;
`;
const Energy = styled.div`
  width: 100vw;
  background: rgb(207, 207, 207);
`;
const Container2 = styled.div`
  background: rgb(237, 237, 237);
  height: 100%;
  @media screen and (max-width: 900px) {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Wrapper2 = styled.div`
  margin-left: 250px;
  @media screen and (max-width: 900px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const ImgWrap2 = styled.div`
  @media screen and (max-width: 900px) {
    margin-top: 25px;
    height: 400px;
  }
`;
const Img2 = styled.img`
  height: 99%;
  width: 100%;
  object-fit: cover;
`;
const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  @media screen and (max-width: 900px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const Content2 = styled.div`
  /* height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; */
  margin-right: 75px;
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  //justify-content: center;
`;
const Header2 = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  margin-top: 40px;
`;
const Blue2 = styled.div`
  color: #081542;
  font-size: 1.15rem;
  margin-top: 25px;
`;
const NotBlue2 = styled.div`
  color: #333;
  margin-top: 25px;
`;
const Container3 = styled.div`
  padding: 25px;
  padding-bottom: 75px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Wrapper3 = styled.div`
  display: flex;
  //gap: 50px;
  width: 70%;
  height: 100%;
  @media screen and (max-width: 1300px) {
    width: 80%;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;
const Header3 = styled.div`
  font-size: 2.5rem;
  padding-bottom: 50px;
`;
const Card3 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //overflow: hidden;
  @media screen and (max-width: 768px) {
    padding: 25px;
    gap: 20px;
  }

  @media screen and (max-width: 500px) {
    padding: 0px;
  }
`;
const Icon = styled.img`
  object-fit: cover;
  width: 125px;
  height: 100%;
  @media screen and (max-width: 768px) {
    width: 125px;
    height: 125px;
  }
`;
const Sub3 = styled.div`
  font-size: 1.5rem;
  margin-top: 15px;
  @media screen and (max-width: 1000px) {
    font-size: 1.2rem;
    overflow-y: hidden;
    margin-bottom: 15px;
    margin: 0px;
    height: 80px;
  }
  @media screen and (max-width: 500px) {
    height: 50px;
  }
`;

const Story = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <Container>
      <Wrapper data-aos="fade-up">
        <Header>Builders you can Trust</Header>
        <H1>BRW Construction Thrives on Constant Communication</H1>
        <Grid>
          {/* <Para>
            Finding builders who exude quality and experience can be a difficult
            task. Yet the real challenge appears when trying to find builders
            who you can consistently depend on, particularly in today's market,
            where a significant labor shortage has created hurdles for
            construction projects. Rushing into the market may leave you having
            to deal with delays, substandard work, and unfulfilled promises. At
            BRW, we find it unacceptable that you should have to deal with
            careless work.
          </Para> */}
          <Para>
            With over 15 years of proven expertise, BRW Construction is
            dedicated to making your project experience not just seamless but
            exceptional. Our track record of success stems from constant
            communication with our valued customers. We understand that your
            project is a significant investment, and we empower you to stay
            fully informed and in control every step of the way ensuring you
            remain in full control of your project.
          </Para>
        </Grid>
      </Wrapper>
      <Container3>
        <Header>Our Core Values</Header>
        <Wrapper3>
          <Card3 data-aos="fade-right">
            <Icon src={Ico1} />
            <Sub3>Integrity</Sub3>
          </Card3>
          <Card3 data-aos="fade-right">
            <Icon src={Ico2} />
            <Sub3>Quality</Sub3>
          </Card3>
          <Card3 data-aos="fade-left">
            <Icon src={Ico3} />
            <Sub3>Accountability</Sub3>
          </Card3>
          <Card3 data-aos="fade-left">
            <Icon src={Ico4} />
            <Sub3>Customer Focus</Sub3>
          </Card3>
        </Wrapper3>
      </Container3>
      <Container2>
        <Wrapper2>
          <Grid2>
            <Content2>
              <Header2>Our Mission</Header2>
              <Blue2>
                At BRW, our mission is clear: to excel in the construction
                industry by providing unmatched value to our customers. In an
                era of global warming and escalating energy expenses, we
                recognize that delivering exceptional value hinges on our
                commitment to energy efficiency.
              </Blue2>
              <NotBlue2>
                This approach not only enables our customers to make long-term
                savings by reducing energy costs but also enhances their quality
                of life with cozier winters and cooler summers.
              </NotBlue2>
              <NotBlue2>
                Also for savvy investors eyeing the real estate market,
                embracing the potential of energy efficiency is your ticket to a
                thriving portfolio. Energy-efficient homes are in high demand,
                and it's not hard to see why. These homes offer reduced utility
                costs, enhanced comfort, and a competitive edge in the market.
              </NotBlue2>
              <NotBlue2>
                Imagine a real estate portfolio where every property is a beacon
                of energy efficiency, consistently increasing in value over
                time. Can you afford to miss out on this movement? Now is the
                perfect time to explore the world of energy-efficient homes and
                watch your real estate portfolio thrive.
              </NotBlue2>
              <NotBlue2>
                We're dedicated to building a future where sustainable
                construction practices redfine comfort living and economic
                well-being.
              </NotBlue2>
            </Content2>
            <ImgWrap2>
              <Img2 src={Img1} alt="" />
            </ImgWrap2>
          </Grid2>
        </Wrapper2>
      </Container2>
    </Container>
  );
};

export default Story;
