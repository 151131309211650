import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import CTA from '../CTA/CTA';
import Footer2 from '../Footer/Footer2';
import ProjectCard2 from '../Projects/ProjectCard2';
import ProjectCards from '../Projects/ProjectCards';
import RecentProjects from '../Projects/RecentProjects';

const Cont = styled.div`
  position: relative;
  z-index: 50;
  margin-bottom: 30vh;
  background: gray;
  @media screen and (max-width: 500px) {
    margin-bottom: 44.9vh;
  }
`;

const WorkPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Recent Projects - BRW Construction, Nottingham, Burton Joyce
        </title>
        <meta
          name="description"
          content="We showcase our recent projects so that you know, when working with BRW Construction, you are getting high-end, reliable construction."
        />
        <link rel="canonical" href="/recent-projects" />
      </Helmet>
      <Cont>
        <RecentProjects />

        <ProjectCards />
        <CTA />
      </Cont>
      <Footer2 />
    </>
  );
};

export default WorkPage;
