import React from 'react';
import styled from 'styled-components';

const Img = styled.img`
  height: 200px;
  width: 200px;
  object-fit: cover;
`;

const CardFuzz = (props) => {
  return (
    <div>
      <Img src={props.src} />
    </div>
  );
};

export default CardFuzz;
