import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';
import { Button } from './Button.js';

const Contact2 = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_z511cjq',
        'template_vwvi1lf',
        form.current,
        'i3BtttRuNhmo2u7ru'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <section>
      <div className="contact1">
        <h2 className="contact-sub-header1">CONTACT US FOR A QUICK ENQUIRY</h2>
        <div className="contact-container1">
          <form ref={form} onSubmit={sendEmail} className="contact-form --card">
            <div className="contact-input-items1">
              <p className="contact-input-header1">Name:</p>
              <input
                type="text"
                className="input-box1"
                placeholder="Full Name"
                name="user_name"
                required
              />
            </div>
            <div className="contact-input-items1">
              <p className="contact-input-header1">Email:</p>
              <input
                className="input-box1"
                type="email"
                placeholder="Email"
                name="user_email"
                required
              />
            </div>
            <div className="contact-input-items1">
              <p className="contact-input-header1">Subject:</p>
              <input
                className="input-box1"
                type="text"
                placeholder="Construction project"
                name="subject"
                required
              />
            </div>
            <div className="contact-input-items1">
              <p className="contact-input-header1">Message:</p>
              <textarea
                className="input-box2"
                placeholder=""
                name="message"
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div className="contact-input-items1">
              <div className="contact-btn1">
                {' '}
                <Button
                  className="contact-btn1"
                  buttonStyle="btn--primary"
                  buttonSize="btn--large"
                  type="submit"
                >
                  {' '}
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact2;
