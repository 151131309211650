import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CTA from '../../CTA/CTA';
import ImgR from '../../../images/renovation-pic.jpg';
import Footer2 from '../../Footer/Footer2';
import ImgLead from '../../../images/embracing.png';
const Container = styled.div`
  width: 100vw;
  height: 100%;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  background-color: rgb(202, 210, 255);
  background-color: rgb(230, 230, 230);
  position: relative;
  z-index: 50;
`;
const Wrapper = styled.div`
  margin-left: 200px;
  width: 800px;
  //height: 150vh;
  @media screen and (max-width: 1400px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 900px) {
    width: 80vw;
    margin-left: 50px;
  }
  @media screen and (max-width: 400px) {
    margin-left: 25px;
  }
`;
const Header = styled.div`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 25px;
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 2rem;
  }
`;
const Para = styled.div`
  color: #333;
  font-weight: 300;
  margin-top: 25px;
  margin-bottom: 25px;
`;
const SubHeader = styled.div`
  font-weight: 500;
  font-size: 2rem;
`;
const Sticky = styled.div`
  position: fixed;
  z-index: 0;
  margin-right: 75px;
  right: 0;
  top: 0;
  height: 100vh;
  width: 350px;
  background: rgb(237, 237, 237);
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1500px) {
    margin-right: 25px;
  }
  @media screen and (max-width: 1300px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 1275px) {
    display: none;
  }
`;
const Span = styled.span`
  color: #000;
  text-decoration: underline;
  transition: 300ms ease;
  &:hover {
    cursor: pointer;
    color: blue;
  }
`;
const Img = styled.img`
  height: 250px;
  width: 500px;
  object-fit: cover;
`;

const LeadGen = styled.div`
  background: #fff;
  //background: #071023;
  //height: 20vh;
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  margin-top: 75px;
  @media screen and (max-height: 650px) {
    margin-top: 125px;
  }
`;
const LeadImg = styled.img`
  height: 250px;
  @media screen and (max-height: 700px) {
    height: 200px;
  }
  @media screen and (max-height: 650px) {
    height: 225px;
  }
  @media screen and (max-height: 550px) {
    height: 200px;
  }
`;
const LeadImgWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
  align-items: center;
`;
const LeadDiv = styled.div`
  //color: #333;
  padding: 25px;
  font-weight: 500;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  background: #071023;
  @media screen and (max-height: 700px) {
    font-size: 1rem;
    padding: 5px;
  }
  @media screen and (max-height: 600px) {
    display: none;
  }
`;
const CTADiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const Div = styled.div`
  @media screen and (max-height: 650px) {
    font-size: 0.9rem;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
`;
const BlogContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  //align-items: center;
  //justify-content: center;
  background: #fff;
  margin-left: 350px;
  width: 825px;
  padding-top: 50px;
  height: 250vh;
`;
const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-left: 25px;
  grid-row-gap: 0px;
`;
const Wrap = styled.div`
  position: relative;
  z-index: 49;
  height: 500vh;
  width: 100vw;
`;
const Btn = styled.div`
  padding: 10px 20px;
  font-size: 16px;
  //min-width: 50px;
  max-width: 250px;
  transition: 300ms ease-in-out;
  background: #081542;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid #071023;
    cursor: pointer;
  }
`;
// const CTADiv = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   text-align: center;
// `;
// const Div = styled.div``;

const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Renovation = () => {
  return (
    <>
      <Container>
        <Sticky>
          <LeadGen>
            <LeadDiv>Embracing Energy Efficiency</LeadDiv>
            <LeadImgWrap>
              <LeadImg src={ImgLead} alt="free pdf" />
            </LeadImgWrap>
            <CTADiv>
              <Div>TAKE CONTROL OF YOUR ENERGY BILLS:</Div>
              <Div>DOWNLOAD OUR FREE GUIDE NOW</Div>
              <a href="https://mailchi.mp/4cb5ae9e1389/embracing-energy-efficiecny">
                <BtnWrap>
                  <Btn>DOWNLOAD THE GUIDE</Btn>
                </BtnWrap>
              </a>
            </CTADiv>
          </LeadGen>
        </Sticky>
        <Wrapper>
          <Header>10 Essential Tips for Planning Your Home Renovation</Header>
          <Img src={ImgR} alt="" />
          <Para>
            Are you gearing up for an exciting home renovation project? Planning
            is the key to success when it comes to remodeling your living space.
            Whether you're looking to upgrade your kitchen, add a new room, or
            give your entire home a facelift, careful planning can make the
            process smoother and more satisfying. Here are 10 essential tips to
            help you get started on the right track.
          </Para>
          <SubHeader>1. Set Clear Goals</SubHeader>
          <Para>
            Before you dive into any renovation project, define your objectives.
            What do you want to achieve with this renovation? Whether it's
            enhancing functionality, increasing energy efficiency, or boosting
            curb appeal, having clear goals will guide your decisions throughout
            the process.
          </Para>
          <SubHeader>2. Create a Realistic Budget</SubHeader>
          <Para>
            Budgeting is crucial for a successful renovation. Calculate your
            total budget, including materials, labor, permits, and a contingency
            fund for unexpected expenses. Stick to your budget as closely as
            possible to avoid financial stress down the road.
          </Para>
          <SubHeader>3. Research and Gather Inspiration</SubHeader>
          <Para>
            Explore home improvement magazines, websites, and social media
            platforms for inspiration. Collect photos, ideas, and design
            concepts that resonate with your vision. This will help you
            communicate your preferences to your contractor.
          </Para>
          <SubHeader>4. Find the Right Contractor</SubHeader>
          <Para>
            Choosing the right contractor is perhaps the most critical decision
            you'll make. Research local contractors, read client testimonials,
            and look at past work to ensure you're hiring a reputable team. You
            should be able to find this information on the companies website.
          </Para>
          <SubHeader>5. Secure Necessary Permits</SubHeader>
          <Para>
            Most renovations require permits from your local government.
            Skipping this step can lead to costly delays and legal issues. Work
            with your contractor to obtain the necessary permits and ensure
            compliance with building codes.
          </Para>
          <SubHeader>6. Create a Detailed Project Timeline</SubHeader>
          <Para>
            Develop a timeline that outlines every phase of your renovation.
            Include milestones, deadlines, and any events that may impact the
            project's progress. A well-structured timeline helps keep everyone
            on the same page.
          </Para>
          <SubHeader>7. Prepare for Temporary Disruptions</SubHeader>
          <Para>
            Renovations can be disruptive to your daily life. Plan for temporary
            inconveniences, such as restricted access to certain areas of your
            home or noise disturbances. Temporary living arrangements may be
            necessary for extensive projects.
          </Para>
          <SubHeader>8. Choose Quality Materials</SubHeader>
          <Para>
            Invest in high-quality materials that will stand the test of time.
            While it may be tempting to cut costs, durable materials can save
            you money in the long run by reducing the need for repairs and
            replacements.
          </Para>
          <SubHeader>9. Communicate Effectively</SubHeader>
          <Para>
            Maintaining open communication with your contractor and design team
            is essential. Regularly discuss progress, address concerns, and make
            decisions promptly to keep the project on track.
          </Para>
          <SubHeader>10. Be Flexible and Patient</SubHeader>
          <Para>
            Finally, remain flexible and patient throughout the renovation
            process. Unexpected issues can arise, but a positive attitude and
            adaptability will help you navigate challenges with ease.
          </Para>
          <Para>
            By following these 10 essential tips, you'll be well-prepared to
            embark on your home renovation journey. Whether it's a small update
            or a major transformation, careful planning is the key to a
            successful and stress-free renovation experience.
          </Para>
          <Para>
            For professional assistance with your home renovation project,{' '}
            <Span>
              <Link to="/contact">contact us</Link>
            </Span>{' '}
            today.
          </Para>
        </Wrapper>
      </Container>
    </>
  );
};

export default Renovation;
