import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import Img1 from '../../images/person.jpg';
import './Card.css';
const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  background: #fff;
`;
const Wrapper = styled.div`
  width: 80%;
  //height: 5px;
  border-top: 1px solid #081542;
`;
const Img = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 45px;
`;
const ImgWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Author = styled.div`
  color: #333;
  color: #081542;
  //text-align: center;
  margin-top: 25px;
  margin-left: 10px;
  font-weight: 500;
`;
const Content = styled.div`
  font-size: 1.4rem;
  color: #333;
  //margin-top: 50px;

  margin-top: 25px;
  margin-left: 10px;

  overflow-y: hidden;
  @media screen and (max-width: 1200px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.1rem;
  }
`;
const Div = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;
  display: flex;
  flex-direction: column-reverse;
`;
const Quote = styled.div`
  font-size: 2rem;
  color: #081542;
  text-align: left;
  overflow-y: hidden;
  //margin-top: 50px;
`;
const Div2 = styled.div`
  margin-top: 150px;
`;
const Card = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <Container>
      <Wrapper>
        <Div>
          <ImgWrap>
            {/* <div ref={ref}>
              {inView ? <Img src={props.src} alt="" /> : <Div2></Div2>}
            </div> */}
            <Author>- {props.author}</Author>
          </ImgWrap>
          <div>
            <Content>
              <Quote>
                <i class="fa-solid fa-quote-left oy"></i>
              </Quote>
              {props.content}
            </Content>
          </div>
        </Div>
      </Wrapper>
    </Container>
  );
};

export default Card;
