import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import Contact from '../Contact/Contact';
import Footer2 from '../Footer/Footer2';

const Cont = styled.div`
  background: #fff;
  position: relative;
  z-index: 50;
  margin-bottom: 30vh;
  @media screen and (max-width: 768px) {
    margin-bottom: 44.9vh;
  }
`;

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact Page - BRW Construction, Nottingham, Burton Joyce</title>
        <meta
          name="description"
          content="Easily get in touch and begin your project journey!"
        />
        <link rel="canonical" href="/contact" />
      </Helmet>
      <Cont>
        <Contact />
      </Cont>
      <Footer2 />
    </>
  );
};

export default ContactPage;
