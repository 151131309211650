import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Span = styled.span`
  font-size: 3rem;
  color: #081542;
  color: #333;
  font-weight: 300;
`;

const NumberCounter2 = ({ targetValue2, duration2 }) => {
  const [currentValue2, setCurrentValue2] = useState(0);

  useEffect(() => {
    if (targetValue2 <= 0) return;

    const intervalId = setInterval(() => {
      if (currentValue2 < targetValue2) {
        setCurrentValue2((prevValue2) => prevValue2 + 1);
      } else {
        clearInterval(intervalId);
      }
    }, duration2 / targetValue2);

    // Clear the interval when the animation is complete
    return () => clearInterval(intervalId);
  }, [currentValue2, targetValue2, duration2]);

  // Trigger the animation when the component mounts
  useEffect(() => {
    setCurrentValue2(0); // Start from 0
  }, [targetValue2]);

  return <Span>{currentValue2}</Span>;
};

export default NumberCounter2;
