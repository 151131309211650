import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Wrapper = styled.div``;
const ImgWrap = styled.div`
  width: 100%;
  height: 100%;
  /* width: 400px;
  height: 350px; */
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  //position: absolute;
  position: relative; /* Required for positioning the pseudo-element */
  display: inline-block;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the color and opacity as needed */
    opacity: 0; /* Initially transparent */
    transition: opacity 0.3s ease-in-out; /* Add a smooth transition effect */
  }
  &:hover::before {
    opacity: 1; /* Show the background color on hover */
  }

  /* Adjust image appearance if needed */

  &:hover {
    cursor: pointer;
  }
  &:hover {
    transform: scale(1.05);
  }
  transition-duration: 500ms;
`;
const Img = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  margin-left: -2px;
  transition-duration: 500ms;
  display: block;
  max-width: 100%;
  //height: auto;
  // overflow: hidden;
  &:hover {
    transform: scale(1.2);
  }
`;
const H1 = styled.h2`
  padding-top: 25px;
  //text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 1.5em;
  @media screen and (max-width: 1100px) {
    width: 275px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
  }
`;
const P = styled.p`
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 0.9rem;
  text-decoration: none;
  @media screen and (max-width: 1100px) {
    width: 250px;
  }
  @media screen and (max-width: 1000px) {
    width: 225px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    //max-width: 500px;
  }
`;
const BtnWrap = styled.div``;
const Content = styled.div`
  width: 100%;
  height: 250px;
  border-left: 1px solid rgb(207, 207, 207);
  margin-top: -10px;
  padding-left: 15px;
  color: rgb(190, 190, 190);
  @media screen and (max-width: 1200px) {
    width: 300px;
    height: 100%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    max-width: 100vw;
  }
`;
const ImgText = styled.div`
  position: absolute;
  top: 50%; /* Adjust the vertical position of the text */
  left: 50%; /* Adjust the horizontal position of the text */
  text-align: center;
  transform: translate(
    -50%,
    -50%
  ); /* Center the text both horizontally and vertically */
  //background-color: rgba(0, 0, 0, 0.7); /* Background color for the text */
  color: white; /* Text color */
  padding: 10px; /* Adjust padding as needed */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
`;
const ImgWrapWrap = styled.div`
  height: 350px;
  width: 400px;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    width: 300px;
    height: 350px;
  }
  @media screen and (max-width: 900px) {
    width: 90vw;
  }
  @media screen and (max-width: 500px) {
    height: 250px;
    width: 90vw;
  }
`;
const Span = styled.div`
  //margin-left: 5px;
  text-decoration: underline;
  color: rgb(190, 190, 190);
  margin-top: 5px;
  &:hover {
    color: #fff;
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Card = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <Wrapper>
      <Link to={props.link}>
        <ImgWrapWrap>
          <ImgWrap className="image-container">
            <Img src={props.src} alt="" />
            <ImgText className="image-text">{props.header}</ImgText>
          </ImgWrap>
        </ImgWrapWrap>
      </Link>
      <Content>
        <H1>{props.h1}</H1>
        <P>
          {props.p}
          <Link to={props.link}>
            <Span>{props.cta}</Span>
          </Link>
        </P>
      </Content>
    </Wrapper>
  );
};

export default Card;
