import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CTA from '../../CTA/CTA';
import ImgR from '../../../images/bath.jpg';
import Footer2 from '../../Footer/Footer2';
import ImgLead from '../../../images/embracing.png';
const Container = styled.div`
  width: 100vw;
  height: 100%;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  background-color: rgb(202, 210, 255);
  background-color: rgb(230, 230, 230);
  position: relative;
  z-index: 50;
`;
const Wrapper = styled.div`
  margin-left: 200px;
  width: 800px;
  //height: 150vh;
  @media screen and (max-width: 1400px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 900px) {
    width: 80vw;
    margin-left: 50px;
  }
  @media screen and (max-width: 400px) {
    margin-left: 25px;
  }
`;
const Header = styled.div`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 25px;
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 2rem;
  }
`;
const Para = styled.div`
  color: #333;
  font-weight: 300;
  margin-top: 25px;
  margin-bottom: 25px;
`;
const List = styled.ol`
  font-weight: 300;
  color: #333;
`;
const Span2 = styled.span`
  color: #000;
  font-weight: 400;
`;
const Li = styled.li`
  margin-bottom: 15px;
`;
const SubHeader = styled.div`
  font-weight: 500;
  font-size: 2rem;
`;
const Sticky = styled.div`
  position: fixed;
  z-index: 0;
  margin-right: 75px;
  right: 0;
  top: 0;
  height: 100vh;
  width: 350px;
  background: rgb(237, 237, 237);
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1500px) {
    margin-right: 25px;
  }
  @media screen and (max-width: 1300px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 1275px) {
    display: none;
  }
`;
const Span = styled.span`
  color: #000;
  text-decoration: underline;
  transition: 300ms ease;
  &:hover {
    cursor: pointer;
    color: blue;
  }
`;
const Img = styled.img`
  height: 250px;
  width: 500px;
  object-fit: cover;
`;

// const LeadGen = styled.div`
//   background: #fff;
//   //background: #071023;
//   //height: 20vh;
//   width: 100%;
//   padding-bottom: 20px;
//   margin-top: 75px;
// `;
// const LeadImg = styled.img`
//   height: 250px;
// `;
// const LeadImgWrap = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: center;
//   padding: 25px;
//   align-items: center;
// `;
// const LeadDiv = styled.div`
//   //color: #333;
//   padding: 25px;
//   font-weight: 500;
//   color: #fff;
//   font-size: 1.1rem;
//   text-align: center;
//   background: #071023;
// `;
const LeadGen = styled.div`
  background: #fff;
  //background: #071023;
  //height: 20vh;
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  margin-top: 75px;
  @media screen and (max-height: 650px) {
    margin-top: 125px;
  }
`;
const LeadImg = styled.img`
  height: 250px;
  @media screen and (max-height: 700px) {
    height: 200px;
  }
  @media screen and (max-height: 650px) {
    height: 225px;
  }
  @media screen and (max-height: 550px) {
    height: 200px;
  }
`;
const LeadImgWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
  align-items: center;
`;
const LeadDiv = styled.div`
  //color: #333;
  padding: 25px;
  font-weight: 500;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  background: #071023;
  @media screen and (max-height: 700px) {
    font-size: 1rem;
    padding: 5px;
  }
  @media screen and (max-height: 600px) {
    display: none;
  }
`;
const CTADiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const Div = styled.div`
  @media screen and (max-height: 650px) {
    font-size: 0.9rem;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
`;
const BlogContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  //align-items: center;
  //justify-content: center;
  background: #fff;
  margin-left: 350px;
  width: 825px;
  padding-top: 50px;
  height: 250vh;
`;
const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-left: 25px;
  grid-row-gap: 0px;
`;
const Wrap = styled.div`
  position: relative;
  z-index: 49;
  height: 500vh;
  width: 100vw;
`;
const Btn = styled.div`
  padding: 10px 20px;
  font-size: 16px;
  //min-width: 50px;
  max-width: 250px;
  transition: 300ms ease-in-out;
  background: #081542;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid #071023;
    cursor: pointer;
  }
`;
// const CTADiv = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   text-align: center;
// `;
// const Div = styled.div``;

const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Interior = () => {
  return (
    <>
      <Container>
        <Sticky>
          <LeadGen>
            <LeadDiv>Embracing Energy Efficiency</LeadDiv>
            <LeadImgWrap>
              <LeadImg src={ImgLead} alt="free pdf" />
            </LeadImgWrap>
            <CTADiv>
              <Div>TAKE CONTROL OF YOUR ENERGY BILLS:</Div>
              <Div>DOWNLOAD OUR FREE GUIDE NOW</Div>
              <a href="https://mailchi.mp/4cb5ae9e1389/embracing-energy-efficiecny">
                <BtnWrap>
                  <Btn>DOWNLOAD THE GUIDE</Btn>
                </BtnWrap>
              </a>
            </CTADiv>
          </LeadGen>
        </Sticky>
        <Wrapper>
          <Header>The Impact of Interior Design on Your Well-Being</Header>
          <Img src={ImgR} alt="" />
          <Para>
            Your home is more than just a place to live; it's a sanctuary where
            you seek comfort, relaxation, and happiness. The way you design and
            arrange your interior spaces can significantly impact your
            well-being and overall quality of life. In this blog post, we'll
            delve into how interior design choices can influence your mental and
            emotional health, and we'll provide tips on creating a harmonious
            living environment that promotes wellness.
          </Para>
          <SubHeader>Colors and Emotions</SubHeader>
          <Para>
            The choice of colors in your home can have a profound effect on your
            mood and emotions. Different colors evoke different feelings, and
            understanding this psychology of color is essential in interior
            design.
          </Para>
          <List>
            <Li>
              <Span2>Blue: </Span2>This color is known for its calming and
              soothing effects. It can reduce stress and anxiety, making it an
              excellent choice for bedrooms and relaxation areas.
            </Li>
            <Li>
              <Span2>Green: </Span2>Associated with nature, green promotes a
              sense of tranquility and balance. It's ideal for spaces where you
              want to feel refreshed and rejuvenated, such as bathrooms or
              meditation corners.
            </Li>
            <Li>
              <Span2>Yellow: </Span2>Yellow is a cheerful and energetic color
              that can boost your mood and creativity. It's perfect for kitchens
              and dining rooms, where it can stimulate conversation and
              appetite.
            </Li>
            <Li>
              <Span2>Neutral Colours: </Span2>Shades of white, gray, and beige
              create a sense of openness and simplicity. They can make small
              spaces feel more significant and provide a clean, uncluttered look
            </Li>
          </List>

          <SubHeader>Natural Light and Well-Being</SubHeader>
          <Para>
            Exposure to natural light is vital for your well-being. It regulates
            your circadian rhythm, enhances mood, and supports physical health.
            When designing your interior spaces, prioritize natural light
            sources like large windows and skylights. Arrange your furniture to
            maximize sunlight penetration during the day, which not only reduces
            the need for artificial lighting but also promotes a positive
            atmosphere.
          </Para>
          <SubHeader>Declutter for Mental Clarity</SubHeader>
          <Para>
            A cluttered living space can lead to mental clutter and stress. Make
            decluttering a part of your interior design strategy. Organize your
            belongings, get rid of unnecessary items, and create a clean and
            clutter-free environment. Minimalist design principles,
            characterized by simplicity and functionality, can help you achieve
            a sense of calm and mental clarity.
          </Para>
          <SubHeader>Biophilic Design: Bringing Nature Indoors</SubHeader>
          <Para>
            Biophilic design is a concept that seeks to connect people with
            nature within their living spaces. Incorporating natural elements
            such as houseplants, natural materials (wood, stone), and
            nature-inspired artwork can reduce stress and increase feelings of
            happiness and well-being. Adding indoor plants not only purifies the
            air but also provides a sense of vitality and serenity
          </Para>
          <SubHeader>Personalization for Comfort</SubHeader>
          <Para>
            Your home should reflect your personality and cater to your specific
            needs. Personalize your interior design by incorporating elements
            that resonate with you, such as artwork, family photos, or cherished
            heirlooms. Surrounding yourself with meaningful items can create a
            sense of belonging and comfort.
          </Para>
          <Para>
            In conclusion, interior design is more than just aesthetics; it's
            about creating an environment that nurtures your well-being. By
            understanding the impact of colors, natural light, decluttering,
            biophilic design, and personalization, you can transform your living
            space into a haven of positivity and relaxation. Your home should be
            a place where you can recharge, find inspiration, and experience
            joy—so let your interior design choices support your well-being.
          </Para>
          <Para>
            Remember that the right interior design can make a significant
            difference in your life. If you're considering a home renovation or
            remodeling project, consult with our experts at{' '}
            <Span>
              <Link to="/contact"> BRW Construction</Link>
            </Span>{' '}
            to create a space that promotes your well-being.
          </Para>
        </Wrapper>
      </Container>
    </>
  );
};

export default Interior;
