import React from 'react';
import { Link } from 'react-router-dom';
import './Button3.css';

const STYLES = [
  'btn--primary',
  'btn--outline',
  'btn-outline-nav',
  'btn--guide1',
];

const SIZES = ['btn--medium1', 'btn--large1'];

export const Button3 = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <button
      className={`btn2 ${checkButtonStyle} ${checkButtonSize}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};
