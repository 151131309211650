import React from 'react';
import styled from 'styled-components';
import Img1 from '../../images/solar.jpg';

const Container = styled.div`
  height: 1540px;
  width: 100vw;
  position: relative;
  //background: #071023;
  @media screen and (max-width: 900px) {
    height: 1500px;
  }
  @media screen and (max-width: 700px) {
    height: 1450px;
  }
`;
const Wrapper = styled.div`
  //height: 100vh;
  display: flex;
  //align-items: center;
  justify-content: center;
`;
const Header = styled.h1`
  font-family: 'Roboto Slab', serif;
  //font-family: 'Playfair Display', serif;
  color: #fff;
  font-size: 8rem;
  margin-top: 210px;
  margin-right: 300px;
  position: relative; /* Relative positioning for the Header */
  z-index: 2;
  @media screen and (max-width: 1450px) {
    margin-right: 250px;
  }
  @media screen and (max-width: 1400px) {
    font-size: 6rem;
    margin-top: 245px;
    margin-right: 200px;
  }
  @media screen and (max-width: 1000px) {
    margin-right: 100px;
  }
  @media screen and (max-width: 900px) {
    font-size: 5.5rem;
    margin-top: 255px;
    margin-right: 0px;
  }
  @media screen and (max-width: 800px) {
    font-size: 5rem;
    margin-top: 265px;
    margin-right: 0px;
    width: 95vw;
  }
  @media screen and (max-width: 700px) {
    margin-top: 145px;
    text-align: left;
    margin-left: 40px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 165px;
    font-size: 4.5rem;
    margin-left: 10px;
  }
  @media screen and (max-width: 370px) {
    margin-top: 165px;
    font-size: 4.5rem;
  }
`;
const Line = styled.div`
  height: 850px;
  border-left: 2px solid #fff;
  position: absolute; /* Absolute positioning for the image */
  top: 760px; /* Position the image vertically at 50% of the parent container */
  left: 20%; /* Position the image horizontally at 50% of the parent container */
  transform: translate(-50%, -25%); /* Center the image perfectly */
  z-index: 1;
  @media screen and (max-width: 1400px) {
    left: 15%;
  }
  @media screen and (max-width: 1200px) {
    left: 12%;
  }
  @media screen and (max-width: 1000px) {
    left: 80px;
  }
  @media screen and (max-width: 950px) {
    left: 50px;
  }
  @media screen and (max-width: 700px) {
    left: 40px;
  }
  @media screen and (max-width: 500px) {
    height: 700px;
  }
`;
const Image = styled.img`
  position: absolute; /* Absolute positioning for the image */
  top: 760px; /* Position the image vertically at 50% of the parent container */
  left: 60%; /* Position the image horizontally at 50% of the parent container */
  transform: translate(-50%, -42.5%); /* Center the image perfectly */
  z-index: 1; /* Ensure the image appears behind the text */
  width: 850px;
  height: 950px;
  object-fit: cover;
  @media screen and (max-width: 900px) {
    width: 80vw;
  }
`;
const Block = styled.div`
  padding-top: 50px;
  padding-right: 50px;
  background-color: #071023;
  position: absolute;
  z-index: 2;
  top: 800px; /* Position the image vertically at 50% of the parent container */
  left: 37.5%; /* Position the image horizontally at 50% of the parent container */
  transform: translate(-50%, 50%); /* Center the image perfectly */
  z-index: 1; /* Ensure the image appears behind the text */
  width: 400px;
  font-size: 1.2rem;
  color: #fff;
  height: 400px;
  @media screen and (max-width: 1000px) {
    left: 325px;
  }
  @media screen and (max-width: 950px) {
    left: 300px;
  }
  @media screen and (max-width: 700px) {
    left: 175px;
    font-size: 1rem;
    padding-right: 25px;
    width: 300px;
  }
  @media screen and (max-width: 500px) {
    top: 800px;
  }
`;
const Energy = () => {
  return (
    <Container>
      <Wrapper>
        <Header>Energy Efficiency</Header>
        <Image src={Img1} alt="Energy Image" />
        <Line></Line>
        <Block>
          Every day, ignoring energy efficiency not only costs you money but
          also precious time. Enhance your comfort, reduce future energy bills,
          and boost your home's value by upgrading to an energy-efficient home.
          Prepare for the future with one of today's smartest investments.
        </Block>
      </Wrapper>
    </Container>
  );
};

export default Energy;
