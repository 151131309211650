import React, { useState, useEffect } from 'react';

const AlphabeticalCounter = ({ targetWord, duration }) => {
  const [currentWord, setCurrentWord] = useState('A'.repeat(targetWord.length));
  const targetWordArray = Array.from(targetWord);

  useEffect(() => {
    const timer = setInterval(() => {
      let newWord = '';
      let animationComplete = true;

      for (let i = 0; i < targetWordArray.length; i++) {
        const currentChar = currentWord.charCodeAt(i);
        const targetChar = targetWordArray[i].charCodeAt(0);

        if (currentChar < targetChar) {
          newWord += String.fromCharCode(currentChar + 1);
          animationComplete = false;
        } else {
          newWord += String.fromCharCode(targetChar);
        }
      }

      setCurrentWord(newWord);

      if (animationComplete) {
        clearInterval(timer);
      }
    }, duration);

    return () => clearInterval(timer);
  }, [currentWord, targetWordArray, duration]);

  return <span>{currentWord}</span>;
};

export default AlphabeticalCounter;
