import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import './Button.css';

function HeroSection() {
  return (
    <div className="hero-container">
      <h1>Construction work you can rely on</h1>
      <p>All aspects of building work undertaken</p>
      <div className="hero-btns">
        <Link to="/video" className="btn-mobile">
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
          >
            WATCH VIDEO <i className="far fa-play-circle" />
          </Button>
        </Link>
        <Link to="/contact" className="btn-mobile">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
          >
            Schedule a visit
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default HeroSection;
