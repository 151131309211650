import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import ImgLead from '../../images/5MistakesPeopleMake.jpg';
import LeadGenerator from '../LeadGen/LeadGenerator';
import LeadPopUp from '../LeadGen/LeadPopUp';

const popUpAnimation = keyframes`
  0% {
    transform: translateX(-1000px)
  }
  
  100% {
    transform: translateX(0px);
  }
`;

const PopupContainer = styled.div`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  border-radius: 10px;
  left: 0;
  bottom: 0;
  /* top: 50%;
  left: 50%; */
  //transform: translate(-50%, -50%);
  background-color: rgba(300, 300, 300, 0.5);
  //padding: 20px;
  // border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  //transform: scale(1.1);
  overflow: hidden;
  animation: ${popUpAnimation} 0.3s cubic-bezier(0.25, 1, 0.5, 1);
`;

const CloseButton = styled.button`
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
`;
const Container = styled.div`
  background-color: #071023;
  background: rgb(237, 237, 237);
  // background-color: #fff;
  // background: #071023;
  //height: 70vh;
  //width: 200px;
  //padding-top: 20vh;
  overflow: hidden;
  //padding-bottom: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //display: none;
  //position: absolute;
  @media screen and (max-width: 1000px) {
    display: flex;
    //padding-bottom: 50px;
    //padding-top: 50px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 25px;
  }
  @media screen and (max-width: 500px) {
    //padding-bottom: 5vh;
  }
  @media screen and (max-width: 380px) {
    //padding-top: 0px;
  }
`;
const Wrapper = styled.div`
  background: rgb(207, 207, 207);
  background: #fff;
  //background: rgb(237, 237, 237);
  height: 35vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 6fr;
  //position: absolute;
  //display: flex;
`;
const OnTop = styled.div`
  background: rgb(207, 207, 207);

  width: 0;
  height: 0;
  border-left: 100vw solid rgb(237, 237, 237); /* Adjust the width as needed */
  border-bottom: 100px solid #fff;
`;
const WrapperWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeadGen = styled.div`
  background: rgb(237, 237, 237);
  //background: #071023;
  //height: 20vh;
  width: 100%;
  height: 100%;
  //padding-bottom: 20px;
  //margin-top: 75px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 1000px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
    width: 300px;
  }
`;
const LeadImg = styled.img`
  height: 250px;
  @media screen and (max-width: 768px) {
    height: 200px;
  }
`;
const LeadImgWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;
const LeadDiv = styled.div`
  //color: #333;
  padding: 25px;
  font-weight: 500;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  background: #071023;
`;
const Btn = styled.div`
  padding: 10px 20px;
  font-size: 16px;
  //min-width: 50px;
  max-width: 250px;
  transition: 300ms ease-in-out;
  background: #081542;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid #071023;
    cursor: pointer;
  }
`;
const CTADiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // text-align: center;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
const Div = styled.div`
  font-weight: 500;
  font-family: 'Roboto Slab', serif;
  font-size: 1.5rem;
  color: #333;
`;
const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  //justify-content: center;
  margin-top: 10px;
`;
const BtnWrap2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const Red = styled.div`
  color: red;
  margin-top: 20px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: darkred;
  }
`;
const Red2 = styled.div`
  color: red;
  margin-top: 10px;
  text-decoration: underline;
  padding: 10px;
  &:hover {
    cursor: pointer;
    color: darkred;
  }
`;
const Div3 = styled.div`
  text-align: center;
`;
const Small = styled.div`
  display: none;
  @media screen and (max-width: 1100px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
`;
const Div2 = styled.div`
  font-weight: 300;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin-top: 5px;
  }
`;
const PopupAd = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 30000); // 10 seconds in milliseconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <PopupContainer isVisible={isVisible}>
      <Container>
        <WrapperWrap>
          <LeadGen>
            <LeadImgWrap>
              <LeadImg src={ImgLead} alt="free pdf" />
            </LeadImgWrap>
            <Small>
              <Div2>The 5 mistakes people make when designing their home:</Div2>

              {/* <Div3>DOWNLOAD OUR FREE PDF NOW</Div3> */}
              <a href="https://mailchi.mp/a08c35c35b8c/home-design-tips">
                <BtnWrap2>
                  <Btn>DOWNLOAD THE PDF</Btn>
                </BtnWrap2>
              </a>
              <Red2 onClick={() => setIsVisible(false)}>
                No thanks, I'm not planning a new project
              </Red2>
            </Small>
            <CTADiv>
              <Div>TAKE CONTROL OF YOUR PROJECT:</Div>
              <Div>DOWNLOAD OUR FREE PDF NOW</Div>
              <a href="https://mailchi.mp/a08c35c35b8c/home-design-tips">
                <BtnWrap>
                  <Btn>DOWNLOAD THE PDF</Btn>
                </BtnWrap>
              </a>
              <BtnWrap>
                <Red onClick={() => setIsVisible(false)}>
                  No thanks, I'm not planning a new project
                </Red>
              </BtnWrap>
            </CTADiv>
          </LeadGen>
        </WrapperWrap>
      </Container>
    </PopupContainer>
  );
};

export default PopupAd;
