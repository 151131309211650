import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BsFillArrowLeftCircleFill, BsTypeBold } from 'react-icons/bs';
import './TheGuide.css';
import { TestData } from './TestData';
import { Button } from '../Button';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import SQR from '../../images/square.png';

const Container = styled.div`
  height: 70vh;
  height: 100%;
  width: 100vw;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 150px;
  padding-top: 50px;

  @media screen and (max-width: 768px) {
    height: 110vh;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 25px;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 75px;
  overflow-y: hidden;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-top: 25px;
  }
`;
const One = styled.div`
  //height: 70vh;
  //width: 100%;
  overflow: hidden;
  //margin-top: 60px;
  @media screen and (max-width: 768px) {
    height: 100%;
    margin-bottom: 40px;
    margin-top: 0;
  }
`;
const Two = styled.div`
  //height: 70vh;
  //width: 100%;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  // justify-content: center;
  //padding-left: 200px;
  //background: #081542;
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;
const Content1 = styled.div`
  //background: #081542;
  //background: #071023;
  width: 62.5vw;
  background: rgb(237, 237, 237);
  padding-top: 50px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  //padding: 150px;
  position: relative;
  top: 20%;
  @media screen and (max-width: 768px) {
    width: 100vw;
    top: 0;
  }
`;
const Quote = styled.div`
  font-size: 2rem;
  color: #081542;
  text-align: left;
`;
const Content2 = styled.div`
  //margin-top: -25px;
  height: 100%;
  //background: #071023;
  //background: rgb(220, 220, 220);
  position: relative;
  color: #081542;
  padding: 25px;
  right: 0;
  //display: flex;
  //justify-content: center;
  // align-items: center;
  //flex-direction: column;
  margin-top: 25px;
  display: flex;
  gap: 20px;
  //margin-left: -50px;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    padding-top: 0px;
  }
`;
const Wrap = styled.div`
  border-left: 1px solid #081542;
  height: 200px;
  @media screen and (max-width: 768px) {
    height: 275px;
  }
  // width: 10px;
  //padding-left: 15px;
  //height: 20px;
`;
const Card = styled.div`
  min-width: 60vw;
  transition: ease;
  transition-duration: 500ms;
  @media screen and (max-width: 768px) {
    min-width: 100vw;
  }
`;
const Test = styled.div`
  color: #081542;
  font-weight: 300;
  font-family: 'Roboto Slab', serif;
`;
const Person = styled.div`
  color: #081542;
  font-weight: 200;
  margin-top: 25px;
`;
const CardWrap = styled.div`
  display: flex;
`;
const H2 = styled.h2`
  //color: #fff;
  font-family: 'Roboto Slab', serif;
  font-size: 24px;
  //background: #081542;
  font-weight: 400;
  margin-top: 10px;
  //padding: 10px;
  //margin-top: -25px;
`;
const Div = styled.div`
  font-weight: 300;
  margin-top: 25px;
`;
const Div2 = styled.div`
  font-weight: 300;
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  // justify-content: center;
`;
const Content = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
`;
const Img = styled.img`
  height: 150px;
  width: 150px;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    height: auto;
    width: 200px;
  }
`;
const HEAD = styled.div`
  color: #081542;
  font-size: 3rem;
  font-weight: 500;
`;
const SUB = styled.div``;

const TheGuide = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? 2 : (prev) => prev - 1);
  };
  const nextSlide = () => {
    setCurrentSlide(currentSlide === 2 ? 0 : (prev) => prev + 1);
  };
  const { ref: oceanRef, inView: oceanVisible } = useInView();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [yearsOfExperience, setYearsOfExperience] = useState(0);

  useEffect(() => {
    let timer;

    if (oceanVisible) {
      const targetExperience = 15; // Set your target number of years of experience

      timer = setInterval(() => {
        setYearsOfExperience((prevExperience) => {
          const increment = 1; // Set the increment value
          const nextExperience = prevExperience + increment;

          if (nextExperience >= targetExperience) {
            clearInterval(timer);
            return targetExperience;
          }

          return nextExperience;
        });
      }, 150); // Set the interval for the animation
    }

    return () => {
      clearInterval(timer);
    };
  }, [oceanVisible]);
  return (
    <Container ref={oceanRef}>
      <div className="w-full flex items-center justify-center ">
        <Wrapper>
          <One>
            <Content>
              <Img src={SQR} alt="" />
              <HEAD>{yearsOfExperience} +</HEAD>
              <SUB>Years of experience</SUB>
            </Content>
          </One>
          <Two>
            <Content2>
              <div>
                <Wrap
                  className={`${''} ${oceanVisible ? 'anim-line' : 'null'}`}
                />
              </div>
              <div>
                <H2>No one wants to be stuck in a project standstill</H2>
                <Div>
                  With over 15 years of proven expertsie, we ensure your
                  project's smooth journey.
                </Div>
                <Div2>
                  Explore recent projects we've completed for clients just like
                  you:
                </Div2>
                <Link to="/recent-projects">
                  <BtnWrap>
                    <Button buttonSty="btn--outline">
                      See Recent Projects
                    </Button>
                  </BtnWrap>
                </Link>
              </div>
            </Content2>
          </Two>
        </Wrapper>
      </div>
    </Container>
  );
};

export default TheGuide;
