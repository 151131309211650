import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '../About/About.css';
import Video from '../../images/van-sun-shot.MOV';
import BlogCard from './BlogCard';
import ImgK from '../../images/house.jpg';
import ImgR from '../../images/renovation-pic.jpg';
import ImgC from '../../images/cold.jpg';
import ImgL from '../../images/solar-house.jpg';
import ImgF from '../../images/foundation.jpg';
import ImgLead from '../../images/embracing.png';
import { Button } from '../Button';
import ImgI from '../../images/bath.jpg';
import ImgH from '../../images/hammer-jeans.jpg';
import './Blog.css';
const Container = styled.div`
  height: 60vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: left;
  position: relative;
  z-index: 50;
  color: #fff;
  /* @media screen and (max-width: 1300px) {
    background-size: 120%;
  }
  @media screen and (max-width: 1100px) {
    background-size: 140%;
  }
  @media screen and (max-width: 900px) {
    background-size: 160%;
  }
  @media screen and (max-width: 768px) {
    background-size: 180%;
  }
  @media screen and (max-width: 700px) {
    background-size: 200%;
  }
  @media screen and (max-width: 650px) {
    background: url('/images/hero-small.png');
    background-size: 100%;
  }
  @media screen and (max-width: 500px) {
    background-size: 160%;
  } */
  @media screen and (max-height: 550px) {
    height: 100vh;
  }
  @media screen and (max-width: 500px) {
    height: 60vh;
  }
`;
const Img = styled.div`
  height: 60vh;
  width: 100vw;
  background: url('/images/about-us.png') center;
  background-size: 100%;
  background: #070123;
  /* @media screen and (max-width: 1300px) {
    background-size: 120%;
  }
  @media screen and (max-width: 1100px) {
    background-size: 140%;
  }
  @media screen and (max-width: 900px) {
    background-size: 160%;
  }
  @media screen and (max-width: 768px) {
    background-size: 180%;
    transform: scale(1);
  }
  @media screen and (max-width: 700px) {
    background-size: 200%;
  }
  @media screen and (max-width: 650px) {
    //background: url('/images/hero-small.png');
    background-size: 100%;
  }
  @media screen and (max-width: 500px) {
    background-size: 160%;
  } */
  @media screen and (max-height: 550px) {
    height: 100vh;
  }
  @media screen and (max-width: 500px) {
    height: 60vh;
  }
`;
const Container2 = styled.div`
  height: 100vh;
  width: 100vw;
  display: none;
  justify-content: center;
  align-items: center;
  //background: url('/images/about-us-pic.jpg') center;
  background: #071023;
  background-size: 100%;
  color: #fff;
  @media screen and (max-width: 900px) {
    display: flex;
  }
`;
const Wrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  // border: 1px solid #000;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  /* max-width: 1000px; */
  width: 100vw;
  margin-left: 300px;
  //margin-top: 150px;
  /* position: relative;
  z-index: 47; */
  @media screen and (max-width: 768px) {
    margin-left: 50px;
  }
  @media screen and (max-height: 550px) {
    height: 100%;
  }
  @media screen and (max-width: 500px) {
    margin-left: 0px;
  }
`;
const Header = styled.h1`
  text-align: left;
  font-size: 3rem;
  font-family: 'Roboto Slab', serif;
  font-size: 3rem;
  font-weight: 400;
  margin-top: 100px;
  width: 800px;
  //margin-left: -200px;
  @media screen and (max-width: 1500px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 3.5rem;
    margin-top: 75px;
  }

  @media screen and (max-height: 550px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 3rem;
    margin-top: 125px;
  }
  @media screen and (max-width: 380px) {
    font-size: 2.5rem;
  }
`;
const SubHeader = styled.div`
  text-align: left;
  font-size: 1.2rem;
  margin-top: 25px;
  margin-bottom: 50px;
  width: 600px;
  //margin-left: -200px;

  @media screen and (max-width: 500px) {
    margin-bottom: 0px;
    margin-top: 20px;
    width: 85vw;
    font-size: 1rem;
  }
`;
const Chev = styled.div`
  height: 100px;
  @media screen and (max-height: 550px) {
    display: none;
  }
`;
const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const HeaderWrap = styled.div`
  //position: relative;
  max-height: 500px;
  overflow: hidden;
`;
const VideoBg = styled.video`
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  position: absolute;
  z-index: 0;
  //z-index: 0;
  top: 0;
  //z-index: 999;
`;
const FrontWrapWrap = styled.div`
  background: #fff;
  //height: 100vh;
  width: 100vw;
`;
const FrontWrap = styled.div`
  background: #fff;
  margin-left: 150px;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 700px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 500px) {
    margin-left: 25px;
  }
  //border: 1px solid #000;
`;
const Sticky = styled.div`
  position: fixed;
  z-index: 0;
  margin-left: 150px;
  left: 0;
  top: 0;
  height: 100vh;
  width: 350px;
  background: rgb(237, 237, 237);
  display: flex;
  align-items: center;
  justify-content: center;
  //opacity: 0;
  @media screen and (max-width: 1400px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 1300px) {
    margin-left: 25px;
  }
  @media screen and (max-width: 1250px) {
    display: none;
  }
  @media screen and (max-width: 500px) {
    margin-left: 0px;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
`;
const LeadGen = styled.div`
  background: #fff;
  //background: #071023;
  //height: 20vh;
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  margin-top: 75px;
  @media screen and (max-height: 650px) {
    margin-top: 125px;
  }
`;
const LeadImg = styled.img`
  height: 250px;
  @media screen and (max-height: 700px) {
    height: 200px;
  }
  @media screen and (max-height: 650px) {
    height: 225px;
  }
  @media screen and (max-height: 550px) {
    height: 200px;
  }
`;
const LeadImgWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
  align-items: center;
`;
const LeadDiv = styled.div`
  //color: #333;
  padding: 25px;
  font-weight: 500;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  background: #071023;
  @media screen and (max-height: 700px) {
    font-size: 1rem;
    padding: 5px;
  }
  @media screen and (max-height: 600px) {
    display: none;
  }
`;
const CTADiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const Div = styled.div`
  @media screen and (max-height: 650px) {
    font-size: 0.9rem;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
`;

const BlogContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  //align-items: center;
  //justify-content: center;
  background: #fff;
  margin-left: 350px;
  width: 825px;
  padding-top: 50px;
  height: 100%;
  @media screen and (max-width: 1400px) {
    margin-left: 300px;
  }
  @media screen and (max-width: 1300px) {
    margin-left: 225px;
  }
  @media screen and (max-width: 1250px) {
    margin-left: 0px;
    width: 100vw;

    height: 100%;
  }
  @media screen and (max-width: 500px) {
    margin-left: 0px;
  }
  @media screen and (max-height: 550px) {
    margin-left: 0px;
  }
`;
const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-left: 25px;
  //grid-row-gap: 0px;
  width: 100%;
  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
    margin-left: 0px;
    width: 70%;
  }
  @media screen and (max-width: 1100px) {
    width: 90%;
    grid-gap: 0px;
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;
const Wrap = styled.div`
  position: relative;
  z-index: 49;
  height: 500vh;
  width: 100vw;
`;
const Btn = styled.div`
  padding: 10px 20px;
  font-size: 16px;
  //min-width: 50px;
  max-width: 250px;
  transition: 300ms ease-in-out;
  background: #081542;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid #071023;
    cursor: pointer;
  }
`;
const Cont = styled.div`
  overflow: hidden;
`;

const FrontPage = () => {
  const [addClass, setAddClass] = useState(false);

  useEffect(() => {
    // After 3000 milliseconds (3 seconds), add the class
    const timer = setTimeout(() => {
      setAddClass(true);
    }, 400);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);
  const [addClass2, setAddClass2] = useState(false);

  useEffect(() => {
    // After 3000 milliseconds (3 seconds), add the class
    const timer = setTimeout(() => {
      setAddClass2(true);
    }, 700);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);
  return (
    <Cont>
      <Container>
        {/* <VideoBg
        autoPlay
        loop
        muted
        src={Video}
        type="video/mp4"
        data-aos="fade"
      /> */}
        <Img>
          <Wrapper>
            <Content>
              <HeaderWrap>
                <Header className={addClass ? 'ab-header' : 'opac-0'}>
                  The BRW Blog
                </Header>
              </HeaderWrap>
              <SubHeader className={addClass2 ? 'ab-sub' : 'opac-0'}>
                Scroll down to see our latest blog articles where we share our
                knowledge of the construction industry
              </SubHeader>
            </Content>
            <Chev>
              {' '}
              <i class="fa-solid fa-chevron-down chev oy"></i>
            </Chev>
          </Wrapper>
        </Img>
      </Container>
      <FrontWrapWrap>
        <FrontWrap>
          <Sticky>
            <LeadGen>
              <LeadDiv>Embrace Energy Efficiency</LeadDiv>
              <LeadImgWrap>
                <LeadImg src={ImgLead} alt="free pdf" />
              </LeadImgWrap>
              <CTADiv>
                <Div>TAKE CONTROL OF YOUR ENERGY BILLS:</Div>
                <Div>DOWNLOAD OUR FREE GUIDE NOW</Div>
                <a href="https://mailchi.mp/4cb5ae9e1389/embracing-energy-efficiecny">
                  <BtnWrap>
                    <Btn>DOWNLOAD THE GUIDE</Btn>
                  </BtnWrap>
                </a>
              </CTADiv>
            </LeadGen>
          </Sticky>
          <BlogContainer>
            <BlogWrapper>
              <BlogCard
                src={ImgR}
                light="HOME RENOVATION"
                header="10 Essential Tips for Planning Your Home Renovation"
                sub="Here are 10 tips to make the process smoother and more satisfying."
                link="/renovation-tips"
              />
              <BlogCard
                src={ImgL}
                light="ENERGY EFFICIENCY"
                header="The Latest Trends in Sustainable Construction"
                sub="Get ahead by staying up to date with the latest trends in sustainable construction"
                link="/latest-sustainable-construction-trends"
              />

              <BlogCard
                src={ImgF}
                light="FOUNDATION TYPES"
                header="Understanding Foundation Types: Which is Right for Your Building?"
                sub="Learn about different foundation types and determine which is right for your building"
                link="/understanding-foundation-types"
              />
              <BlogCard
                src={ImgH}
                light="RELIABLE BUILDERS"
                header="Choosing the Right Contractor: A Step-by-Step Guide"
                sub="We give you 8 steps to help you determine the contractor that's right for you"
                link="/choosing-the-right-contractor"
              />
              <BlogCard
                src={ImgK}
                link="/valuable-home-improvement-projects"
                light="HOME RENOVATIONS"
                header="Which Home Improvement Projects Add the Most Resale Value?"
                sub="Find out which home improvement projects appeal to you"
              />
              <BlogCard
                src={ImgI}
                light="INTERIOR DESIGN"
                header="The Impact of Interior Design on Your Well-Being"
                sub="Find out how interior design can influence your mental and emotional health"
                link="/impact-of-interior"
              />
              <BlogCard
                src={ImgC}
                light="ENERGY EFFICIENCY"
                header="Tips for Winterizing Your Home: Protecting Your Investment"
                sub="7 steps to help you prepare for the cold winters ahead."
                link="/tips-for-the-winter"
              />
            </BlogWrapper>
          </BlogContainer>
        </FrontWrap>
      </FrontWrapWrap>
    </Cont>
  );
};

export default FrontPage;
