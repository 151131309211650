import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import Cards from './components/Cards';
import Video from './components/Video';
import ScheduleCall from './components/pages/ScheduleCall';
import PdfPage from './components/pages/PdfPage';
import styled from 'styled-components';
import HomePage2 from './components/pages/HomePage2';
import OnLoad from './components/OnLoad/OnLoad';
import { useEffect, useState } from 'react';
import Dropdown from './components/Dropdown';
import About from './components/About/About';
import AboutPage from './components/pages/AboutPage';
import WorkPage from './components/pages/WorkPage';
import ScrollToTop from './components/ScrollToTop';
import Prac from './components/Projects/projects/Beeston';
import EnergyPage from './components/pages/EnergyPage';
import BlogPage from './components/pages/BlogPage';
import Renovation from './components/Blogs/Blogs/Renovation';
import Sustainable from './components/Blogs/Blogs/Sustainable';
import TestimonialsPage from './components/pages/TestimonialsPage';
import ContactPage from './components/pages/ContactPage';
import Foundation from './components/Blogs/Blogs/Foundation';
import PopupAd from './components/PopUp/PopUpAd';
import RightContractor from './components/Blogs/Blogs/RightContractor';
import CostVsValue from './components/Blogs/Blogs/CostVsValue';
import Interior from './components/Blogs/Blogs/Interior';
import WordAnimation from './components/Projects/projects/WordAnimation/WordAnimation';
import Bramcote from './components/Projects/projects/Bramcote';
import Wilford from './components/Projects/projects/Wilford';
import Edingley from './components/Projects/projects/Edingley';
import MB from './components/Projects/projects/MB';
import OckBrook from './components/Projects/projects/Ockbrook';
import Winterizing from './components/Blogs/Blogs/Winterizing';
import Higgy from './components/Projects/projects/Higgy';
import Edwalton from './components/Projects/projects/Edwalton';
import Woodborough from './components/Projects/projects/Woodborough';
import East from './components/Projects/projects/East';
import WoodBru from './components/Projects/projects/WoodBru';
import Mansion from './components/Projects/projects/Mansion';
const Full = styled.div`
  width: 100vw;
  overflow: hidden;
`;
const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <Full>
      {loading ? (
        <OnLoad />
      ) : (
        <Router>
          <Navbar toggle={toggle} isOpen={isOpen} />

          <Dropdown isOpen={isOpen} toggle={toggle} />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePage2 />}></Route>
            <Route path="/video" element={<Video />}></Route>
            <Route path="/contact" element={<ContactPage />}></Route>
            <Route path="/services" element={<PdfPage />}></Route>
            <Route path="/about-us" element={<AboutPage />}></Route>
            <Route path="/recent-projects" element={<WorkPage />}></Route>
            <Route path="/energy-efficiency" element={<EnergyPage />}></Route>
            <Route path="/testimonials" element={<TestimonialsPage />}></Route>
            <Route path="/blog" element={<BlogPage />}></Route>
            <Route path="/renovation-tips" element={<Renovation />}></Route>
            <Route
              path="/latest-sustainable-construction-trends"
              element={<Sustainable />}
            ></Route>
            <Route
              path="/understanding-foundation-types"
              element={<Foundation />}
            />
            <Route
              path="/valuable-home-improvement-projects"
              element={<CostVsValue />}
            />
            <Route
              path="/choosing-the-right-contractor"
              element={<RightContractor />}
            />
            <Route path="/tips-for-the-winter" element={<Winterizing />} />
            <Route path="/impact-of-interior" element={<Interior />} />
            <Route path="/beeston" element={<Prac />} />
            <Route path="/bramcote" element={<Bramcote />} />
            <Route path="/wilford" element={<Wilford />} />
            <Route path="/edingley" element={<Edingley />} />
            <Route path="/mb-centre" element={<MB />} />
            <Route path="/ockbrook" element={<OckBrook />} />
            <Route path="/lambley-lane" element={<Higgy />} />
            <Route path="/edwalton" element={<Edwalton />} />
            <Route path="/woodborough-renovation" element={<Woodborough />} />
            <Route path="/east-bridgeford" element={<East />} />
            <Route path="/woodborough" element={<WoodBru />} />
            <Route path="/burton-joyce" element={<Mansion />} />
          </Routes>
        </Router>
      )}
    </Full>
  );
};

export default App;
