import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../Button';
import './BlueOceanCTA.css';
const Container = styled.div`
  height: 65vh;
  padding-bottom: 75px;
  overflow-y: hidden;
  height: 100%;
  background: #fff;
  @media screen and (max-width: 1300px) {
    padding-bottom: 75px;
  }
  @media screen and (max-width: 1050px) {
    height: 100%;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 500px) {
    padding-bottom: 25px;
  }
  @media screen and (max-width: 380px) {
    padding-bottom: 0px;
  }

  /* @media screen and (max-width: 1050px) {
    height: 130vh;
  }
  @media screen and (max-width: 500px) {
    height: 90vh;
  }
  @media screen and (max-width: 400px) {
    height: 105vh;
  }
  @media screen and (max-width: 380px) {
    height: 120vh;
  } */
`;
const Wrapper = styled.div`
  display: grid;
  overflow-y: hidden;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr;
  }
`;
const Side1 = styled.div`
  position: relative;
  margin-top: 75px;
  overflow-y: hidden;
  height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 1300px) {
    margin-top: 75px;
  }
  @media screen and (max-width: 1050px) {
    height: 100%;
    margin-top: 50px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 25px;
  }
  // margin-left: 25px;
`;
const Side1Header = styled.h1`
  //font-family: 'Roboto Slab', serif;
  //color: #fff;
  font-weight: 400;
  height: 100%;

  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  /* text-align: left;
  margin-left: 100px; */
  @media screen and (max-width: 1200px) {
    //margin-left: -50px;
  }
  @media screen and (max-width: 1050px) {
    text-align: left;
  }
  @media screen and (max-width: 768px) {
    height: 50px;
  }
  @media screen and (max-width: 400px) {
    min-height: 50px;
  }
`;
const Side1Wrap = styled.div`
  //height: 100vh;
  // width: 50vw;
  height: 85px;
  // background: lightblue;
  padding: 20px;
  width: 100%;
  //margin-left: -50px;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1050px) {
    height: 85px;
  }
  /* @media screen and (max-width: 1400px) {
    margin-left: -50px;
  }
  @media screen and (max-width: 1300px) {
    margin-left: -75px;
  }
  @media screen and (max-width: 1200px) {
    height: 85px;
    //margin-left:
  }
  @media screen and (max-width: 1050px) {
    margin-left: 25px;
  }

  @media screen and (max-width: 500px) {
    margin-left: 75px;
    height: 55px;
  }
  @media screen and (max-width: 400px) {
    margin-left: 75px;
    height: 60px;
  } */
  @media screen and (max-width: 500px) {
    //margin-left: 75px;
    height: 60px;
  }
  @media screen and (max-width: 400px) {
    //margin-left: 75px;
    height: 60px;
  }
  @media screen and (max-width: 380px) {
    padding-bottom: 0px;
  }
`;
const Side2 = styled.div`
  width: 47vw;
  @media screen and (max-width: 1050px) {
    width: 90vw;
    margin: 25px;
    margin-top: 0px;
  }
`;
const Side2Header = styled.div`
  color: #081542;
  text-transform: uppercase;
  margin-top: 75px;
  font-weight: 500;
  @media screen and (max-width: 1050px) {
    margin-top: 50px;
  }
`;
const Side2P = styled.div`
  font-weight: 300;
  margin-top: 25px;
`;
const Side2PTag = styled.div`
  font-weight: 300;
  margin-top: 10px;
`;
const BtnWrap = styled.div`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  @media screen and (max-width: 1350px) {
    grid-template-columns: 1fr 1.2fr;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;
const BtnWrapWrap = styled.div`
  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TheStakes = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView();
  const { ref: oceanRef, inView: oceanVisible } = useInView();
  const { ref: stratRef, inView: strategyVisible } = useInView();
  const { ref: comesRef, inView: comesVisible } = useInView();
  const { ref: fromRef, inView: fromVisible } = useInView();
  const { ref: withinRef, inView: withinVisible } = useInView();
  return (
    <Container>
      <Wrapper>
        <Side1>
          <Side1Wrap>
            <Side1Header>
              <div ref={myRef}>
                <span
                  className={`${'side1header'} ${
                    myElementIsVisible ? 'animationHeader' : 'null'
                  }`}
                >
                  WOULD YOU TRUST
                </span>
              </div>
            </Side1Header>
          </Side1Wrap>
          <Side1Wrap>
            <Side1Header>
              <div ref={oceanRef}>
                <span
                  className={`${'side1header'} ${
                    oceanVisible ? 'animationHeader' : 'null'
                  }`}
                >
                  A STRANGER WITH
                </span>
              </div>
            </Side1Header>
          </Side1Wrap>
          <Side1Wrap>
            <Side1Header>
              <div ref={stratRef}>
                <span
                  className={`${'side1header'} ${
                    strategyVisible ? 'animationHeader' : 'null'
                  }`}
                >
                  YOUR HOME?
                </span>
              </div>
            </Side1Header>
          </Side1Wrap>
        </Side1>
        <Side2>
          <Side2Header>
            So why would you trust unproven builders with your home?
          </Side2Header>
          <Side2P>
            Finding builders who exude quality and experience can be a difficult
            task. Yet the real challenge appears when trying to find builders
            who you can consistently depend on.
          </Side2P>
          <Side2PTag>
            We understand the frustration that comes from delays, substandard
            work, and broken promises.
          </Side2PTag>
          <Side2PTag>
            That's why we showcase our impressive projects and client
            testimonials so that you know, when working with BRW, you are
            getting high-end, reliable construction.
          </Side2PTag>

          <BtnWrapWrap>
            <BtnWrap>
              <Link to="/recent-projects">
                <Button buttonSize="btn--large" className="btn">
                  Recent Projects
                </Button>
              </Link>
            </BtnWrap>
          </BtnWrapWrap>
        </Side2>
      </Wrapper>
    </Container>
  );
};

export default TheStakes;
