import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import Img1 from '../../images/lighting.jpg';

import Img2 from '../../images/phone.jpg';
import Img3 from '../../images/projects.jpg';

const Container = styled.div`
  display: none;
  height: 210vh;
  background-color: #fff;
  @media screen and (max-width: 768px) {
    display: flex;
    color: #fff;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const One = styled.div`
  //height: 20px;
  height: 100vh;
  background-color: #081542;
  //padding: 100px;#
  @media screen and (max-width: 768px) {
    height: 70vh;
    // background-color: #fff;
  }
`;
const Two = styled.div`
  //height: 20px;
  background-color: #6e8bbd;
  @media screen and (max-width: 768px) {
    height: 70vh;
  }
`;
const Three = styled.div`
  // height: 20px;#6a7b1e#dc720b#eeba22
  background-color: #fe681c;
  @media screen and (max-width: 768px) {
    height: 70vh;
  }
`;
const Img = styled.img`
  object-fit: cover;
  height: 55vh;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: 30vh;
    // display: none;
  }
`;
const Header = styled.h2`
  text-align: center;
  margin-top: 25px;
`;
const Para = styled.div`
  text-align: center;
  margin-top: 10px;
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;
const Triangle2 = styled.div`
  border-bottom: 100px solid transparent;
  border-right: 50vw solid #fff;
  border-left: 50vw solid #fff;
  display: none;
  @media screen and (max-width: 768px) {
    //height: 30vh;
    //display: none;
    position: absolute;
    top: 0%;
    display: flex;
    // display: none;
    border-bottom: 30px solid transparent;
    //display: none;

    //bottom: 90%;

    //margin-top: 20px;
  }
`;

const ValueSection2 = () => {
  return (
    <Container>
      <Wrapper>
        <Content>
          <One>
            <Triangle2 />
            <Img src={Img1} alt="" />

            <Header>Energy Efficiency</Header>
            <Para>
              Our innovative design process is aligned with the builders to
              carry the project out. This ensures the process runs smoothly and
              the construction timeframe is optimised.
            </Para>
            <BtnWrap>
              <Button buttonSize="btn--large" buttonStyle="btn--outline">
                The Design Process
              </Button>
            </BtnWrap>
          </One>
          <Two>
            <Img src={Img3} alt="" />
            <Header>Latest Projects</Header>
            <Para>
              We carry out construction work to the highest quality which is
              optimised for energy efficiency and comfort living. We aim to make
              our customers proud of their projects.
            </Para>
            <BtnWrap>
              <Button buttonSize="btn--large" buttonStyle="btn--outline">
                Latest Projects
              </Button>
            </BtnWrap>
          </Two>
          <Three>
            <Img src={Img2} alt="" />
            <Header>How to get started</Header>
            <Para>
              Contact us through the details on the contact page so we can
              schedule a non comitment visit to discuss plans for your project
              and arrange a start date. Finally, enjoy watching your plans turn
              into reality.
            </Para>
            <BtnWrap>
              <Button buttonSize="btn--large" buttonStyle="btn--outline">
                Contact Us
              </Button>
            </BtnWrap>
          </Three>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default ValueSection2;
