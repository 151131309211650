import React from 'react';
import styled from 'styled-components';
import './Contact.css';
import Contact2 from '.././Contact';
import LeadGenerator from '../LeadGen/LeadGenerator';
import ContactLeadGen from '../LeadGen/ContactLeadGen';

const Container = styled.div`
  width: 100vw;
  //height: 100vh;

  display: flex;
  justify-content: center;
`;
const WrapperWrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 125px;
  background: rgb(237, 237, 237);
  padding-bottom: 100px;
  background: rgb(237, 237, 237);
`;
const SubHeader = styled.div`
  text-transform: uppercase;
  color: #333;
  margin-top: 50px;
  text-align: center;
`;
const Header = styled.div`
  color: #081542;
  text-align: center;
  font-size: 2rem;
`;
const Wrap = styled.div`
  width: 80vw;
  border-top: 1px solid #081542;
  margin-top: 25px;
  height: 100%;
`;
const Wrapper = styled.div`
  display: grid;
  //background-color: blue;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-top: 15px;
  text-align: center;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 50px;
    padding-bottom: 125px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-top: 50px;
  }
  @media screen and (max-width: 500px) {
    padding-top: 10px;
  }
`;
const CallUs = styled.div`
  font-size: 1.5rem;
  padding-bottom: 20px;
`;
const Social = styled.div`
  font-size: 1.5rem;
`;
const Text = styled.div`
  font-size: 1.5rem;
`;
const Phone = styled.div``;
const Media = styled.div`
  display: flex;
  justify-content: center;
`;
const Media1 = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;
const WhatsApp = styled.div`
  display: flex;
  justify-content: center;
`;
const Number = styled.div`
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  outline: none;
  border: 1px solid rgb(9, 9, 98);
  cursor: pointer;
  display: flex;
  min-width: 225px;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.2rem;
  transition: all 0.2 ease-in-out;
  &:hover {
    transition: all 0.2 ease-in-out;
    background: #fff;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 5%;
  }
`;
const Icons = styled.div`
  max-width: 200px;
  ///height: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: flex;
  @media screen and (max-width: 768px) {
    margin-bottom: 5%;
  }
`;
const WIcon = styled.div`
  background: rgb(37 211 102);
  text-decoration: none;
  //height: 100px;
  color: white;
  padding: 15px;
  margin-top: 15px;
  max-width: 250px;
  border-radius: 5%;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;
const WhatsApp1 = styled.div`
  z-index: 1;
`;
const WhatWrap = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 3fr; */
  display: flex;
  gap: 7.5px;
`;
const WhatP = styled.div`
  font-size: 1.2rem;
  font-family: 'Poppins';
  text-decoration: none;
  margin-top: -2.5px;
  // height: 10px;
`;
const F = styled.span`
  color: #fff;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;
const In = styled.span`
  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;
const Tw = styled.span`
  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;
const Yo = styled.span`
  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;
const NumWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;
const Email = styled.div`
  @media screen and (max-width: 1200px) {
    position: absolute;
    //margin-bottom: 75px;
    left: 50%;
    transform: translateX(-50%);
    /* width: 100%;
    display: flex;
    justify-content: center;

    flex-direction: column; */
  }
  @media screen and (max-width: 768px) {
    //position: static;
    margin-top: 25px;
  }
`;
const A = styled.div`
  margin-top: 15px;
  //height: 100%;
  //color: blue;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  @media screen and (max-width: 1300px) {
    margin-left: 10px;
  }
`;
const SpanI = styled.div`
  font-size: 2.5rem;
  color: rgb(178, 12, 78);
  color: #081542;
  margin-right: 17.5px;

  // margin-top: -17.5px;
  @media screen and (max-width: 1300px) {
    margin-right: 10px;
  }
`;
const Span = styled.div`
  color: rgb(10, 50, 152);
  //color: #081542;
  transition: ease 300ms;
  &:hover {
    color: orangered;
  }
  //margin-top: -10px;
`;
const EDiv = styled.div`
  /* @media screen and (max-width: 1200px) {
    width: 100vw;
  } */
`;
const PDFWrap = styled.div`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 1250px) {
    grid-template-columns: 1.5fr 1fr;
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;
const ContactWrap = styled.div`
  margin-left: 10vw;
`;
const MapWrap = styled.div`
  margin-top: 4rem;
`;
const Contact = () => {
  return (
    <>
      <Container>
        <WrapperWrap>
          <SubHeader>GET IN TOUCH</SubHeader>
          <Header>LET'S MAKE IT HAPPEN</Header>
          <Wrap>
            <Wrapper>
              <Phone>
                <CallUs>Call us:</CallUs>
                <a href="tel: 07975 830 631">
                  <NumWrap>
                    <Number>
                      <i class="underline fa-solid fa-phone "></i>
                      <span className="num underline"> 07975 830 631</span>
                    </Number>
                  </NumWrap>
                </a>
                <a href="tel: 0115 931 2339">
                  <NumWrap>
                    <Number>
                      <i class="underline fa-solid fa-phone "></i>
                      <span className="num underline"> 0115 931 2339</span>
                    </Number>
                  </NumWrap>
                </a>
              </Phone>
              <Media1>
                <Social>Social media:</Social>
                <Media>
                  <Icons>
                    <In>
                      <a
                        href="https://www.instagram.com/brwconstruction_uk/"
                        class="fa fa-instagram instagram"
                      ></a>
                    </In>

                    <Yo>
                      <a
                        href="https://www.youtube.com/watch?v=QV8-ndOKSa8"
                        class="fa fa-youtube youtube"
                      ></a>
                    </Yo>
                  </Icons>
                </Media>
              </Media1>
              <WhatsApp1>
                <Text>Text us:</Text>
                <WhatsApp>
                  <WIcon>
                    <a href="https://wa.me/+447975830631/" alt="Whatsapp Link">
                      <WhatWrap>
                        <div>
                          <i className="fa fa-whatsapp"></i>
                        </div>
                        <WhatP> WhatsApp Us</WhatP>
                      </WhatWrap>
                    </a>
                  </WIcon>
                </WhatsApp>
              </WhatsApp1>
              <EDiv>
                <Email>
                  <Text>Send us an email:</Text>

                  <A>
                    <SpanI>
                      <i class="fa-regular fa-envelope-open oy"></i>
                    </SpanI>
                    <Span>
                      <a href="mailto:benwhite658@gmail.com">
                        benwhite658@gmail.com
                      </a>
                    </Span>
                  </A>
                </Email>
              </EDiv>
            </Wrapper>
          </Wrap>
          <PDFWrap>
            <ContactWrap>
              <Contact2 />
            </ContactWrap>
            <MapWrap>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2401.945339692229!2d-1.040979624045282!3d52.985384572186824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879c65adb8db583%3A0x60ce47279bda2640!2s21%20St%20Helen&#39;s%20Cres%2C%20Burton%20Joyce%2C%20Nottingham%20NG14%205DW!5e0!3m2!1sen!2suk!4v1705500950452!5m2!1sen!2suk"
                width="100%"
                height="500"
                //style="border:0;"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <iframe
                width="100%"
                height="500"
                id="gmap_canvas"
                //width="600"
                //height="450"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                //src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2401.945339692232!2d-1.0409796241439235!3d52.98538457218675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879c65adb8db583%3A0x60ce47279bda2640!2s21%20St%20Helen&#39;s%20Cres%2C%20Burton%20Joyce%2C%20Nottingham%20NG14%205DW!5e0!3m2!1sen!2suk!4v1704821337465!5m2!1sen!2suk"
                src="https://maps.google.com/maps?q=21%20St%20Helens%20Crescent&t=&z=11&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe> */}
            </MapWrap>
          </PDFWrap>
        </WrapperWrap>
      </Container>
    </>
  );
};

export default Contact;
