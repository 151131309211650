import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { menuData } from './data/MenuData';
import { Button } from './Button';
import './Dropdown.css';

const DropdownContainer = styled.div`
  //margin-top: 235px;
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  //background: rgb(237, 237, 237);
  //background: #fff;
  //background: rgba(180, 134, 152, 0.9);
  //background: #000;
  display: grid;
  //align-items: center;
  top: 0;
  left: 0;

  transition-delay: ${({ isOpen }) => (isOpen ? '0s' : '1s')};
  /* opacity: ${({ isOpen }) => (isOpen ? '1' : '0')}; */
  //display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};
  /* top: ${({ isOpen }) => (isOpen ? '0' : '-150%')}; */
  z-index: ${({ isOpen }) => (isOpen ? '99' : '0')};
  @media screen and (max-width: 500px) {
    //height: 55vh;
  }
  @media screen and (max-width: 380px) {
    // height: 60vh;
  }
  @media screen and (max-width: 768px) {
    //margin-top: 195px;
  }
  @media screen and (max-width: 600px) {
    //margin-top: 145px;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  left: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

const CloseIcon = styled(FaTimes)`
  color: rgb(178, 12, 78);
`;

const DropdownWrapper = styled.div`
  //display: flex;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 2.5fr 1fr;
  }
`;

const DropdownMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 60px);
  text-align: left;
  // margin-bottom: 2rem;
  //display: flex;
  //position: relative;
  // left: 35vw;
  //left: 50%;
  @media screen and (max-width: 1500px) {
    grid-template-rows: repeat(6, 50px);
    //margin-bottom: 4rem;
  }
  @media screen and (max-width: 500px) {
    grid-template-rows: repeat(6, 60px);
  }
  @media screen and (max-width: 380px) {
    grid-template-rows: repeat(6, 60px);
    margin-bottom: 5rem;
  }
  @media screen and (max-height: 650px) {
    margin-top: 200px;
  }
`;

const DropdownLink = styled(Link)`
  ///background-color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: left;
  text-decoration: none;
  color: #000;
  margin-left: 10px;
  font-size: 1.2rem;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  width: 100vw;
  align-self: center;
  //padding-bottom: 10px;
  //text-align: center;
  transition: 0.2s ease-in-out;

  &:hover {
    /* transform: translateX(20px); */
    padding-left: 20px;
  }
  @media screen and (max-width: 1500px) {
    font-size: 1.1rem;
    height: 30px;
  }
  @media screen and (max-width: 768px) {
    //margin-right: 50px;
    //font-size: 1rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.2rem;
    height: 50px;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  color: #000;
  margin-bottom: 25px;
  @media screen and (max-width: 600px) {
    //margin-right: 50px;
  }
`;
const NavBtnLink = styled(Link)`
  border-radius: 50px;
  background: rgb(178, 12, 78);
  white-space: nowrap;
  padding: 10px 22px;
  margin-left: -20%;
  margin-top: 35px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transform: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2 ease-in-out;
    background: #fff;
  }
`;
const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Wrap = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  @media screen and (max-width: 1500px) {
    //align-items: flex-start;
    margin-top: -50px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 0;
  }
  //justify-content: center;
`;
const One = styled.div`
  display: flex;
  align-items: flex-start;
  //border-top: 30px solid #000;
  background: #fff;
  height: 100%;
  width: 100%;
  transition: 1s ease;
  transition: 01s ease-in-out;
  margin-left: ${({ isOpen }) => (isOpen ? '-10px' : '0px;')};
`;
const Two = styled.div`
  /* position: relative;
  left: 0; */
  background-color: #081542;
  background-color: rgba(8, 21, 66, 0.8);
  height: 100%;
  width: 100%;
  transition: 01s ease-in-out;
`;
const Div = styled.div``;

const Dropdown = ({ isOpen, toggle }) => {
  return (
    <DropdownContainer isOpen={isOpen} onClick={toggle}>
      <DropdownWrapper>
        <One className={`${isOpen ? 'one' : 'one-gone'}`}>
          <Wrap>
            <DropdownMenu>
              {menuData.map((item, index) => (
                <DropdownLink to={item.link} key={index}>
                  <Div>{item.title}</Div>
                </DropdownLink>
              ))}
            </DropdownMenu>
          </Wrap>
          {/* <BtnWrap>
            <Link to="/contact">
              <Button primary="true" dark="true" to="/contact">
                Contact us
              </Button>
            </Link>
          </BtnWrap> */}
        </One>
        <Two className={`${isOpen ? 'two' : 'two-gone'}`}></Two>
      </DropdownWrapper>
    </DropdownContainer>
  );
};

export default Dropdown;
