import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CTA from '../../CTA/CTA';
import ImgR from '../../../images/cold.jpg';
import Footer2 from '../../Footer/Footer2';
import ImgLead from '../../../images/embracing.png';
const Container = styled.div`
  width: 100vw;
  height: 100%;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  background-color: rgb(202, 210, 255);
  background-color: rgb(230, 230, 230);
  position: relative;
  z-index: 50;
`;
const Wrapper = styled.div`
  margin-left: 200px;
  width: 800px;
  @media screen and (max-width: 1400px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 900px) {
    width: 80vw;
    margin-left: 50px;
  }
  @media screen and (max-width: 400px) {
    margin-left: 25px;
  }
  //height: 150vh;
`;
const Header = styled.div`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 25px;
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 2rem;
  }
`;
const Para = styled.div`
  color: #333;
  font-weight: 300;
  margin-top: 25px;
  margin-bottom: 25px;
`;
const SubHeader = styled.div`
  font-weight: 500;
  font-size: 2rem;
`;
const Sticky = styled.div`
  position: fixed;
  z-index: 0;
  margin-right: 75px;
  right: 0;
  top: 0;
  height: 100vh;
  width: 350px;
  background: rgb(237, 237, 237);
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1500px) {
    margin-right: 25px;
  }
  @media screen and (max-width: 1300px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 1275px) {
    display: none;
  }
`;
const Span = styled.span`
  color: #000;
  text-decoration: underline;
  transition: 300ms ease;
  &:hover {
    cursor: pointer;
    color: blue;
  }
`;
const Img = styled.img`
  height: 250px;
  width: 500px;
  object-fit: cover;
`;

//
const LeadGen = styled.div`
  background: #fff;
  //background: #071023;
  //height: 20vh;
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  margin-top: 75px;
  @media screen and (max-height: 650px) {
    margin-top: 125px;
  }
`;
const LeadImg = styled.img`
  height: 250px;
  @media screen and (max-height: 700px) {
    height: 200px;
  }
  @media screen and (max-height: 650px) {
    height: 225px;
  }
  @media screen and (max-height: 550px) {
    height: 200px;
  }
`;
const LeadImgWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
  align-items: center;
`;
const LeadDiv = styled.div`
  //color: #333;
  padding: 25px;
  font-weight: 500;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  background: #071023;
  @media screen and (max-height: 700px) {
    font-size: 1rem;
    padding: 5px;
  }
  @media screen and (max-height: 600px) {
    display: none;
  }
`;
const CTADiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const Div = styled.div`
  @media screen and (max-height: 650px) {
    font-size: 0.9rem;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
`;
const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-left: 25px;
  grid-row-gap: 0px;
`;
const Wrap = styled.div`
  position: relative;
  z-index: 49;
  height: 500vh;
  width: 100vw;
`;
const Btn = styled.div`
  padding: 10px 20px;
  font-size: 16px;
  //min-width: 50px;
  max-width: 250px;
  transition: 300ms ease-in-out;
  background: #081542;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid #071023;
    cursor: pointer;
  }
`;
// const CTADiv = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   text-align: center;
// `;
// const Div = styled.div``;

const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Winterizing = () => {
  return (
    <>
      <Container>
        <Sticky>
          <LeadGen>
            <LeadDiv>Embracing Energy Efficiency</LeadDiv>
            <LeadImgWrap>
              <LeadImg src={ImgLead} alt="free pdf" />
            </LeadImgWrap>
            <CTADiv>
              <Div>TAKE CONTROL OF YOUR ENERGY BILLS:</Div>
              <Div>DOWNLOAD OUR FREE GUIDE NOW</Div>
              <a href="https://mailchi.mp/4cb5ae9e1389/embracing-energy-efficiecny">
                <BtnWrap>
                  <Btn>DOWNLOAD THE GUIDE</Btn>
                </BtnWrap>
              </a>
            </CTADiv>
          </LeadGen>
        </Sticky>
        <Wrapper>
          <Header>
            Tips for Winterizing Your Home: Protecting Your Investment
          </Header>
          <Img src={ImgR} alt="" />
          <Para>
            As the colder months approach, it's essential to prepare your home
            for the challenges that winter can bring. Winterizing your home not
            only ensures your family's comfort but also safeguards your valuable
            investment. In this blog post, we'll share some practical tips on
            how to winterize your home effectively.
          </Para>
          <SubHeader>1. Seal Air Leaks</SubHeader>
          <Para>
            Air leaks around windows, doors, and other openings can lead to heat
            loss and drafts. Inspect for gaps and cracks, and use caulk or
            weatherstripping to seal them. Consider adding door sweeps to
            prevent cold air from creeping in under exterior doors.
          </Para>
          <SubHeader>2. Insulate Attic and Walls</SubHeader>
          <Para>
            Proper insulation is key to retaining heat in your home. Check your
            attic insulation and add more if needed. Additionally, ensure that
            walls and crawl spaces are adequately insulated to prevent cold
            spots.
          </Para>
          <SubHeader>3. Service Your Heating System</SubHeader>
          <Para>
            Before winter arrives, have your heating system professionally
            serviced. A well-maintained furnace or heat pump will operate
            efficiently, keeping your home warm and saving energy. Replace air
            filters regularly throughout the season.
          </Para>
          <SubHeader>4. Protect Pipes</SubHeader>
          <Para>
            Frozen pipes can lead to costly damage. Insulate exposed pipes in
            unheated areas, and disconnect garden hoses. On extremely cold
            nights, allow faucets to drip slightly to prevent freezing.
          </Para>
          <SubHeader>5. Chimney Inspection and Cleaning</SubHeader>
          <Para>
            If you have a fireplace or wood-burning stove, have your chimney
            inspected and cleaned. Ensure the damper closes tightly when not in
            use to prevent warm air from escaping.
          </Para>
          <SubHeader>6. Check Roof and Gutters</SubHeader>
          <Para>
            Inspect your roof for damaged or missing shingles and repair as
            needed. Clean gutters to prevent ice dams from forming, which can
            lead to water damage.
          </Para>
          <SubHeader>7. Install Storm Windows and Doors</SubHeader>
          <Para>
            Consider adding storm windows and doors to improve energy
            efficiency. These additions provide an extra layer of insulation and
            protection against the elements.
          </Para>

          <Para>
            By taking these proactive steps to winterize your home, you can
            protect your investment, maintain a comfortable living environment,
            and reduce energy costs during the colder months. If you require
            assistance with any winterization projects or home improvements,
            don't hesitate to reach out to our experienced team at{' '}
            <Span>
              <Link to="/contact">BRW Construction</Link>
            </Span>
            . We're here to help you keep your home in top shape, no matter the
            season. Stay warm and safe this winter!
          </Para>
        </Wrapper>
      </Container>
    </>
  );
};

export default Winterizing;
