import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './Benefits.css';

const ImgWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  //position: absolute;
  position: relative; /* Required for positioning the pseudo-element */
  display: inline-block;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the color and opacity as needed */
    opacity: 0; /* Initially transparent */
    transition: opacity 0.3s ease-in-out; /* Add a smooth transition effect */
  }
  &:hover::before {
    opacity: 1; /* Show the background color on hover */
  }

  /* Adjust image appearance if needed */

  &:hover {
    cursor: pointer;
  }
  &:hover {
    transform: scale(1.1);
  }
  transition-duration: 500ms;
`;
const Img = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  margin-left: -2px;
  transition-duration: 500ms;
  display: block;
  max-width: 100%;
  //height: auto;
  // overflow: hidden;
  &:hover {
    transform: scale(1.2);
  }
`;

const ImgText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%; /* Adjust the vertical position of the text */
  left: 50%; /* Adjust the horizontal position of the text */
  text-align: center;
  transform: translate(-50%, -50%);
  background: rgba(
    0,
    0,
    0,
    0.3
  ); /* Center the text both horizontally and vertically */
  //background-color: rgba(0, 0, 0, 0.7); /* Background color for the text */
  color: white; /* Text color */

  padding: 10px; /* Adjust padding as needed */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
`;
const ImgWrapWrap = styled.div`
  height: 350px;
  width: 400px;
  overflow: hidden;
  @media screen and (max-width: 500px) {
    height: 200px;
  }
`;
const Place = styled.div`
  font-weight: 300;
  font-size: 1.2rem;
`;
const Br = styled.div`
  margin-top: 10px;
  font-size: 1rem;
`;

const ProjectCard = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  console.log(isHovered);
  return (
    <>
      <Link to={props.link}>
        <ImgWrapWrap>
          <ImgWrap
            id="card"
            className="image-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Img src={props.src} alt="" className="img-fuz" loading="lazy" />
            <ImgText
              className={`${
                isHovered
                  ? 'image-text img-txt-hov'
                  : 'image-text img-txt-hov-no'
              }`}
            >
              <Place
                className={`${
                  isHovered
                    ? 'image-text img-txt-hov'
                    : 'image-text img-txt-hov-no'
                }`}
              >
                {props.header}

                <br />
                <Br
                  className={`${
                    isHovered
                      ? 'image-text img-txt-hov2'
                      : 'image-text img-txt-hov-no2'
                  }`}
                >
                  - click here -
                </Br>
              </Place>
            </ImgText>
          </ImgWrap>
        </ImgWrapWrap>
      </Link>
    </>
  );
};

export default ProjectCard;
