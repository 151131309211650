import React from 'react';
import House1 from '../../images/phone.jpg';
import House2 from '../../images/prac-plan.jpg';
import House3 from '../../images/photo-1.jpg';
import House4 from '../../images/about-us-pic.jpg';
import House5 from '../../images/footer-pic.jpg';
import House6 from '../../images/blueHouse.jpg';
import House7 from '../../images/lighting.jpg';
import styled from 'styled-components';

const Img = styled.img`
  height: 200px;
  width: 200px;
  object-fit: cover;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 200vh;
  background: #fff;
`;

const ProjectCard2 = () => {
  return (
    <Grid>
      <Img src={House1} alt="" />
      <Img src={House2} alt="" />
      <Img src={House3} alt="" />
      <Img src={House4} alt="" />
      <Img src={House5} alt="" />
      <Img src={House6} alt="" />
      <Img src={House7} alt="" />
    </Grid>
  );
};

export default ProjectCard2;
