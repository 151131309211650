import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Img1 from '../../images/Logo.JPG';

const Container = styled.div`
  background: url('/footer-pic.jpg') center;
  background-color: rgba(9, 12, 98, 0.5);
  background-color: rgba(7, 16, 35, 0.9);
  height: 30vh;
  width: 100vw;
  object-fit: cover;
  z-index: 0;
  position: fixed;
  bottom: 0;
  @media screen and (max-width: 1000px) {
    height: 45vh;
  }
`;
const Socials = styled.div`
  /* position: absolute;
  left: 42.5%; */
  position: absolute;
  right: 10%;
  @media screen and (max-width: 1000px) {
    //left: 21.5%;
  }
  @media screen and (max-width: 1000px) {
    position: static;
    margin-top: 45px;
  }
`;
const Links = styled.div`
  display: flex;
  gap: 50px;
  @media screen and (max-width: 1000px) {
    // margin-left: 15px;
    // margin-right: 25px;
    gap: 30px;

    // margin-top: 75px;
  }
`;
const Cont1 = styled.div`
  height: 30vh;
  width: 100vw;
  //position: relative;
  bottom: 0;
  z-index: 3;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: rgba(9, 12, 98, 0.9);
  background-color: rgba(8, 21, 40, 0.95);
  background-color: rgba(7, 16, 35, 0.97);
  @media screen and (max-width: 1000px) {
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 380px) {
    height: 45vh;
  }
`;
const Wrapper = styled.div``;
const Logo = styled.img`
  margin: 32px;
  height: 85px;
  //margin-top: 75px;
  object-fit: cover;
  position: relative;
  left: 0;
  margin-left: 75px;
  margin-right: 100px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
const Content = styled.div`
  color: #fff;
`;
const One = styled.div`
  width: 100%;
  height: 90%;
  margin-top: 1.3vh;
  display: flex;
  //justify-content: center;
  align-items: center;
  //flex-direction: column;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    margin-top: 2vh;
    height: 80%;
    //height: 100%;
    justify-content: center;
  }
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 300;
  height: 100%;
  @media screen and (max-width: 1000px) {
    gap: 5px;
  }
`;
const Div = styled.div`
  color: rgb(190, 190, 190);
  transition: 300ms ease;
  &:hover {
    color: #fff;
    cursor: pointer;
  }
`;

const Footer2 = () => {
  return (
    <>
      <Container>
        <Cont1>
          <One>
            <Logo src={Img1}></Logo>
            <Links>
              <Col>
                <Link to="/about-us">
                  <Div>About</Div>
                </Link>{' '}
                <Link to="/recent-projects">
                  <Div>Recent Projects</Div>
                </Link>{' '}
                <Link to="/energy-efficiency">
                  <Div>Energy Efficiency</Div>
                </Link>
              </Col>
              <Col>
                <Link to="/blog">
                  <Div>Blog</Div>
                </Link>
                <Link to="/contact">
                  <Div>Contact Us</Div>
                </Link>
              </Col>
            </Links>
            <Socials>
              <div className="flex gap-[25px]">
                <div>
                  <a href="https://www.instagram.com/brwconstruction_uk/">
                    <i className="fa fa-instagram social-icon-link "></i>
                  </a>
                </div>
                <div>
                  <a href="https://www.youtube.com/@BRW_TV">
                    <i className="fa fa-youtube social-icon-link"></i>
                  </a>
                </div>
              </div>
            </Socials>
          </One>
        </Cont1>
      </Container>
      <Wrapper></Wrapper>
    </>
  );
};

export default Footer2;
