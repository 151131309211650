import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import './Clean.css';

const Container = styled.div`
  height: 60vh;
  //width: 100vw;
  /* display: flex;
  justify-content: center;
  align-items: left; */
  //background: gray;
  color: #fff;
  @media screen and (max-width: 1300px) {
    background-size: 120%;
  }
  @media screen and (max-width: 1100px) {
    background-size: 140%;
  }
  @media screen and (max-width: 900px) {
    background-size: 160%;
  }
  @media screen and (max-width: 768px) {
    background-size: 180%;
  }
  @media screen and (max-width: 700px) {
    background-size: 200%;
  }
  @media screen and (max-width: 650px) {
    //background: url('/images/hero-small.png');
    background-size: 100%;
  }
  @media screen and (max-width: 500px) {
    background-size: 160%;
    height: 60vh;
  }
`;
const Img = styled.div`
  height: 100%;
  width: 100vw;
  background: url('/images/higgy-house.png') center;
  background-size: 100%;
  color: #fff;

  @media screen and (max-width: 1300px) {
    background-size: 120%;
  }
  @media screen and (max-width: 1100px) {
    background-size: 140%;
  }
  @media screen and (max-width: 900px) {
    background-size: 160%;
  }
  @media screen and (max-width: 768px) {
    background-size: 180%;
    transform: scale(1);
  }
  @media screen and (max-width: 700px) {
    background-size: 200%;
  }
  @media screen and (max-width: 650px) {
    // background: url('/images/hero-small.png');
    background-size: 180%;
  }
  @media screen and (max-width: 500px) {
    background-size: 180%;
  }
  @media screen and (max-width: 500px) {
    background-size: 240%;
    height: 60vh;
  }
`;
const Container2 = styled.div`
  height: 100vh;
  width: 100vw;
  display: none;
  justify-content: center;
  align-items: center;
  background: url('/images/about-us-pic.jpg') center;
  background-size: 100%;
  color: #fff;
  @media screen and (max-width: 900px) {
    display: flex;
  }
`;
const Wrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  // border: 1px solid #000;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  /* max-width: 1000px; */
  width: 100%;
  margin-left: 300px;
  //margin-top: 150px;
  @media screen and (max-width: 768px) {
    margin-left: 50px;
  }
  @media screen and (max-width: 500px) {
    margin-left: 0px;
  }
`;
const Header = styled.h1`
  text-align: left;
  //font-size: 10rem;
  font-family: 'Roboto Slab', serif;
  font-size: 3.5rem;
  font-weight: 400;
  margin-top: 150px;
  width: 800px;
  //margin-left: -200px;
  @media screen and (max-width: 1500px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 3.5rem;
    margin-top: 75px;
  }
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
`;
const SubHeader = styled.div`
  text-align: left;
  font-size: 1rem;
  margin-top: 25px;
  margin-bottom: 50px;
  //margin-left: -200px;
  @media screen and (max-width: 500px) {
    margin-bottom: 0px;
    margin-top: 20px;
  }
`;
const Chev = styled.div`
  height: 100px;
`;
const BtnWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  //margin-bottom: 125px;
  @media screen and (max-width: 650px) {
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }
  @media screen and (max-width: 500px) {
    grid-gap: 5px;
    grid-template-columns: 1fr;
    margin-top: 50px;
  }
`;
const HeaderWrap = styled.div`
  //position: relative;
  max-height: 500px;
  overflow: hidden;
`;

const RecentProjects = () => {
  const [addClass, setAddClass] = useState(false);

  useEffect(() => {
    // After 3000 milliseconds (3 seconds), add the class
    const timer = setTimeout(() => {
      setAddClass(true);
    }, 400);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);
  const [addClass2, setAddClass2] = useState(false);

  useEffect(() => {
    // After 3000 milliseconds (3 seconds), add the class
    const timer = setTimeout(() => {
      setAddClass2(true);
    }, 700);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);
  return (
    <Container>
      <Img>
        <Wrapper>
          <Content>
            <HeaderWrap>
              <Header className={addClass ? 'ab-header' : 'opac-0'}>
                Recent Projects
              </Header>
            </HeaderWrap>
            <SubHeader className={addClass2 ? 'ab-sub' : 'opac-0'}>
              Scroll down to explore all of our recent projects
            </SubHeader>
          </Content>
          <Chev>
            {' '}
            <i class="fa-solid fa-chevron-down chev oy"></i>
          </Chev>
        </Wrapper>
      </Img>
    </Container>
  );
};

export default RecentProjects;
