import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Button.css';
import './Navbar.css';
import ImgLogo from '../images/BRW-logo.webp';
import MenuIcon from './MenuIcon';
import styled from 'styled-components';
import './MenuIcon.css';

const MenuIconWrap = styled.div`
  //height: 200px;
  /* width: 100%;
  height: 100%; */
  overflow-y: hidden;
`;

function Navbar({ toggle, isOpen }) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  const [scrollY, setScrollY] = useState(0);
  const scrollThreshold = 100; // Adjust this threshold as needed
  const initialBackgroundColor = 'linear-gradient(#fff,transparent)'; // Initial gradient background
  const scrolledBackgroundColor = '#fff'; // Background color when scrolled

  const navbarBackgroundColor =
    scrollY > scrollThreshold
      ? scrolledBackgroundColor
      : initialBackgroundColor;

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <nav
        className="navbar"
        style={{
          background: navbarBackgroundColor,
          transition: 'background-color 0.7s', // Add a smooth transition
        }}
      >
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img
              className="brw-logo"
              src={ImgLogo}
              alt="BRW construction logo"
            />
          </Link>
          {/* <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div> */}
          <MenuIconWrap toggle={toggle} isOpen={isOpen}>
            <div
              className={`menu-icon ${isOpen ? 'open' : ''}`}
              onClick={toggle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="bar"></div>
              <div className="bar"></div>
              <div
                className={`${isHovered ? 'bar bar2 bar-hovered' : 'bar bar2'}`}
              ></div>
            </div>
          </MenuIconWrap>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <Link
                to="/contact"
                className="nav-links-mobile"
                onClick={closeMobileMenu}
              >
                <Button buttonStyle="btn--outline-nav">Contact Us</Button>
                Let's Talk
              </Link>
            </li>
          </ul>
          <div className="phone-wrap">
            <Link to="/contact">
              <i
                class="fa-solid fa-phone  phone
              
              "
              ></i>
            </Link>
          </div>
          {button && (
            <Link to="/contact" className="btn-wrap contact-us">
              <i
                class="fa-solid fa-phone phone-nav
              
              "
              ></i>
              CONTACT US
            </Link>
          )}
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
