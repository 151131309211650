import React from 'react';
import './PlanHeader.css';

function PlanHeader() {
  return (
    <div className="header-plan">Get construction work you can rely on:</div>
  );
}

export default PlanHeader;
