import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CardContainer = styled.div`
  //height: 100%;
  overflow: hidden;
  //height: 400px;
  width: 375px;
  border: 1px solid rgb(180, 180, 180);
  border-right: none;
  border-bottom: none;
  &:hover {
    cursor: pointer;
  }
`;
const CardWrapper = styled.div`
  height: 100%;
`;
const ImgWrap = styled.div`
  width: 100%;
  height: 200px;

  overflow: hidden;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 300ms ease-in-out;
  &:hover {
    transform: scale(1.08);
  }
`;
const Light = styled.div`
  text-transform: uppercase;
  color: rgb(180, 180, 180);
  font-weight: 300;
`;
const Header = styled.div`
  font-size: 1.1rem;
  font-weight: 400;
`;
const SubHeader = styled.div`
  color: #333;
  font-size: 1.1rem;
  font-weight: 300;
  //margin-top: 2.5px;
`;
const ReadMore = styled.div`
  color: #333;
  font-size: 1.1rem;
  font-weight: 300;
  margin-top: 10px;
  text-decoration: underline;
  transition: 300ms ease;
  &:hover {
    cursor: pointer;
    color: #081542;
  }
`;
const Content = styled.div`
  padding: 20px;
  //height: 100%;
`;

const BlogCard = (props) => {
  return (
    <CardContainer>
      <Link to={props.link}>
        <CardWrapper>
          <ImgWrap>
            <Img src={props.src} alt="" />
          </ImgWrap>
          <Content>
            <Light>{props.light}</Light>
            <Header>{props.header}</Header>
            <SubHeader>{props.sub}</SubHeader>
            <ReadMore>Read More</ReadMore>
          </Content>
        </CardWrapper>
      </Link>
    </CardContainer>
  );
};

export default BlogCard;
