import React, { useEffect } from 'react';
import Video from '../../images/brw-prac.mp4';
import styled from 'styled-components';
import { Button } from '../Button';
import Vid1 from '../../images/money.mp4';
import '../Stakes/Stakes.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Stakes1, {
  Black,
  Block,
  BlockDiv,
  BtnDiv,
  Span,
} from '../Stakes/Stakes1';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  @media screen and (max-width: 768px) {
    overflow-y: hidden;
  }
`;
const Wrapper = styled.div``;
const VideoBg = styled.video`
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  position: absolute;
  z-index: 0;
  //z-index: 0;
  top: 0;
  //z-index: 999;
`;
const Wrap = styled.div`
  z-index: 3;
  z-index: 3;
  background: rgba(9, 12, 78, 0.3);
  max-width: 800px;
  position: absolute;
  left: 5%;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  //align-items: center;
  @media screen and (max-width: 768px) {
    left: 0;
  }
`;
const Div = styled.div`
  overflow-y: hidden;
`;
const Header = styled.h1`
  color: #fff;
  font-size: 3.8rem;
  text-align: left;
  font-family: 'Poppins';
  font-family: 'Poppins', sans-serif;
  //line-height: 75px;
  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;
const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const BtnWrap = styled.div`
  width: 200px;
  margin-top: 25px;
`;
const Hero2 = () => {
  return (
    <Container>
      <VideoBg
        autoPlay
        loop
        muted
        src={Video}
        type="video/mp4"
        data-aos="fade"
      />
      <Wrap>
        <Header>BUILDING AN ENERGY EFFICIENT FUTURE</Header>
        <BtnWrap>
          <Link to="/video">
            <Button buttonSize="btn--large">Watch Video</Button>
          </Link>
        </BtnWrap>
      </Wrap>
      <Wrap>
        <Header>BUILDING AN ENERGY EFFICIENT FUTURE</Header>
        <BtnWrap>
          <Link to="/video">
            <Button buttonSize="btn--large">Watch Video</Button>
          </Link>
        </BtnWrap>
      </Wrap>
      <Block>
        <BlockDiv>
          <Span>
            Rising energy prices is a growing issue that won't go away.
          </Span>{' '}
          That's why we offer energy efficient design in our construction work
          so you can get ahead of your energy bills for a more comfortable, cost
          efficient future.
          <BtnWrap>
            <Button buttonSize="btn--large" buttonStyle="btn--outline">
              <BtnDiv>The Design Process </BtnDiv>
            </Button>
          </BtnWrap>
        </BlockDiv>
      </Block>
      <div className="text-box">
        <h1>
          <span className="span">SAVE YOUR</span> MONEY
        </h1>
      </div>
      <video autoPlay loop muted className="video">
        <source src={Vid1} type="video/mp4"></source>
      </video>
      <Black />
    </Container>
  );
};

export default Hero2;
