import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './Clean.css';

const ImgWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  display: inline-block;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover::before {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }
  &:hover {
    transform: scale(1.1);
  }
  transition-duration: 500ms;
`;
const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-left: -2px;
  transition-duration: 500ms;
  display: block;
  max-width: 100%;

  &:hover {
    transform: scale(1.2);
  }
`;

const ImgText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.3);
  color: white;

  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;
const ImgWrapWrap = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;

  @media screen and (max-width: 1100px) {
    height: 325px;
    width: 300px;
  }
  @media screen and (max-width: 1000px) {
    width: 350px;
    height: 350px;
  }
  @media screen and (max-width: 900px) {
    height: 300px;
    width: 275px;
  }
  @media screen and (max-width: 800px) {
    height: 350px;
    width: 350px;
  }
  @media screen and (max-width: 700px) {
    height: 275px;
    width: 325px;
  }
  @media screen and (max-width: 500px) {
    height: 300px;
  }
`;
const Place = styled.div`
  font-weight: 300;
  font-size: 1.2rem;
`;
const Br = styled.div`
  margin-top: 10px;
  font-size: 1rem;
`;
const Div = styled.div`
  height: 100%;

  // border-left: 1px solid #081542;
  overflow: hidden;
`;
const Details = styled.div`
  text-transform: uppercase;
  color: #333;
`;
const DetailsWrap = styled.div`
  display: none;
  padding-left: 10px;
  padding-top: 5px;
  @media screen and (max-width: 500px) {
    display: block;
  }
`;
const DetailsHeader = styled.div`
  text-transform: uppercase;
  color: #081542;
  font-size: 1.8rem;
`;
const CardClean = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Use the useInView hook to track whether the image is in view
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once when it first comes into view
  });

  // Use the useEffect hook to set the visibility state

  return (
    <Div>
      {/* {inView && ( */}
      <Link to={props.link}>
        <ImgWrapWrap>
          <ImgWrap
            className="image-container-clean"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Img src={props.src} alt="" loading="eager" />

            <ImgText className="image-text">
              <Place
                className={`${
                  isHovered
                    ? 'image-text img-txt-hov-clean'
                    : 'image-text img-txt-hov-no-clean'
                }`}
              >
                {props.header}

                <br />
                <Br>- click here -</Br>
              </Place>
            </ImgText>
          </ImgWrap>
        </ImgWrapWrap>
        <DetailsWrap>
          <Details>{props.header}</Details>
          <DetailsHeader>{props.details}</DetailsHeader>
        </DetailsWrap>
      </Link>
    </Div>
  );
};

export default CardClean;
