import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Span = styled.span`
  font-size: 3rem;
  color: #081542;
  color: #333;
  font-weight: 300;
`;

const NumberCounter = ({ targetValue, duration }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    if (targetValue <= 0) return;

    const intervalId = setInterval(() => {
      if (currentValue < targetValue) {
        setCurrentValue((prevValue) => prevValue + 1);
      } else {
        clearInterval(intervalId);
      }
    }, duration / targetValue);

    // Clear the interval when the animation is complete
    return () => clearInterval(intervalId);
  }, [currentValue, targetValue, duration]);

  // Trigger the animation when the component mounts
  useEffect(() => {
    setCurrentValue(0); // Start from 0
  }, [targetValue]);

  return <Span>{currentValue}</Span>;
};

export default NumberCounter;
