import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 70vh;
  background: #fff;
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
  }
  @media screen and (max-width: 380px) {
    height: 90vh;
  }
`;

const OnHundred = () => {
  return <Container></Container>;
};

export default OnHundred;
