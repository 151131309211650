import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../Button';
import { Button2 } from '../Button2';
import './CTA2.css';

const Container = styled.div`
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #071023;
  // background-color: #081542;
  // background-color: rgb(220, 220, 220);
  @media screen and (max-width: 1000px) {
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media screen and (max-width: 500px) {
    height: 100%;
  }
`;
const Wrapper = styled.div`
  width: 70%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
const Div = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  //border: 2px solid #000;
  box-sizing: border-box;
  padding: 40px 40px;
  margin: 32px;
  color: #000;
  /* border-radius: 30px;
  box-shadow: 5px -5px 15px 0 rgba(67, 67, 67, 0.5),
    -5px 5px 15px 0 rgba(67, 67, 67, 0.5);
  */
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    font-size: 1.5rem;
    text-align: center;
    grid-gap: 25px;
    margin: 10px;
    padding: 0;
    // margin-top: -50px;
  }
  @media screen and (max-width: 500px) {
    padding: 10px;
  }
`;
const Three = styled.div``;
const One = styled.div`
  color: rgb(190, 190, 190);
`;
const Two = styled.div`
  color: #081542;
  margin-top: 7.5px;
  color: #fff;
  // margin-bottom: 10px;
`;
const BtnWrap = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #081542;
  @media screen and (max-width: 500px) {
    width: 70vw;
    display: none;
  }
`;
const Line = styled.div`
  border-top: 4px solid #081542;
  margin-top: 15px;
  //width: 75px;
  margin-right: 10px;
  transition: all ease 500ms;
  //width: 150px;
`;
const Grid = styled.div`
  display: flex;
`;
const BtnWrapWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;
const BtnWrap2 = styled.div`
  //width: 60vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CTA = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Container>
      <Wrapper>
        <Div>
          <Three>
            <One>Have an upcoming project?</One> <Two>See how we can help</Two>
          </Three>
          <Link to="/contact">
            <BtnWrap
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Button2 buttonSize="btn--large1">
                <Grid>
                  <Line
                    className={`${isHovered ? 'hovered' : 'not-hovered'}`}
                  ></Line>
                  Get Started
                </Grid>
              </Button2>
            </BtnWrap>
          </Link>
          <Link to="/contact">
            <BtnWrapWrap>
              <BtnWrap2>
                <Button2 buttonSize="btn--large1">Get Started</Button2>
              </BtnWrap2>
            </BtnWrapWrap>
          </Link>
        </Div>
      </Wrapper>
    </Container>
  );
};

export default CTA;
