import React, { useEffect } from 'react';
import Img1 from '../../images/black-white.jpg';
import styled from 'styled-components';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Button } from '../Button';
import './CTA2.css';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const Container = styled.div`
  //height: 75vh;
  //background-color: #000;
  background: url('/images/case.jpg') center;
  //background-position: 50%;
  background-size: 100%;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  display: flex;
  justify-content: left;
  //align-items: center;
  //margin-left: 100px;
  @media screen and (max-width: 500px) {
    background-size: 150%;
    justify-content: center;
    // align-items: left;
    //display: grid;
  }
`;
const Wrapper = styled.div``;
const Img = styled.img`
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  z-index: 0;
`;
const Content = styled.div`
  //height: 100%;
  width: 100%;
  //background-color: #000;
  z-index: 3;
  color: #fff;
  margin-left: 100px;
  margin-top: 100px;
  @media screen and (max-width: 500px) {
    margin-left: 10px;
  }
  //position: absolute;
`;
const Header = styled.div`
  font-size: 2rem;
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
  }
`;
const One = styled.div`
  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
  }
`;
const Two = styled.div`
  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
  }
`;
const Three = styled.div`
  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
  }
`;
const Four = styled.div`
  border-bottom: 5px solid #fff;
  width: 50px;
  margin-top: 15px;
`;
const P = styled.div`
  //font-family: 'Poppins';
  font-weight: 300;
  margin-top: 25px;
`;
const BtnWrap = styled.div`
  width: 300px;
  margin-top: 25px;
`;
const Content2 = styled.div`
  padding: 75px;
  background: #fff;
  position: absolute;
  right: 0%;
  margin-right: 100px;
  padding-left: 150px;
  border-radius: 25px;
  transform: translateX(-50px);
`;
const BWrap = styled.div`
  margin-top: 25px;
  //margin-right: 300px;
  transform: translateX(-50px);
`;
const ButtonCTA = styled.div`
  padding: 12px 26px;
  font-size: 20px;
  max-width: 175px;
  background-color: #081542;
  color: #fff;
  &:hover {
    background-color: rgb(250, 251, 252);
    color: #242424;
    transition: all 0.3s ease-out;
    border: 1px solid #081542;
  }
`;
const CTA2 = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <Container>
      <Wrapper>
        <Content>
          <Header data-aos="fade-left">
            <One>Assuring Quality</One>
            <Two>Efficient, Friendly Communication</Two>
            <Three>Cost Effective Design</Three>
          </Header>
          <Four></Four>

          <P>Find out why we should be your next construction partner</P>
          <BtnWrap>
            <Button buttonSize="btn--large">Case Study</Button>
          </BtnWrap>
        </Content>
        <div ref={myRef}>
          <Content2
            className={`${''} ${myElementIsVisible ? 'animation' : 'null'}`}
          >
            <h1
              className={`${''} ${myElementIsVisible ? 'animation3' : 'null'}`}
            >
              Have an upcoming project?
            </h1>
            <h2
              className={`${''} ${myElementIsVisible ? 'animation2' : 'null'}`}
            >
              See how we can help!
            </h2>
            <Link to="/contact">
              <BWrap>
                <ButtonCTA
                  className={`${''} ${
                    myElementIsVisible ? 'animation4' : 'null'
                  }`}
                  buttonSize="btn--large"
                >
                  Get Started{' '}
                </ButtonCTA>
              </BWrap>
            </Link>
          </Content2>
        </div>
      </Wrapper>
    </Container>
  );
};

export default CTA2;
