import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import Img1 from '../../../images/projects/woodborough ext after.jpg';
import Img2 from '../../../images/projects/woodborough ext b4.jpg';
import Img3 from '../../../images/photo-1.jpg';
import Img4 from '../../../images/about-us-pic.jpg';
import Img5 from '../../../images/footer-pic.jpg';
import './Prac.css';
import NumberCounter from './NumberCounter';
import NumberCounter2 from './NumberCounter2';
import AlphabetCounter from './AlphabeticalCounter';
import AlphabeticalCounter from './WordAnimation/AlphaCounter';

const Container = styled.div`
  position: relative;
  z-index: 47;
  background: gray;
  //height: 150vh;
  height: 100vh;
  @media screen and (max-width: 1300px) {
    height: 750px;
  }
  @media screen and (max-width: 900px) {
    height: 1200px;
  }
`;
const SliderWrapper = styled.div`
  height: 100%;
`;
const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: url('/images/woodbru.jpg') fixed center;
  background-size: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: center;
  @media screen and (max-width: 1300px) {
    background-size: 120%;
  }
  @media screen and (max-width: 1200px) {
    background-size: 140%;
  }
  @media screen and (max-width: 1000px) {
    background-size: 160%;
  }
  @media screen and (max-width: 900px) {
    background-size: 180%;
  }
  @media screen and (max-width: 768px) {
    height: 100vh;
    background-size: 200%;
  }
  @media screen and (max-width: 600px) {
    background-size: 400%;
  }
`;
const Content = styled.div`
  //height: 130vh;
  width: 90%;
  bottom: 0;
  position: absolute;
  padding-bottom: 50px;
  //left: 0;
  margin-top: 150px;
  top: 0;
  background-color: rgba(7, 16, 40, 0.8);
  background-color: rgba(300, 300, 300, 0.9);
  @media screen and (max-width: 1300px) {
    height: 750px;
  }
  @media screen and (max-width: 900px) {
    height: 1200px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    top: 0;
  }
`;
const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column-reverse;
  }
`;
const One = styled.div`
  margin-left: 75px;
  margin-top: 75px;
  @media screen and (max-width: 900px) {
    margin-top: 25px;
  }
  @media screen and (max-width: 500px) {
    margin-left: 50px;
  }
`;
const Two = styled.div``;
const PWrap = styled.div`
  width: 100%;
  //height: 100%;
  display: flex;
  justify-content: center;
  //align-items: center;
`;
const Header = styled.div`
  font-size: 2.5rem;
  color: #081542;
  font-family: 'Roboto Slab', serif;
`;
const List = styled.div`
  width: 200%;
`;
const Stats = styled.div`
  font-size: 1.2rem;
  color: #333;
  color: #081542;
`;
const ListH = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: #081542;
  width: 200px;
  border-bottom: 1px solid #081542;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const ListH2 = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: #081542;
  width: 150px;
  border-bottom: 1px solid #081542;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const Ul = styled.ol``;
const Li = styled.li`
  color: #333;
  font-size: 1.2rem;
  margin-top: 10px;
  width: 110%;
`;
const HeadWrap = styled.div`
  height: 100px;
  width: 100%;
  overflow-y: hidden;
  @media screen and (max-width: 1200px) {
    height: 150px;
  }
  @media screen and (max-width: 900px) {
    height: 100px;
  }
  @media screen and (max-width: 500px) {
    height: 150px;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;
const Span = styled.span`
  font-size: 3rem;
  color: #081542;
  color: #333;
  font-weight: 300;
  margin-left: 7.5px;
`;
const StatsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const Dash = styled.span`
  font-size: 1.2rem;
  margin-left: -5px;
`;
const H1 = styled.div`
  font-size: 2.8rem;
  font-weight: 300;
  color: #333;
`;
const HeadWrap2 = styled.div`
  height: 100px;
  width: 100%;
  overflow-y: hidden;
`;
const H2 = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  color: #333;
`;

const WoodBru = ({ word }) => {
  const [addClass2, setAddClass2] = useState(false);

  useEffect(() => {
    // After 3000 milliseconds (3 seconds), add the class
    const timer = setTimeout(() => {
      setAddClass2(true);
    }, 500);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [currentSlide, setCurrentSlide] = useState(0);

  const data = [Img1, Img2];
  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? 1 : (prev) => prev - 1);
  };
  const nextSlide = () => {
    setCurrentSlide(currentSlide === 1 ? 0 : (prev) => prev + 1);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  console.log(currentIndex);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900); // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // const targetValue = 20; // Specify the target value
  // const duration = 2000;
  // const targetValue2 = 190;
  // const duration2 = 2000;
  // const [targetLetter, setTargetLetter] = useState('D'); // Set the target letter

  // const handleChangeTargetLetter = (event) => {
  //   setTargetLetter(event.target.value);
  // };
  // const word = 'Extension';
  // const [targetLetters, setTargetLetters] = useState(
  //   Array(word.length).fill('A')
  // ); // Initialize with 'A' for each letter
  // const duration = 3000; // Duration for each letter animation
  // const delay = 500; // Delay between each letter animation
  // const [animationComplete, setAnimationComplete] = useState(false);

  // useEffect(() => {
  //   let currentIndex = 0;

  //   const updateLetters = () => {
  //     if (currentIndex < word.length) {
  //       setTimeout(() => {
  //         const newTargetLetters = [...targetLetters];
  //         newTargetLetters[currentIndex] = word[currentIndex];
  //         setTargetLetters(newTargetLetters);
  //         currentIndex++;
  //         updateLetters();
  //       }, delay);
  //     } else {
  //       setAnimationComplete(true);
  //     }
  //   };

  //   updateLetters(); // Start updating letters
  // }, []);
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    const targetWord = 'Conversion';
    const checkAnimationCompletion = () => {
      if (word === targetWord) {
        setAnimationComplete(true);
      }
    };

    checkAnimationCompletion();
  }, [word]);
  return (
    <Container>
      <Wrapper>
        <Content>
          <ContentGrid>
            <One>
              <HeadWrap>
                <Header className={addClass2 ? 'head' : 'opac-0'}>
                  Woodborough, Nottingham
                </Header>
              </HeadWrap>
              <Grid>
                <StatsWrap>
                  <div>
                    <ListH2>Project Type:</ListH2>
                    <H1>
                      <AlphabeticalCounter
                        targetWord="Extension"
                        duration={10}
                      />
                    </H1>
                  </div>
                  {/* <Stats>
                    <div>Cost:</div>
                    <Span>£</Span>
                    <NumberCounter2
                      targetValue2={targetValue2}
                      duration2={duration2}
                    />
                    ,000
                  </Stats>
                  <Stats>
                    <div>Duration:</div>
                    <NumberCounter
                      targetValue={targetValue}
                      duration={duration}
                    />
                    <Span>Weeks</Span>
                  </Stats> */}
                </StatsWrap>
                <List>
                  <ListH>SERVICES UNDERTAKEN:</ListH>
                  <Ul>
                    <Li>
                      <Dash>-</Dash>Design
                    </Li>
                    <Li>
                      <Dash>-</Dash>Project management
                    </Li>
                    <Li>
                      <Dash>-</Dash>Construction
                    </Li>
                  </Ul>
                </List>
              </Grid>
            </One>
            <Two>
              {' '}
              <SliderWrapper>
                <div className="marg">
                  <div className="cont">
                    <div className=" box">
                      <div
                        style={{
                          transform: `translateX(-${
                            currentSlide * (isSmallScreen ? 90 : 40)
                          }vw)`,
                        }}
                        className="img-box"
                      >
                        <img
                          className="img"
                          src={data[0]}
                          alt=""
                          loading="priority"
                        />
                        <img className="img" src={data[1]} alt="" />
                        <img className="img" src={data[2]} alt="" />
                        <img className="img" src={data[3]} alt="" />
                        <img className="img" src={data[4]} alt="" />
                      </div>
                      <div className="arw-wrap"></div>
                    </div>
                  </div>

                  <div className="arw-wrap">
                    <div className=" arrows-l">
                      <div onClick={prevSlide} className="left-arw">
                        <i class="fa-solid fa-chevron-left"></i>
                      </div>
                    </div>
                    <div className="arrows-r">
                      <div onClick={nextSlide} className="right-arw">
                        <i class="fa-solid fa-chevron-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </SliderWrapper>
            </Two>
          </ContentGrid>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default WoodBru;
