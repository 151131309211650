import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import CTAW from '../CTA/CTA-white';
import Energy from '../Energy/Energy';
import Investors from '../Energy/Investors';
import Footer2 from '../Footer/Footer2';

const Cont = styled.div`
  position: relative;
  z-index: 47;
  background: #071023;
  margin-bottom: 30vh;
  @media screen and (max-width: 500px) {
    margin-bottom: 44.9vh;
  }
`;

const EnergyPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Energy Efficiency - BRW Construction, Nottingham, Burton Joyce
        </title>
        <meta
          name="description"
          content="Every day, ignoring energy efficiency not only costs you money but
          also precious time. Enhance your comfort, reduce future energy bills,
          and boost your home's value by upgrading to an energy-efficient home.
          Prepare for the future with one of today's smartest investments."
        />
        <link rel="canonical" href="/energy-efficiency" />
      </Helmet>
      <Cont>
        <Energy />
        <Investors />
        <CTAW />
      </Cont>
      <Footer2 />
    </>
  );
};

export default EnergyPage;
