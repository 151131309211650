import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CTA from '../../CTA/CTA';
import ImgR from '../../../images/solar-house.jpg';
import Footer2 from '../../Footer/Footer2';
import ImgLead from '../../../images/embracing.png';
const Container = styled.div`
  width: 100vw;
  height: 100%;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  background-color: rgb(202, 210, 255);
  background-color: rgb(230, 230, 230);
  position: relative;
  z-index: 50;
`;
const Wrapper = styled.div`
  margin-left: 200px;
  width: 800px;
  //height: 150vh;
  @media screen and (max-width: 1400px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 900px) {
    width: 80vw;
    margin-left: 50px;
  }
  @media screen and (max-width: 400px) {
    margin-left: 25px;
  }
`;
const Header = styled.div`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 25px;
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 2rem;
  }
`;
const Para = styled.div`
  color: #333;
  font-weight: 300;
  margin-top: 25px;
  margin-bottom: 25px;
`;
const SubHeader = styled.div`
  font-weight: 500;
  font-size: 2rem;
`;

const List = styled.ol`
  font-weight: 300;
  color: #333;
`;
const Span2 = styled.span`
  color: #000;
  font-weight: 400;
`;
const Li = styled.li`
  margin-bottom: 15px;
`;
const Sticky = styled.div`
  position: fixed;
  z-index: 0;
  margin-right: 75px;
  right: 0;
  top: 0;
  height: 100vh;
  width: 350px;
  background: rgb(237, 237, 237);
  display: flex;
  align-items: center;
  justify-content: center;
  //opacity: 0;
  @media screen and (max-width: 1500px) {
    margin-right: 25px;
  }
  @media screen and (max-width: 1300px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 1275px) {
    display: none;
  }
`;
// const LeadGen = styled.div`
//   background: #fff;
//   //background: #071023;
//   //height: 20vh;
//   width: 100%;
//   padding-bottom: 20px;
//   margin-top: 75px;
// `;
// const LeadImg = styled.img`
//   height: 250px;
// `;
// const LeadImgWrap = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: center;
//   padding: 25px;
//   align-items: center;
// `;
// const LeadDiv = styled.div`
//   //color: #333;
//   padding: 25px;
//   font-weight: 500;
//   color: #fff;
//   font-size: 1.1rem;
//   text-align: center;
//   background: #071023;
// `;
const LeadGen = styled.div`
  background: #fff;
  //background: #071023;
  //height: 20vh;
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  margin-top: 75px;
  @media screen and (max-height: 650px) {
    margin-top: 125px;
  }
`;
const LeadImg = styled.img`
  height: 250px;
  @media screen and (max-height: 700px) {
    height: 200px;
  }
  @media screen and (max-height: 650px) {
    height: 225px;
  }
  @media screen and (max-height: 550px) {
    height: 200px;
  }
`;
const LeadImgWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
  align-items: center;
`;
const LeadDiv = styled.div`
  //color: #333;
  padding: 25px;
  font-weight: 500;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  background: #071023;
  @media screen and (max-height: 700px) {
    font-size: 1rem;
    padding: 5px;
  }
  @media screen and (max-height: 600px) {
    display: none;
  }
`;
const CTADiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const Div = styled.div`
  @media screen and (max-height: 650px) {
    font-size: 0.9rem;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
`;

const BlogContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  //align-items: center;
  //justify-content: center;
  background: #fff;
  margin-left: 350px;
  width: 825px;
  padding-top: 50px;
  height: 250vh;
`;
const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-left: 25px;
  grid-row-gap: 0px;
`;
const Wrap = styled.div`
  position: relative;
  z-index: 49;
  height: 500vh;
  width: 100vw;
`;
const Btn = styled.div`
  padding: 10px 20px;
  font-size: 16px;
  //min-width: 50px;
  max-width: 250px;
  transition: 300ms ease-in-out;
  background: #081542;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid #071023;
    cursor: pointer;
  }
`;
// const CTADiv = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   text-align: center;
// `;
// const Div = styled.div``;
const Span = styled.span`
  color: #000;
  text-decoration: underline;
  transition: 300ms ease;
  &:hover {
    cursor: pointer;
    color: blue;
  }
`;
const Img = styled.img`
  height: 250px;
  width: 500px;
  object-fit: cover;
`;
const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const Sustainable = () => {
  return (
    <>
      <Container>
        <Sticky>
          <LeadGen>
            <LeadDiv>Embracing Energy Efficiency</LeadDiv>
            <LeadImgWrap>
              <LeadImg src={ImgLead} alt="free pdf" />
            </LeadImgWrap>
            <CTADiv>
              <Div>TAKE CONTROL OF YOUR ENERGY BILLS:</Div>
              <Div>DOWNLOAD OUR FREE GUIDE NOW</Div>
              <a href="https://mailchi.mp/4cb5ae9e1389/embracing-energy-efficiecny">
                <BtnWrap>
                  <Btn>DOWNLOAD THE GUIDE</Btn>
                </BtnWrap>
              </a>
            </CTADiv>
          </LeadGen>
        </Sticky>
        <Wrapper>
          <Header>The Latest Trends in Sustainable Construction</Header>
          <Img src={ImgR} alt="" />
          <Para>
            In an era marked by a growing awareness of environmental concerns
            and a desire for more eco-friendly living spaces, sustainable
            construction has become a prominent trend in the building industry.
            Homeowners and businesses alike are increasingly seeking ways to
            reduce their environmental footprint and lower long-term operating
            costs. As a result, sustainable construction practices are gaining
            momentum and transforming the way we build.
          </Para>
          <SubHeader>Sustainable Materials and Green Building</SubHeader>
          <Para>
            <Span2>1. Recycled and Reclaimed Materials:</Span2> Sustainable
            construction embraces the use of recycled and reclaimed materials.
            Builders are turning to reclaimed wood, recycled glass, and salvaged
            materials to reduce the demand for new resources and minimize waste.
          </Para>
          <Para>
            <Span2>2. Low-Impact Building Products:</Span2> The market is
            flooded with low-impact building products, including low-VOC
            (volatile organic compounds) paints, eco-friendly insulation, and
            sustainable flooring options. These products promote healthier
            indoor air quality and reduce the environmental impact of
            construction.
          </Para>
          <SubHeader>Energy Efficiency</SubHeader>
          <Para>
            <Span2>3. Passive House Design:</Span2> Passive house design
            principles are gaining popularity. These designs focus on optimizing
            insulation, air-tightness, natural light, and efficient ventilation
            to minimize energy consumption. Passive homes can significantly
            reduce heating and cooling costs while maintaining a comfortable
            interior climate.
          </Para>
          <Para>
            <Span2>4. Solar Power Integration:</Span2> Solar panels and solar
            roofing systems are becoming more affordable and accessible.
            Sustainable builders are incorporating solar power into their
            designs, allowing homeowners to generate clean energy and reduce
            their reliance on the grid.
          </Para>
          <SubHeader>Green Technologies</SubHeader>
          <Para>
            <Span2>5. Smart Building Systems:</Span2> Smart technologies are
            revolutionizing construction. Home automation systems, energy
            management tools, and IoT (Internet of Things) devices enable
            property owners to monitor and control their energy usage, lighting,
            and HVAC systems remotely.
          </Para>
          <Para>
            <Span2>6. Water-Saving Innovations:</Span2> Water-efficient fixtures
            and landscaping techniques are a key focus in sustainable
            construction. Rainwater harvesting, graywater recycling, and
            drought-resistant landscaping are all part of the effort to conserve
            water resources.
          </Para>
          {/* <SubHeader>Green Certifications and Standards</SubHeader>
          <Para>
            <Span2>7. LEED Certification:</Span2> Leadership in Energy and
            Environmental Design (LEED) certification continues to be a
            benchmark for sustainable construction projects. Achieving LEED
            certification demonstrates a commitment to eco-friendly building
            practices.
          </Para>
          <Para>
            <Span2>8. Net-Zero Energy Buildings:</Span2> The goal of
            constructing net-zero energy buildings, which produce as much energy
            as they consume, is gaining traction. These buildings are
            energy-efficient, utilize renewable energy sources, and often
            feature advanced insulation and HVAC systems.
          </Para> */}
          <SubHeader>Benefits of Sustainable Construction</SubHeader>
          <Para>
            The benefits of sustainable construction extend beyond environmental
            concerns. They also include:
          </Para>
          <List>
            <Li>
              <Span2>Cost Savings:</Span2> Energy-efficient designs and
              materials can lead to lower utility bills and maintenance costs.
            </Li>
            <Li>
              <Span2>Enhanced Property Value:</Span2> Sustainable features can
              increase the resale value of properties.
            </Li>
            <Li>
              <Span2>Healthier Living and Working Environments:</Span2> Improved
              indoor air quality and comfort contribute to healthier spaces.
            </Li>
            <Li>
              <Span2>Compliance with Regulations:</Span2> Sustainable practices
              often align with building codes and regulations.
            </Li>
          </List>
          <SubHeader>Conclusion</SubHeader>
          <Para>
            Sustainable construction is no longer a niche trend; it's a driving
            force in the industry. As the demand for eco-friendly and
            energy-efficient spaces continues to grow, staying informed about
            the latest trends in sustainable construction is crucial for
            builders and property owners alike. Embracing these trends not only
            reduces the environmental impact of construction but also enhances
            the quality, efficiency, and value of the built environment.
          </Para>
          <Para>
            For sustainable construction solutions tailored to your project's
            needs,{' '}
            <Span>
              <Link to="/contact">contact BRW Construction</Link>
            </Span>
            . We specialize in eco-friendly building practices and can help you
            create a sustainable and energy-efficient space that meets your
            goals and values.
          </Para>
        </Wrapper>
      </Container>
    </>
  );
};

export default Sustainable;
