import React from 'react';
import './Video.css';

export default function Video() {
  return (
    <div>
      <div className="video-container">
        <h1 className="video-header">
          Here's we should be the construction company of your choice:
        </h1>
        <div className="ratio">
          <iframe
            width="90%"
            height="650"
            src="https://www.youtube.com/embed/hcwgyAPY6es"
            title="YouTube video"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}
