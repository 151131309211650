import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CTA from '../../CTA/CTA';
import ImgR from '../../../images/hammer-jeans.jpg';
import Footer2 from '../../Footer/Footer2';
import ImgLead from '../../../images/embracing.png';
const Container = styled.div`
  width: 100vw;
  height: 100%;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  background-color: rgb(202, 210, 255);
  background-color: rgb(230, 230, 230);
  position: relative;
  z-index: 50;
`;
const Wrapper = styled.div`
  margin-left: 200px;
  width: 800px;
  //height: 150vh;
  @media screen and (max-width: 1400px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 900px) {
    width: 80vw;
    margin-left: 50px;
  }
  @media screen and (max-width: 400px) {
    margin-left: 25px;
  }
`;
const Header = styled.div`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 25px;
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 2rem;
  }
`;
const Para = styled.div`
  color: #333;
  font-weight: 300;
  margin-top: 25px;
  margin-bottom: 25px;
`;
const SubHeader = styled.div`
  font-weight: 500;
  font-size: 2rem;
`;
const Sticky = styled.div`
  position: fixed;
  z-index: 0;
  margin-right: 75px;
  right: 0;
  top: 0;
  height: 100vh;
  width: 350px;
  background: rgb(237, 237, 237);
  display: flex;
  align-items: center;
  justify-content: center;
  //opacity: 0;
  @media screen and (max-width: 1500px) {
    margin-right: 25px;
  }
  @media screen and (max-width: 1300px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 1275px) {
    display: none;
  }
`;
const LeadGen = styled.div`
  background: #fff;
  //background: #071023;
  //height: 20vh;
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  margin-top: 75px;
  @media screen and (max-height: 650px) {
    margin-top: 125px;
  }
`;
const LeadImg = styled.img`
  height: 250px;
  @media screen and (max-height: 700px) {
    height: 200px;
  }
  @media screen and (max-height: 650px) {
    height: 225px;
  }
  @media screen and (max-height: 550px) {
    height: 200px;
  }
`;
const LeadImgWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
  align-items: center;
`;
const LeadDiv = styled.div`
  //color: #333;
  padding: 25px;
  font-weight: 500;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  background: #071023;
  @media screen and (max-height: 700px) {
    font-size: 1rem;
    padding: 5px;
  }
  @media screen and (max-height: 600px) {
    display: none;
  }
`;
const CTADiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const Div = styled.div`
  @media screen and (max-height: 650px) {
    font-size: 0.9rem;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
`;
const BlogContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  //align-items: center;
  //justify-content: center;
  background: #fff;
  margin-left: 350px;
  width: 825px;
  padding-top: 50px;
  height: 250vh;
`;
const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-left: 25px;
  grid-row-gap: 0px;
`;
const Wrap = styled.div`
  position: relative;
  z-index: 49;
  height: 500vh;
  width: 100vw;
`;
const Btn = styled.div`
  padding: 10px 20px;
  font-size: 16px;
  //min-width: 50px;
  max-width: 250px;
  transition: 300ms ease-in-out;
  background: #081542;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid #071023;
    cursor: pointer;
  }
`;
// const CTADiv = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   text-align: center;
// `;
// const Div = styled.div``;
const Span = styled.span`
  color: #000;
  text-decoration: underline;
  transition: 300ms ease;
  &:hover {
    cursor: pointer;
    color: blue;
  }
`;
const Img = styled.img`
  height: 250px;
  width: 500px;
  object-fit: cover;
`;
const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const RightContractor = () => {
  return (
    <>
      <Container>
        <Sticky>
          <LeadGen>
            <LeadDiv>Embracing Energy Efficiency</LeadDiv>
            <LeadImgWrap>
              <LeadImg src={ImgLead} alt="free pdf" />
            </LeadImgWrap>
            <CTADiv>
              <Div>TAKE CONTROL OF YOUR ENERGY BILLS:</Div>
              <Div>DOWNLOAD OUR FREE GUIDE NOW</Div>
              <a href="https://mailchi.mp/4cb5ae9e1389/embracing-energy-efficiecny">
                <BtnWrap>
                  <Btn>DOWNLOAD THE GUIDE</Btn>
                </BtnWrap>
              </a>
            </CTADiv>
          </LeadGen>
        </Sticky>
        <Wrapper>
          <Header>Choosing the Right Contractor: A Step-by-Step Guide</Header>
          <Img src={ImgR} alt="" />
          <Para>
            Embarking on a construction or renovation project is an exciting
            endeavor, but finding the right contractor to bring your vision to
            life is crucial. The contractor you select can make all the
            difference between a successful project and a costly, stressful
            experience. In this guide, we'll walk you through the essential
            steps to help you choose the right contractor for your project.
          </Para>
          <SubHeader>Step 1: Define Your Project Scope and Goals</SubHeader>
          <Para>
            Before you start searching for a contractor, it's crucial to have a
            clear understanding of your project's scope and goals. Outline your
            project's specifications, budget, and timeline. Knowing exactly what
            you want will make it easier to communicate your needs to potential
            contractors.
          </Para>
          <SubHeader>Step 2: Ask for Recommendations</SubHeader>
          <Para>
            Seek recommendations from friends, family, neighbors, or colleagues
            who have had similar work done. Personal referrals can provide
            valuable insights into a contractor's reputation and work quality.
          </Para>
          <SubHeader>Step 3: Research and Gather Inspiration</SubHeader>
          <Para>
            Explore home improvement magazines, websites, and social media
            platforms for inspiration. Collect photos, ideas, and design
            concepts that resonate with your vision. This will help you
            communicate your preferences to your contractor.
          </Para>
          <SubHeader>Step 4: Find the Right Contractor</SubHeader>
          <Para>
            Choosing the right contractor is perhaps the most critical decision
            you'll make. Research local contractors, read client testimonials,
            and look at past work to ensure you're hiring a reputable team. You
            should be able to find this information on the companies website.
          </Para>
          <SubHeader>Step 5: Secure Necessary Permits</SubHeader>
          <Para>
            Most renovations require permits from your local government.
            Skipping this step can lead to costly delays and legal issues. Work
            with your contractor to obtain the necessary permits and ensure
            compliance with building codes.
          </Para>
          <SubHeader>Step 6: Review the Contract</SubHeader>
          <Para>
            Before signing any contracts, carefully review the terms and
            conditions. Ensure that all project details, costs, and timelines
            are documented in writing. A well-drafted contract protects both
            parties and clarifies expectations.
          </Para>
          <SubHeader>Step 7: Monitor Progress</SubHeader>
          <Para>
            Once your project is underway, maintain open communication with your
            contractor. Regularly monitor progress and address any concerns
            promptly. A successful project relies on collaboration and
            transparency.
          </Para>

          <SubHeader>Step 8: Trust Your Instincts</SubHeader>
          <Para>
            Ultimately, trust your instincts when choosing a contractor. Select
            the contractor who not only meets your project requirements but also
            makes you feel confident and comfortable throughout the process.
          </Para>
          <Para>
            By following this step-by-step guide, you'll be well on your way to
            selecting the right contractor for your construction project.
            Remember, making an informed choice now will lead to a successful
            and stress-free project in the future.
          </Para>
          <Para>
            For expert construction services and a team you can trust,
            <Span>
              <Link to="/contact"> BRW Construction</Link>
            </Span>
            . We undertake all aspects of construction and have a proven track
            record of delivering exceptional results. Don't settle for less when
            it comes to your construction project. Choose BRW Construction for
            excellence from start to finish today.
          </Para>
        </Wrapper>
      </Container>
    </>
  );
};

export default RightContractor;
