import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CTA from '../../CTA/CTA';
import ImgR from '../../../images/house.jpg';

import Footer2 from '../../Footer/Footer2';
import ImgLead from '../../../images/embracing.png';
const Container = styled.div`
  width: 100vw;
  height: 100%;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  background-color: rgb(202, 210, 255);
  background-color: rgb(230, 230, 230);
  position: relative;
  z-index: 50;
`;
const Wrapper = styled.div`
  margin-left: 200px;
  width: 800px;
  //height: 150vh;
  @media screen and (max-width: 1400px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 900px) {
    width: 80vw;
    margin-left: 50px;
  }
  @media screen and (max-width: 400px) {
    margin-left: 25px;
  }
`;
const Header = styled.div`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 25px;
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 380px) {
    font-size: 2rem;
  }
`;
const Para = styled.div`
  color: #333;
  font-weight: 300;
  margin-top: 25px;
  margin-bottom: 25px;
`;
const SubHeader = styled.div`
  font-weight: 500;
  font-size: 2rem;
`;
const Sticky = styled.div`
  position: fixed;
  z-index: 0;
  margin-right: 75px;
  right: 0;
  top: 0;
  height: 100vh;
  width: 350px;
  background: rgb(237, 237, 237);
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1500px) {
    margin-right: 25px;
  }
  @media screen and (max-width: 1300px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 1275px) {
    display: none;
  }
`;
const Span = styled.span`
  color: #000;
  text-decoration: underline;
  transition: 300ms ease;
  &:hover {
    cursor: pointer;
    color: blue;
  }
`;
const Img = styled.img`
  height: 250px;
  width: 500px;
  object-fit: cover;
`;

//
const LeadGen = styled.div`
  background: #fff;
  //background: #071023;
  //height: 20vh;
  width: 100%;
  padding-bottom: 20px;
  overflow: hidden;
  margin-top: 75px;
  @media screen and (max-height: 650px) {
    margin-top: 125px;
  }
`;
const LeadImg = styled.img`
  height: 250px;
  @media screen and (max-height: 700px) {
    height: 200px;
  }
  @media screen and (max-height: 650px) {
    height: 225px;
  }
  @media screen and (max-height: 550px) {
    height: 200px;
  }
`;
const LeadImgWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
  align-items: center;
`;
const LeadDiv = styled.div`
  //color: #333;
  padding: 25px;
  font-weight: 500;
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
  background: #071023;
  @media screen and (max-height: 700px) {
    font-size: 1rem;
    padding: 5px;
  }
  @media screen and (max-height: 600px) {
    display: none;
  }
`;
const CTADiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const Div = styled.div`
  @media screen and (max-height: 650px) {
    font-size: 0.9rem;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
`;
const BlogContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  //align-items: center;
  //justify-content: center;
  background: #fff;
  margin-left: 350px;
  width: 825px;
  padding-top: 50px;
  height: 250vh;
`;
const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-left: 25px;
  grid-row-gap: 0px;
`;
const Wrap = styled.div`
  position: relative;
  z-index: 49;
  height: 500vh;
  width: 100vw;
`;
const Btn = styled.div`
  padding: 10px 20px;
  font-size: 16px;
  //min-width: 50px;
  max-width: 250px;
  transition: 300ms ease-in-out;
  background: #081542;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid #071023;
    cursor: pointer;
  }
`;
// const CTADiv = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   text-align: center;
// `;
// const Div = styled.div``;

const BtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const CostVsValue = () => {
  return (
    <>
      <Container>
        <Sticky>
          <LeadGen>
            <LeadDiv>Embracing Energy Efficiency</LeadDiv>
            <LeadImgWrap>
              <LeadImg src={ImgLead} alt="free pdf" />
            </LeadImgWrap>
            <CTADiv>
              <Div>TAKE CONTROL OF YOUR ENERGY BILLS:</Div>
              <Div>DOWNLOAD OUR FREE GUIDE NOW</Div>
              <a href="https://mailchi.mp/4cb5ae9e1389/embracing-energy-efficiecny">
                <BtnWrap>
                  <Btn>DOWNLOAD THE GUIDE</Btn>
                </BtnWrap>
              </a>
            </CTADiv>
          </LeadGen>
        </Sticky>
        <Wrapper>
          <Header>
            Cost vs. Value: Which Home Improvement Projects Add the Most Resale
            Value?
          </Header>
          <Img src={ImgR} alt="" />
          <Para>
            As a homeowner, you may find yourself contemplating various home
            improvement projects to enhance your living space. While the desire
            to create a more comfortable and aesthetically pleasing environment
            is natural, it's also important to consider the long-term financial
            implications. Many homeowners wonder which home improvement projects
            will not only make their homes more enjoyable but also add
            significant resale value.
          </Para>
          <Para>
            In this blog post, we'll explore some popular home improvement
            projects and their potential return on investment (ROI).
            Understanding the cost vs. value equation can help you make informed
            decisions about where to allocate your renovation budget.
          </Para>
          <SubHeader>The Key Factors: Cost vs. Value</SubHeader>
          <Para>
            When assessing the value of a home improvement project, it's crucial
            to weigh two primary factors: the cost of the project and the
            potential resale value it adds to your home. Let's take a closer
            look at some projects that tend to provide a high ROI:
          </Para>
          <SubHeader>1. Energy-efficient Upgrades</SubHeader>
          <Para>
            In today's environmentally conscious world, energy-efficient
            upgrades are gaining popularity. Projects such as installing
            energy-efficient windows, adding insulation, and upgrading heating
            and cooling systems not only reduce utility bills but also add value
            to your home. Energy-efficient upgrades can yield an ROI of 60-80%.
            Potential buyers appreciate the cost savings and sustainability of
            an energy-efficient home.
          </Para>
          <SubHeader>2. Kitchen Remodel</SubHeader>
          <Para>
            A kitchen remodel is a classic choice for homeowners looking to add
            value to their homes. Updating kitchen cabinets, countertops,
            appliances, and lighting can transform your cooking space into a
            culinary haven. According to industry experts, a well-executed
            kitchen remodel can yield an ROI of up to 70-80%. Prospective buyers
            often view an updated kitchen as a significant selling point.
          </Para>
          <SubHeader>3. Bathroom Renovation</SubHeader>
          <Para>
            Bathrooms are another area where improvements can pay off
            handsomely. Modernizing fixtures, tiles, and vanities can
            significantly increase your home's resale value. A bathroom
            renovation can provide an ROI of around 60-70%. A well-designed and
            up-to-date bathroom can attract potential buyers and make a lasting
            impression.
          </Para>
          <SubHeader>4. Curb Appeal Enhancementsr</SubHeader>
          <Para>
            First impressions matter, and that's where curb appeal comes into
            play. Exterior improvements like landscaping, repainting, and
            updating entryways can boost your home's visual appeal. Investing in
            curb appeal can offer an ROI of 50-60%. A welcoming exterior can
            entice buyers and set a positive tone for their overall impression
            of your property.
          </Para>
          <SubHeader>5. Finished Basement or Attic</SubHeader>
          <Para>
            Converting an unfinished basement or attic into functional living
            space can be a wise investment. It increases your home's square
            footage and versatility. A well-executed basement or attic
            renovation can provide an ROI of 50-70%. The additional living space
            can be a selling point for families looking to maximize their home's
            potential.
          </Para>
          <SubHeader>Conclusion</SubHeader>
          <Para>
            When deciding on home improvement projects, it's essential to
            balance your personal preferences with potential resale value. While
            some projects offer a higher ROI, remember that your enjoyment of
            your home should be a top priority. Ultimately, the best home
            improvement projects are those that align with your lifestyle,
            budget, and long-term goals.
          </Para>

          <Para>
            At{' '}
            <Span>
              <Link to="/contact">BRW Construction</Link>
            </Span>
            , we specialize in helping homeowners bring their renovation visions
            to life. Whether you're considering a kitchen remodel, bathroom
            renovation, or other home improvement projects, our team of experts
            is here to assist you every step of the way. Contact us today to
            discuss your ideas and receive a free consultation.
          </Para>
          <Para>
            Invest wisely in your home, and it will not only provide comfort and
            enjoyment but also prove to be a valuable asset when it's time to
            sell. This blog post provides valuable insights into the cost vs.
            value consideration for various home improvement projects, helping
            potential clients make informed decisions about their renovations.
            Customize it with your company's specific details, services, and
            contact information to enhance your SEO and promote your business
            effectively.
          </Para>
        </Wrapper>
      </Container>
    </>
  );
};

export default CostVsValue;
