import React from 'react';
import styled from 'styled-components';
import About from '../About/About';
import Story from '../About/Story';
import Vision from '../About/Vision';
import CTA from '../CTA/CTA';
import CTAW from '../CTA/CTA-white';
import Footer2 from '../Footer/Footer2.js';
import Footer3 from '../Footer/Footer3';

const Cont = styled.div`
  position: relative;
  z-index: 50;
  margin-bottom: 30vh;
  @media screen and (max-width: 1000px) {
    margin-bottom: 44.9vh;
  }
`;

const AboutPage = () => {
  return (
    <>
      <Cont>
        <About />
        <Story />
        <CTAW />
      </Cont>
      <Footer2 />
    </>
  );
};

export default AboutPage;
