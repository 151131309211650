import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Img1 from '../../images/BRW-logo.webp';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  font-size: 3rem;
`;
const ImgWrap = styled.div``;
const Img = styled.img`
  @media screen and (max-width: 500px) {
    height: 150px;
  }
`;
const Div = styled.div`
  position: absolute;
  top: 250px;
  left: 115px;
`;
const Div2 = styled.div`
  position: absolute;
  top: 250px;
  right: 115px;
`;
const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;
const H1 = styled.h1`
  color: #081542;
  font-family: 'Roboto Slab', serif;
  font-size: 2em;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const OnLoad = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const [loading2, setLoading2] = useState(false);
  useEffect(() => {
    setLoading2(true);
    setTimeout(() => {
      setLoading2(false);
    }, 3000);
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <Container>
      {loading ? (
        <ImgWrap data-aos="fade">
          <Img src={Img1} />
        </ImgWrap>
      ) : (
        <H1 data-aos="fade">Bespoke Excellence in Construction</H1>
      )}
    </Container>
  );
};

export default OnLoad;
