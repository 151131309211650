import React from 'react';
import styled from 'styled-components';
import Img1 from '../../images/thumbnail.jpg';

const Container = styled.div`
  background: rgb(237, 237, 237);
`;
const Wrapper = styled.div`
  margin-left: 250px;
  @media screen and (max-width: 500px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
const ImgWrap = styled.div``;
const Img = styled.img`
  height: 20vh;
  width: 100%;
  object-fit: cover;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  @media screen and (max-width: 500px) {
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
  }
`;
const Content = styled.div`
  /* height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; */
  margin-right: 75px;
  //justify-content: center;
`;
const H = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  margin-top: 40px;
`;
const Blue = styled.div`
  color: #081542;
  font-size: 1.15rem;
  margin-top: 25px;
`;
const NotBlue = styled.div`
  color: #333;
  margin-top: 25px;
`;
const Vision = () => {
  return (
    <Container>
      <Wrapper>
        <Grid>
          <Content>
            <H>Our Mission</H>
            <Blue>
              At BRW, our mission is clear: to excel in the construction
              industry by providing unmatched value to our customers. In an era
              of global warming and escalating energy expenses, we recognize
              that delivering exceptional value hinges on our commitment to
              energy efficiency.
            </Blue>
            <NotBlue>
              This approach not only enables our customers to make long-term
              savings by reducing energy costs but also enhances their quality
              of life with cozier winters and cooler summers.
            </NotBlue>
            <NotBlue>
              Also for savvy investors eyeing the real estate market, embracing
              the potential of energy efficiency is your ticket to a thriving
              portfolio. Energy-efficient homes are in high demand, and it's not
              hard to see why. They promise substantial savings, a greener
              planet, and a brighter financial future. So why wait? Now is the
              perfect time to explore the world of energy-efficient homes and
              watch your real estate portfolio thrive.
            </NotBlue>
            <NotBlue>
              We're dedicated to building a future where sustainable
              construction practices redfine comfort living and economic
              well-being.
            </NotBlue>
          </Content>
          <ImgWrap>
            <Img src={Img1} alt="" />
          </ImgWrap>
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default Vision;
