import React from 'react';
import styled from 'styled-components';
import './Stakes.css';
import Img1 from '../images/blueHouse.jpg';

export const StakesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
export const VideoWrap = styled.img`
  width: 100%;
  height: 80vh;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    height: 20vh;
  }
`;
export const StakesContent = styled.div``;

function Stakes() {
  return (
    <div className="stakes">
      <StakesWrapper>
        <VideoWrap src={Img1} alt="promo video"></VideoWrap>
        <StakesContent>
          <h1 className="stakes-header">
            Have you been frustrated by builders who:
          </h1>

          <div className="stakes-container">
            <div className="stakes-list-items">
              <i class="fa-solid fa-circle-xmark"></i>
              <div>Delays over completion</div>
            </div>

            <div className="stakes-list-items">
              <i class="fa-solid fa-circle-xmark"></i>
              <div>Leaves your project in a mess</div>
            </div>

            <div className="stakes-list-items">
              <i class="fa-solid fa-circle-xmark"></i>
              <div>Produce less quality than you expect</div>
            </div>
            <div className="stakes-list-items">
              <i class="fa-solid fa-circle-xmark"></i>
              <div>Doesn't communicate with you effectively</div>
            </div>
            <div className="stakes-list-items">
              <i class="fa-solid fa-circle-xmark"></i>
              <div>Damages your property</div>
            </div>
          </div>
        </StakesContent>
      </StakesWrapper>
    </div>
  );
}

export default Stakes;
