import React from 'react';
import styled from 'styled-components';
import Benefits from '../Benefits/Benefits';
import CTA from '../CTA/CTA';
import Hero3 from '../Hero3/Hero3';
import TheGuide from '../TheGuide/TheGuide';
import TheStakes from '../TheStakes/TheStakes';
import Footer2 from '../Footer/Footer2.js';
import CTAW from '../CTA/CTA-white';
import LeadGenerator from '../LeadGen/LeadGenerator';
import ContactLeadGen from '../LeadGen/ContactLeadGen';
import PopupAd from '../PopUp/PopUpAd';
import { Helmet } from 'react-helmet-async';
import View from '../View/View';

const OF = styled.div`
  overflow: hidden;
`;
const Cont = styled.div`
  position: relative;
  z-index: 3;
  margin-bottom: 30vh;
  background: gray;
  @media screen and (max-width: 1000px) {
    margin-bottom: 44.9vh;
  }
`;
const NoShow = styled.div`
  @media screen and (max-width: 1000px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const HomePage2 = () => {
  return (
    <>
      <Helmet>
        <title>BRW Construction - Nottingham, Burton Joyce</title>
        <meta
          name="description"
          content="Your project is a significant investment that has to be perfect. At BRW, you are getting high-end, reliable construction bespoke for your needs so you can turn your vision into a reality."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <Cont>
        <Hero3 />
        <OF>
          <View />
        </OF>
        <Benefits />
        <TheGuide />

        <NoShow>
          <LeadGenerator />
        </NoShow>
        <ContactLeadGen />
        <CTA />
      </Cont>

      <Footer2 />
    </>
  );
};

export default HomePage2;
