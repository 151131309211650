import React from 'react';
import './OnLoad.css';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import Img1 from '../../images/photo-1.jpg';
import { Button } from '../Button';

const Container = styled.div`
  height: 80vh;

  background-color: #fff;
  @media screen and (max-width: 768px) {
    height: 90vh;
  }
`;
const Wrapper = styled.div``;
const Img = styled.img`
  width: 100vw;
  height: 50vh;
  position: relative;
  object-fit: cover;
  top: 10%;
`;
const Block2 = styled.div`
  background-color: #081542;
  background-color: #fe681c;
  background-color: #6e8bbd;
  //background-color: turquoise;
  padding: 50px;
  position: absolute;
  top: 0%;
  right: 0;
  border-radius: 25px 0px 0px 25px;
  padding: 125px;
  padding-top: 75px;
  padding-right: 50px;
  padding-left: 125px;
  max-width: 700px;

  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding-left: 50px;
    padding-right: 25px;
    top: 15%;
  }
`;
const BlockDiv = styled.div`
  font-size: 2rem;
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
const BtnWrap = styled.div`
  margin-top: 50px;
`;

const CaseStudy = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView();
  return (
    <Container>
      <Wrapper>
        <section className="section">
          <div ref={myRef}>
            <h5
              className={`${''} ${myElementIsVisible ? 'animation' : 'null'}`}
            >
              <span className="first-bit first">E</span>
              <span className="first-bit">n</span>
              <span className="first-bit">e</span>
              <span className="first-bit">r</span>
              <span className="first-bit">g</span>
              <span className="first-bit">y</span>
              <span className="gap"></span>
              <span className="first-bit">e</span>
              <span className="first-bit">f</span>
              <span className="first-bit">f</span>
              <span className="first-bit">i</span>
              <span className="first-bit">c</span>
              <span className="first-bit">i</span>
              <span className="first-bit">e</span>
              <span className="first-bit">c</span>
              <span className="first-bit">y</span>
              <span className="gap"></span>
              <span className="third-bit">i</span>
              <span>s</span>
              <span className="gap"></span>
              <span>t</span>
              <span>h</span>
              <span>e</span>

              <span className="gap"></span>
              <span>f</span>
              <span>u</span>
              <span>t</span>
              <span>u</span>
              <span>r</span>
              <span>e</span>
              <span className="gap"></span>
              <span className="">o</span>
              <span className="">f</span>

              <span className="second-bit fourth-bit blue">c</span>
              <span className="second-bit blue">o</span>
              <span className="second-bit blue">m</span>
              <span className="second-bit blue">f</span>
              <span className="second-bit blue">o</span>
              <span className="second-bit blue">r</span>
              <span className="second-bit blue">t</span>
              <span className="gap"></span>
              <span className="second-bit blue">l</span>
              <span className="second-bit blue">i</span>
              <span className="second-bit blue">v</span>
              <span className="second-bit blue">i</span>
              <span className="second-bit blue">n</span>
              <span className="second-bit blue">g</span>
            </h5>
          </div>
        </section>

        <Block2>
          <BlockDiv>
            <span className="blue">
              Assuring quality, efficient, friendly communication, and cost
              effective design.
            </span>{' '}
            Find out why should be your next construction partner:
            <BtnWrap>
              <Button buttonSize="btn--large" buttonStyle="btn--outline">
                Case Study
              </Button>
            </BtnWrap>
          </BlockDiv>
        </Block2>
      </Wrapper>
    </Container>
  );
};

export default CaseStudy;
