import React, { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard';
import Img1 from '../../images/project-prac.png';
import styled from 'styled-components';
import CardFuzz from './CardFuzz';
import House1 from '../../images/projects/beeston after.jpg';
import House2 from '../../images/projects/bramcote round back.jpg';
import House3 from '../../images/projects/wilford after 1.jpg';
import House4 from '../../images/projects/edingley after.jpg';
import House5 from '../../images/projects/mb-center.png';
import House6 from '../../images/projects/ockbrook after.jpg';
import House7 from '../../images/projects/higgy-house.jpg';
import House8 from '../../images/projects/edwalton.jpg';
import House9 from '../../images/projects/woodborough 5.jpg';
import House10 from '../../images/projects/east bridgeford after.jpg';
import House11 from '../../images/projects/woodborough ext b4.jpg';
import House12 from '../../images/projects/mansion.jpeg';
import ProjectCard2 from './ProjectCard2';
import CardClean from './CardClean';
import './Options.css';
import { useInView } from 'react-intersection-observer';
import CardClean2 from './CardClean2';
import 'aos/dist/aos.css';
import Aos from 'aos';

const Container = styled.div`
  background: rgb(245, 245, 245);
  //height: 100vh;
  width: 100vw;
  height: 100%;
  padding-left: 200px;
  padding-right: 200px;
  overflow: hidden;
  padding-bottom: 50px;
  @media screen and (max-width: 1400px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media screen and (max-width: 1000px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    //margin-left: -25px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    grid-row-gap: 25px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0;
  }
  @media screen and (max-width: 500px) {
    // background-size: 160%;
    height: 100%;
    padding-bottom: 25px;
  }
`;
const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  //grid-row-gap: 50px;
  //overflow-y: hidden;
  height: 100%;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    //margin-left: -25px;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    //margin-left: -25px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    grid-row-gap: 25px;

    margin-left: 0;
  }
`;
const OptionsWrapper = styled.div`
  //height: 100%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  grid-gap: 25px;
  width: 100%;
`;
const Options = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  // display: grid;
  justify-content: flex-start;
  gap: 20px;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const Option = styled.div`
  font-weight: 500;
  //font-style: italic;
  font-size: 1.5rem;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const DashedRow = styled.div`
  grid-column: span 4; /* Make it span all 4 columns */
  border-bottom: 1px solid #081542;
  margin-left: 40%;
  margin-right: 40%;
  display: flex;
  justify-content: center; /* Add a dashed border at the bottom */
  margin-bottom: 0px;
  @media screen and (max-width: 1200px) {
    grid-column: span 3;
    display: none;
  } /* Adjust the margin as needed */
`;
const CardWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
`;

const ProjectCards = () => {
  const [showCommercial, setShowCommercial] = useState(false);

  const [items, setItems] = useState([
    {
      header: 'Beeston',
      details: 'Extension',
      src: House1,
      link: '/beeston',
      com: true,
      con: false,
      new: false,
    },
    {
      header: 'Bramcote',
      details: 'New Build',
      src: House2,
      link: '/bramcote',
      con: false,
      new: true,
      com: false,
    },
    {
      header: 'Wilford',
      src: House3,
      link: '/wilford',
      details: 'Extension',
      com: true,
      con: false,
      new: false,
    },
    {
      header: 'Edingley',
      src: House4,
      com: true,
      link: '/edingley',
      details: 'Extension',
      con: false,
      new: false,
    },
    {
      header: 'MB Centre',
      src: House5,
      link: '/mb-centre',
      com: true,
      new: false,
      details: 'Extension',
      con: false,
    },
    {
      header: 'Ockbrook',
      src: House6,
      new: false,
      link: '/ockbrook',
      com: false,
      details: 'Conversion',

      con: true,
    },
    {
      header: 'Butron Joyce',
      details: 'Landscaping',
      src: House7,
      new: false,
      link: '/lambley-lane',
      com: true,
      con: false,
    },
    {
      header: 'Edwalton',
      src: House8,
      com: true,
      new: false,
      details: 'Extension',
      link: '/edwalton',
      con: false,
    },
    {
      header: 'Burton Joyce',
      src: House12,
      new: true,
      details: 'New Build',
      com: false,
      link: '/burton-joyce',
      con: false,
    },
    {
      header: 'Woodborough',
      src: House9,
      new: false,
      link: '/woodborough-renovation',
      com: false,
      details: 'Renovation',
      con: true,
    },
    {
      header: 'East Bridgeford',
      src: House10,
      new: false,
      link: '/east-bridgeford',
      com: false,
      details: 'Conversion',
      con: true,
    },

    {
      header: 'Woodborough',
      src: House11,
      new: false,
      link: '/woodborough',
      com: true,
      details: 'Extension',
      con: false,
    },
  ]);

  // const showCommercialItems = () => {
  //   setShowCommercial(true);
  // };

  // // Function to show all items
  // const showAllItems = () => {
  //   setShowCommercial(false);
  // };

  // const filteredItems = showCommercial
  //   ? items.filter((item) => item.com)
  //   : items;

  // console.log(showCommercial);
  const [filterCategory, setFilterCategory] = useState('All');

  const filterItems = (category) => {
    if (category === 'All') {
      setShowCommercial(false);
    } else {
      setShowCommercial(true);
      setFilterCategory(category);
    }
  };

  // ...

  const filteredItems = showCommercial
    ? items.filter((item) => item[filterCategory.toLowerCase()])
    : items;
  const [ref, inView] = useInView();
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <Container>
      <OptionsWrapper>
        <Options>
          <Option
            // className={`${showCommercial ? '' : 'all'}`}
            // onClick={showAllItems}
            className={`${!showCommercial ? 'all' : ''}`}
            onClick={() => filterItems('All')}
          >
            All
          </Option>

          <Option
            // className={`${showCommercial ? 'all' : ''}`}
            // onClick={showCommercialItems}
            className={`${
              showCommercial && filterCategory === 'Com' ? 'all' : ''
            }`}
            onClick={() => filterItems('Com')}
          >
            Extensions
          </Option>
          <Option
            // className={`${showCommercial ? '' : ''}`}
            // onClick={showCommercialItems}
            className={`${
              showCommercial && filterCategory === 'Con' ? 'all' : ''
            }`}
            onClick={() => filterItems('Con')}
          >
            Conversions
          </Option>
          <Option
            // className={`${showCommercial ? '' : ''}`}
            // onClick={showCommercialItems}
            className={`${
              showCommercial && filterCategory === 'New' ? 'all' : ''
            }`}
            onClick={() => filterItems('New')}
          >
            New Builds
          </Option>
        </Options>
      </OptionsWrapper>

      <CardsWrapper data-aos="fade-up">
        {filteredItems.map((item, index) => (
          <div className={`${showCommercial ? 'fade-in' : 'fade-in-2'}`}>
            <CardWrap>
              <CardClean
                src={item.src}
                header={item.header}
                key={index}
                link={item.link}
                location={item.location}
                details={item.details}
              />
            </CardWrap>
          </div>
        ))}
      </CardsWrapper>
    </Container>
  );
};

export default ProjectCards;
