import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import FrontPage from '../Blogs/FrontPage';

const Cont = styled.div`
  position: relative;
  z-index: 50;
  background: gray;
`;

const BlogPage = () => {
  return (
    <>
      <Helmet>
        <title>The BRW Blog - BRW Construction, Nottingham, Burton Joyce</title>
        <meta
          name="description"
          content="See our latest blog articles where we share our
          knowledge of the construction industry."
        />
        <link rel="canonical" href="/blog" />
      </Helmet>
      <Cont>
        <FrontPage />
      </Cont>
    </>
  );
};

export default BlogPage;
