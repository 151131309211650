import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import P from '../../images/pw.png';
import H from '../../images/h.png';
import D from '../../images/d.png';
import L from '../../images/l.png';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 50px;
  /* padding-left: 200px;
  padding-right: 200px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: relative;
  overflow-y: hidden;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 25px;
  overflow-y: hidden;
  height: auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;
const H1 = styled.h1`
  font-size: 2.5rem;
  font-weight: 300;
  padding: 25px;
`;
const Card = styled.div`
  width: 100%;
  //height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: hidden;
`;
const Img = styled.img`
  width: auto;
  height: 300px;
  object-fit: cover;
  @media screen and (max-width: 1000px) {
    height: 200px;
  }
`;
const Header = styled.div`
  // text-align: center;
  font-size: 1.3rem;
  font-weight: 300;
`;

const View = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  const [hover, setHover] = useState(false);
  const handleHover = () => {
    setHover(true);
  };
  const handleLeave = () => {
    setHover(false);
  };
  const [hover2, setHover2] = useState(false);
  const handleHover2 = () => {
    setHover2(true);
  };
  const handleLeave2 = () => {
    setHover2(false);
  };
  const [hover3, setHover3] = useState(false);
  const handleHover3 = () => {
    setHover3(true);
  };
  const handleLeave3 = () => {
    setHover3(false);
  };
  return (
    <Container className="overflow-y-hidden">
      <H1>View Our Work</H1>
      <Wrapper>
        <Link to="/recent-projects">
          <Card
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            className="hover:cursor-pointer overflow-y-hidden"
            data-aos="fade-right"
          >
            <Img
              src={P}
              alt=""
              className={`transition-transform duration-500 ease-in-out ${
                hover ? 'scale-[1.02]' : ''
              }`}
            />
            <Header>
              New Builds{' '}
              <i
                className={`fa-solid fa-chevron-right text-[1rem] transition-transform duration-300 absolute mt-[9px] ml-[5px] ease-in-out ${
                  hover ? 'translate-x-[7.5px]' : ''
                }`}
              ></i>
            </Header>
          </Card>
        </Link>
        <Link to="/recent-projects">
          <Card
            onMouseEnter={handleHover2}
            onMouseLeave={handleLeave2}
            className="hover:cursor-pointer overflow-y-hidden"
            data-aos="fade"
          >
            <Img
              src={H}
              alt=""
              className={`transition-transform duration-500 ease-in-out ${
                hover2 ? 'scale-[1.02]' : ''
              }`}
            />
            <Header>
              Extensions{' '}
              <i
                className={`fa-solid fa-chevron-right text-[1rem] transition-transform duration-300 absolute mt-[9px] ml-[5px] ease-in-out ${
                  hover2 ? 'translate-x-[7.5px]' : ''
                }`}
              ></i>
            </Header>
          </Card>
        </Link>
        <Link to="/recent-projects">
          <Card
            onMouseEnter={handleHover3}
            onMouseLeave={handleLeave3}
            className="hover:cursor-pointer overflow-y-hidden"
            data-aos="fade-left"
          >
            <Img
              src={D}
              alt=""
              className={`transition-transform duration-500 ease-in-out ${
                hover3 ? 'scale-[1.02]' : ''
              }`}
            />
            <Header>
              Conversions{' '}
              <i
                className={`fa-solid fa-chevron-right text-[1rem] transition-transform duration-300 absolute mt-[9px] ml-[5px] ease-in-out ${
                  hover3 ? 'translate-x-[7.5px]' : ''
                }`}
              ></i>
            </Header>
          </Card>
        </Link>
      </Wrapper>
    </Container>
  );
};

export default View;
